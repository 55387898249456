import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Link, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { BACKEND_API } from "../../constant";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Register from "./Register";
import { FreeAccount } from "../../common/FreeAccount";
import { useScoreContext } from "../../context/ScoreContext";
import { GameConfig } from "../../interfaces/GameConfig";
import { useFetch } from "../../hooks/useFetch";
import { Loader } from "../../context/LoaderContext";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
    link: {
        cursor: "pointer",
    },
}));

/**
 * AWS Accounts Component, called by /accounts in the dashboard
 */
interface AccountsProps {
    gameConfig: GameConfig;
    gameDay: boolean;
}

export const Accounts: React.FC<AccountsProps> = ({ gameConfig, gameDay }) => {
    const [accountsData, accountsDataIsLoading, accountsDataHasError] = useFetch(
        `${BACKEND_API}/accounts`,
    );

    const [openRegister, setOpenRegister] = React.useState<boolean>(false);
    const [idAccount, setIdAccount] = React.useState<string>("");

    const { gamedayScores } = useScoreContext();

    const classes = useStyles();
    const handleClickOpenRegister = (id: string) => {
        setIdAccount(id);
        setOpenRegister(true);
    };

    const handleCloseRegister = () => {
        setOpenRegister(false);
    };

    /**
     * Function called with the OPEN button
     * @param account : account id to open
     */

    const open = async (account: string) => {
        let response;
        let link;
        try {
            response = await axios.get(`${BACKEND_API}/accounts/${account}/link`);
            link = response.data;
            window.open(`${link}`, "_blank", "noopener,noreferrer");
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Data helpers
     */

    const getValues = (state: any) => Object.values(state);

    const getTime = (timestamp: string | number | Date) => {
        const begin = new Date(timestamp);
        const now = new Date();
        const diff = now.getTime() - begin.getTime();
        return Math.floor(diff / 3600000);
    };

    if (accountsDataIsLoading) {
        return <Loader />;
    }
    if (accountsDataHasError) {
        return (
            <Alert severity={"error"} style={{ textAlign: "left" }}>
                <AlertTitle>Une erreur est survenue</AlertTitle>
                <Typography variant="body2">
                    Quelque chose s'est mal passé, veuillez réessayer.
                </Typography>
            </Alert>
        );
    }
    /**
     * Function to construct rows taken in the final table
     * @param gameConfig : Result of the /config api
     * @param teams : Teams array
     * @param scores : Raw socket's data
     * @param : Columns of the table
     *
     * @return One line of teh table
     */

    const createAccountsData = (
        availableAccounts: string | any[],
        team: { info: string; scores: { timestamp: any }[]; name: any; useCase: any } | undefined,
        id: string,
        level: string,
    ) => {
        let status = "",
            link: React.ReactNode,
            actions: React.ReactNode;

        switch (level) {
            case "easy":
                level = "Faibles";
                break;
            case "medium":
                level = "Controlés";
                break;
            case "hard":
                level = "Ouverts";
                break;
            default:
                break;
        }

        if (typeof team !== "undefined") {
            actions = (
                <div>
                    <FreeAccount
                        disabled={true} //temp solution to avoid admin missclick
                        // disabled={team.info === "Disabled"}
                        team={team}
                        text={"Nettoyer le compte"}
                        style={{ margin: "10px" }}
                    />
                </div>
            );

            if (team.info === "Disabled") {
                status = "Désactivé";
            } else {
                const usedBy =
                    typeof team.scores[0] !== "undefined"
                        ? `Utilisé par ${team.name} depuis ${getTime(
                              team.scores[0].timestamp,
                          )} heures`
                        : `Utilisé par ${team.name}`;
                status += `${usedBy} pour le ${team.useCase}`;
                link = (
                    <div>
                        <Link color="primary" className={classes.link} onClick={() => open(id)}>
                            Ouvrir
                        </Link>
                    </div>
                );
            }
        } else if (availableAccounts.includes(id) && team === undefined) {
            status = "Disponible pour le Gameday";
            actions = (
                <div>
                    <Button
                        disableElevation
                        variant="contained"
                        style={{ margin: "10px" }}
                        onClick={() => handleClickOpenRegister(id)}
                        color="primary"
                    >
                        Enregistrer une équipe
                    </Button>
                </div>
            );
        } else {
            status = "Libre";
        }

        return { id, status, level, link, actions };
    };

    let table;
    // Avoid errors if no data
    if (typeof gamedayScores !== "undefined" && typeof gameConfig !== "undefined") {
        const accounts = gameDay
            ? getValues(accountsData.AWS)
                  .filter((account: any) =>
                      gameConfig["availableAccounts"].includes(account.accountId),
                  )
                  .map((account: any) =>
                      createAccountsData(
                          gameConfig["availableAccounts"],
                          gamedayScores[account.accountId],
                          account.accountId,
                          account.level,
                      ),
                  )
            : getValues(accountsData.AWS).map((account: any) =>
                  createAccountsData(
                      gameConfig["availableAccounts"],
                      gamedayScores[account.accountId],
                      account.accountId,
                      account.level,
                  ),
              );

        table = (
            <div
                style={{
                    textAlign: "left",
                    padding: "40px",
                    boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
                }}
            >
                <React.Fragment>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Droits</TableCell>
                                <TableCell>Statut</TableCell>
                                <TableCell>Console AWS</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accounts.length > 0 &&
                                accounts.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.level}</TableCell>
                                        <TableCell>{row.status}</TableCell>
                                        <TableCell>{row.link}</TableCell>
                                        <TableCell>{row.actions}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </React.Fragment>
                <hr />
            </div>
        );
    } else {
        table = (
            <div>
                <p>
                    <br />
                    Le tableau des comptes AWS va apparaitre ici.
                </p>
            </div>
        );
    }

    return (
        <div>
            <Typography
                color="textPrimary"
                variant="h2"
                style={{ marginTop: "20px", marginBottom: "20px" }}
            >
                COMPTES AWS
            </Typography>
            <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div>{table}</div>
            <Register
                openRegister={openRegister}
                handleCloseRegister={handleCloseRegister}
                id={idAccount}
                gameConfig={gameConfig}
            />
        </div>
    );
};
