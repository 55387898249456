import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardGameday as PlayerDashboardGameday } from "./player/gameday/DashboardGameday";
import { DashboardStaffing } from "./common/DashboardStaffing";
import { DashboardEvent as PlayerDashboardEvent } from "./player/event/DashboardEvent";
import WelcomePage from "./common/WelcomePage";
import { ScenarioDataProvider } from "./context/ScenarioDataContext";
import { GameConfigProvider } from "./context/GameConfigContext";
import { ScoreContextProvider } from "./context/ScoreContext";
import { BugReport } from "./common/BugReport";

const PlayerRoutes: React.FC = () => {
    return (
        <ScenarioDataProvider>
            <BugReport />
            <Routes>
                <Route index element={<WelcomePage role={"player"} />} />
                <Route
                    path="gameday/*"
                    element={
                        <GameConfigProvider>
                            <ScoreContextProvider>
                                <PlayerDashboardGameday />
                            </ScoreContextProvider>
                        </GameConfigProvider>
                    }
                />
                <Route path="staffing/*" element={<DashboardStaffing role="player" />} />
                <Route path="event" element={<PlayerDashboardEvent />} />
                <Route path="*" element={<Navigate to="/player" replace={true} />} />
            </Routes>
        </ScenarioDataProvider>
    );
};

export default PlayerRoutes;
