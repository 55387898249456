import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Accordion, { AccordionProps } from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 2, 2, 2),
        marginBottom: theme.spacing(4),
    },
    overrides: {
        MuiAccordionExpanded: {
            marginBottom: theme.spacing(4),
        },
    },

    summary: {
        padding: theme.spacing(0, 0, 0, 0),
        minHeight: "50px",
    },

    details: {
        display: "block",
        minHeight: "200px",
        padding: theme.spacing(0, 0, 0, 0),
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
}));

interface CustomAccordionProps {
    title: string;
    bgColor: string;
    defaultExpanded?: boolean;
}

export const CustomAccordion: React.FC<AccordionProps & CustomAccordionProps> = ({
    title,
    bgColor,
    defaultExpanded = false,
    children,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Accordion
            className={classes.root}
            style={{ backgroundColor: bgColor }}
            defaultExpanded={defaultExpanded}
            {...props}
        >
            <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
            >
                <Typography color="primary" variant="h3">
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div style={{ textAlign: "left" }}>
                    <Divider className={classes.divider} />
                    {children}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
