import React, { useEffect, useState } from "react";
import cytoscape from "cytoscape";
// @ts-ignore
import coseBilkent from "cytoscape-cose-bilkent";
import "./Cytoscape.css";
import { cytoscapeStyles } from "./cytoscapeStyle";
import { Divider } from "@material-ui/core";
import { ChipInformation } from "../ChipInformation";

interface NodeData {
    id: string;
    local_id: string;
    name: string;
    type: string;
}

export const Cytoscape: React.FC<{ data: any }> = ({ data }) => {
    const [selectedNode, setSelectedNode] = useState<NodeData | false>(false);

    const formattedData = (rawData: any) => {
        let computedData: any = { nodes: [], edges: [] };
        rawData.resources.forEach((resource: any) => {
            computedData.nodes.push({
                data: {
                    id: resource.id,
                    name: resource.name,
                    local_id: resource.name,
                    type: `azure-${resource.id.split("/")[resource.id.split("/").length - 2]}`,
                },
            });
            resource.associations.forEach((association: any) => {
                if (association.associationType !== "Associated")
                    computedData.edges.push({
                        data: {
                            id: `${resource.id}-to-${association.resourceId}`,
                            source: resource.id,
                            target: association.resourceId,
                        },
                    });
            });
        });
        return computedData;
    };

    useEffect(() => {
        cytoscape.use(coseBilkent);
        const cy = cytoscape({
            wheelSensitivity: 0.1,
            container: document.getElementById("cy"),
            elements: formattedData(data),
            layout: {
                // @ts-ignore
                name: "cose-bilkent",
                nodeDimensionsIncludeLabels: true,
                tilingPaddingVertical: 10,
                tilingPaddingHorizontal: 100,
            },
            style: cytoscapeStyles as any,
        });
        cy.on("tap", "node", (evt) => setSelectedNode(evt.target.data()));
    }, [data]);

    return (
        <div className="cy-container">
            <div id="cy" />
            {selectedNode && (
                <div className="cy-overlay">
                    <h4>Infrastructure sélectionnée </h4>
                    <Divider style={{ margin: "6px 0" }} />
                    <ChipInformation label={"local_id"} value={selectedNode.local_id} disableCopy />
                    <ChipInformation label={"Nom"} value={selectedNode.name} disableCopy />
                    <ChipInformation label={"Type"} value={selectedNode.type} disableCopy />
                </div>
            )}
        </div>
    );
};
