import React from "react";
import { FormControl, FormLabel, FormControlLabel, FormGroup, Typography } from "@material-ui/core";
import { GameSetupFormValues } from "./GameSetup";
import CustomCheckbox from "../../../common/CustomCheckbox";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { BACKEND_API } from "../../../constant";
import { useFetch } from "../../../hooks/useFetch";
import { Loader } from "../../../context/LoaderContext";

interface GameSetupSelectAccountsProps {
    values: GameSetupFormValues;
    setValues: React.Dispatch<any>;
}

export const GameSetupSelectAccounts: React.FC<GameSetupSelectAccountsProps> = ({
    values,
    setValues,
}) => {
    const [availableAccounts, availableAccountsIsLoading, availableAccountsHasError] = useFetch(
        `${BACKEND_API}/accounts?status=available`
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const accountId = event.target.name;
        let selectedAccountsValue = [...values.selectedAccounts];
        if (selectedAccountsValue.includes(accountId)) {
            selectedAccountsValue = selectedAccountsValue.filter((elem) => elem !== accountId);
        } else {
            selectedAccountsValue.push(accountId);
        }
        setValues({ ...values, selectedAccounts: selectedAccountsValue });
    };

    if (availableAccountsIsLoading) {
        return <Loader />;
    }

    if (availableAccountsHasError) {
        return (
            <GameSetupAlert
                severity={"error"}
                title={"Une erreur est survenue"}
                message={"Quelque chose s'est mal passé, veuillez réessayer."}
            />
        );
    }
    if (!Object.values(availableAccounts.AWS).length) {
        return (
            <GameSetupAlert
                severity={"warning"}
                title={"Aucun compte disponible !"}
                message={
                    "Tous les comptes sont déjà occupés avec cette configuration de jeu. Veuillez réessayer avec d'autres options, en changeant notamment le niveau de difficulté du Gameday."
                }
            />
        );
    }
    return (
        <FormControl component="fieldset">
            <FormLabel style={{ marginBottom: 8 }}>Comptes disponibles</FormLabel>
            <FormGroup>
                {Object.values(availableAccounts.AWS).map((accountValues: any) => (
                    <FormControlLabel
                        key={accountValues.accountId}
                        control={
                            <CustomCheckbox
                                checked={values.selectedAccounts.includes(accountValues.accountId)}
                                onChange={handleChange}
                                name={accountValues.accountId}
                            />
                        }
                        label={`${accountValues.accountId} - ${accountValues.name}`}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

interface GameSetupAlertProps {
    severity: "warning" | "error";
    title: string;
    message: string;
}
const GameSetupAlert: React.FC<GameSetupAlertProps> = ({ severity, title, message }) => {
    return (
        <Alert
            severity={severity}
            style={{ width: "80%", margin: "auto", textAlign: "left", marginBottom: 16 }}
        >
            <AlertTitle>{title}</AlertTitle>
            <Typography variant="body2">{message}</Typography>
        </Alert>
    );
};
