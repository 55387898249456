import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(6),
    },
}));

const Copyright: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="body2" color="textSecondary" align="center">
                {"Copyright © "}
                <Link color="inherit" href="https://fr.ippon.tech/" target="_blank">
                    Ippon Technologies
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
                Tous les droits sont réservés.
            </Typography>
        </div>
    );
};
export default Copyright;
