import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_API } from "../constant";

export const useGameConfigHook = () => {
    const [gameConfig, setGameConfig] = useState<any>({});
    const [isGameConfigLoading, setIsGameConfigLoading] = useState<boolean>(true);
    const [gameConfigHasError, setGameConfigHasError] = useState<boolean>(false);

    const fetchGameConfig = async () => {
        setIsGameConfigLoading(true);
        axios
            .get(`${BACKEND_API}/config`)
            .then((response) => {
                setGameConfig(response.data.gameConfig);
                setIsGameConfigLoading(false);
            })
            .catch((error) => {
                setGameConfigHasError(true);
                console.error(error);
                setIsGameConfigLoading(false);
            });
    };

    useEffect(() => {
        setGameConfigHasError(false);
        fetchGameConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { gameConfig, isGameConfigLoading, gameConfigHasError, fetchGameConfig };
};
