import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useReload = () => {
    const navigate = useNavigate();
    const reloadPage = useCallback(() => {
        navigate(0);
    }, [navigate]);
    return { reloadPage };
};
