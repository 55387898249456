export const BACKEND_API = window._env_ ? window._env_.API_URL : "http://localhost:4000";
export const S3_SCENARIOS_URL = window._env_ ? window._env_.S3_SCENARIOS_URL : "http://localhost:4000";
export const CLOUDMAPPER = window._env_ ? window._env_.CLOUDMAPPER : "http://localhost:3000";
export const CLOUDPDF = window._env_ ? window._env_.CLOUDPDF : "http://localhost:7000";
export const CLOUDMAPPER_PREFIX = "cloudmapper";
export const REGION = window._env_ ? window._env_.REGION : "eu-west-1";
export const PRIVATE_KEY = window._env_ ? window._env_.PRIVATE_KEY : "null";
export const KEYCLOAK_URL = window._env_ ? window._env_.KEYCLOAK_URL : "";
export const KEYCLOAK_REALM = window._env_ ? window._env_.KEYCLOAK_REALM : "";
export const KEYCLOAK_CLIENT_ID = window._env_ ? window._env_.KEYCLOAK_CLIENT_ID : "";
export const EASY = "easy";
export const MEDIUM = "medium";
export const HARD = "hard";
export const ACCOUNT_ID = "ippon-gameday-easy-01";
export const AWS_CLOUD_CONSOLE_LINK = `https://signin.aws.amazon.com/console`;
export const COGNITO_USER_POOL_ID_TEAM = window._env_
    ? window._env_.COGNITO_USER_POOL_ID_TEAM
    : null;
export const COGNITO_CLIENT_ID_TEAM = window._env_ ? window._env_.COGNITO_CLIENT_ID_TEAM : null;
export const MAX_GRAPH_LENGTH = 20;
