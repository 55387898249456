import React, { createContext, useContext, useEffect, useState } from "react";
import CustomAlertPopup from "../common/CustomAlertPopUp";
import { AlertProps } from "@material-ui/lab/Alert";

interface CustomAlertContextProps {
    showCustomAlert: (type: AlertProps["severity"], title: string, message: string) => void;
}

export const CustomAlertContext = createContext<CustomAlertContextProps>({
    showCustomAlert: () => {},
});

export const useCustomAlertContext = () => useContext(CustomAlertContext);

export const CustomAlertContextProvider: React.FC = ({ children }) => {
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [severityAlert, setSeverityAlert] = useState<AlertProps["severity"]>("error");
    const [titleAlert, setTitleAlert] = useState<string>("Erreur");
    const [messageAlert, setMessageAlert] = useState<string>("");
    const [snackbarPack, setSnackbarPack] = useState<any[]>([]);

    const showCustomAlert = (type: AlertProps["severity"], title: string, message: string) => {
        setSnackbarPack((prev) => [...prev, { type, title, message }]);
    };

    useEffect(() => {
        if (snackbarPack.length && !messageAlert) {
            // Set a new snack when we don't have an active one
            setMessageAlert(snackbarPack[0].message);
            setSeverityAlert(snackbarPack[0].type);
            setTitleAlert(snackbarPack[0].title);
            setSnackbarPack((prev) => prev.slice(1));
            setOpenAlert(true);
        } else if (snackbarPack.length && messageAlert && openAlert) {
            // Close an active snack when a new one is added
            setOpenAlert(false);
        }
    }, [messageAlert, snackbarPack, openAlert]);

    const handleExited = () => {
        setMessageAlert("");
    };

    const closeCustomAlert: any = (_event: any, reason: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    return (
        <CustomAlertContext.Provider value={{ showCustomAlert }}>
            <CustomAlertPopup
                title={titleAlert}
                open={openAlert}
                severity={severityAlert}
                message={messageAlert}
                handleClose={closeCustomAlert}
                handleExited={handleExited}
            />
            {children}
        </CustomAlertContext.Provider>
    );
};
