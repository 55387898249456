import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    chartTeamChip: {
        cursor: "pointer",
        display: "flex",
        gap: theme.spacing(0.75),
        alignItems: "center",
        textTransform: "capitalize",
        transition: theme.transitions.create(["all"]),
    },
    legendBulletPoint: {
        width: 12,
        height: 12,
        borderRadius: "100%",
    },
}));

interface ChartTeamChipProps {
    color: string;
    name: string;
}

export const ChartTeamChip: React.FC<ChartTeamChipProps> = ({ color, name }) => {
    const classes = useStyles();

    return (
        <div className={classes.chartTeamChip}>
            <div className={classes.legendBulletPoint} style={{ backgroundColor: color }} />
            <span>{name}</span>
        </div>
    );
};
