import React, { useEffect, useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { CLOUDPDF, BACKEND_API } from "../../constant";
import { Cloudmapper } from "../../common/Cloudmapper";
import CustomAlert from "../../common/CustomAlert";
import { StaffingHeaderBar } from "../../common/StaffingHeaderBar";
import ModalStaff from "../../common/ModalStaff";
import { useNavigate, useParams } from "react-router-dom";
import { useStaffingValues } from "../../hooks/useStaffingValues";
import { useCustomAlertContext } from "../../context/CustomAlertContext";
import { PlayerStaffContent } from "./PlayerStaffContent";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(3),
    },
    end: {
        display: "flex",
        justifyContent: "right",
    },
    annuler: {
        marginRight: theme.spacing(3),
    },
    cloudlarge: {
        position: "absolute",
        zIndex: 1000,
        top: 388,
        left: 50,
        borderRadius: 16,
        width: "95%",
        height: 1300,
    },
}));

/**
 * Page for player during a staffing session.
 *
 * @return {JSX} /staffing/:name - session page when an applicant is setted
 */
export const PlayerStaff: React.FC = () => {
    const classes = useStyles();

    const [open, setOpen] = useState<boolean>(false);
    const [cloudmapperLarge, setCloudmapperLarge] = useState<boolean>(false);
    const [disablePdfButton, setdisablePdfButton] = useState<boolean>(true);
    const [disableExitButton, setdisableExitButton] = useState<boolean>(true);
    const [openModalEndSession, setOpenModalEndSession] = useState<boolean>(false);

    const navigate = useNavigate();
    const applicantName = useParams().applicantName as string;
    const { showCustomAlert } = useCustomAlertContext();

    useEffect(() => {
        const sessionName = sessionStorage.getItem("applicantName");
        if (sessionName !== applicantName) {
            navigate("/player/staffing");
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "Vous n'avez pas les droits pour participer à cette session."
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage, applicantName]);

    const { areStaffingValuesLoaded, applicantTeam, selectedScenarios } =
        useStaffingValues(applicantName);

    const disableTeamAccount = () => {
        const data = { masterPassword: applicantTeam.password, status: "Disabled" };
        return axios.post(`${BACKEND_API}/teams/status`, data);
    };

    const downloadPdf = () => {
        window.open(`${CLOUDPDF}/static/${applicantName}/${applicantName}.pdf`);
    };

    const getPdf = () => {
        return axios(`${CLOUDPDF}/static/${applicantName}/${applicantName}.pdf`, {
            method: "GET",
            responseType: "blob",
        });
    };

    const generatePDF = () => {
        const data = {
            accountId: applicantTeam.accountId,
            lastname: applicantTeam.name,
            firstname: applicantTeam.name,
            name: applicantTeam.name,
            email: applicantTeam.email,
            endSession: applicantTeam.endSession,
            createdAt: applicantTeam.createdAt,
            useCase: applicantTeam.useCase,
            bullets: applicantTeam.bullets,
        };
        return axios.post(`${CLOUDPDF}/generate`, data);
    };

    const logoutFromSession = () => {
        navigate("/player/staffing");
    };

    const releaseAccountFromSession = () => {
        generatePDF()
            .then(() => getPdf())
            .then(() => disableTeamAccount())
            .then(() => {
                setdisablePdfButton(false);
                setdisableExitButton(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCloseSession = () => {
        releaseAccountFromSession();
        setOpen(false);
        setOpenModalEndSession(true);
    };

    if (!areStaffingValuesLoaded) {
        return <StaffingHeaderBar name={applicantName} />;
    }
    return (
        <main className={classes.main}>
            <PlayerStaffContent
                applicantTeam={applicantTeam}
                selectedScenarios={selectedScenarios}
                cloudmapperLarge={cloudmapperLarge}
                setCloudmapperLarge={setCloudmapperLarge}
                setOpen={setOpen}
            />

            <ModalStaff
                open={open}
                setOpen={setOpen}
                title="TERMINER LA SESSION"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert
                        title="Attention, vous êtes sur le point de terminer la session."
                        severity="error"
                    >
                        Terminer la session signifie que le candidat a fini sa session de test. Il
                        ne pourra donc pas y revenir.
                        <br />
                        Êtes-vous sûr de vouloir faire cela ?
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.annuler}
                            disableElevation
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpen(false)}
                            startIcon={<CloseIcon />}
                        >
                            Annuler
                        </Button>
                        <Button
                            className={classes.annuler}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleCloseSession}
                            startIcon={<CheckIcon />}
                        >
                            Terminer la session
                        </Button>
                    </div>
                </React.Fragment>
            </ModalStaff>

            <ModalStaff
                open={openModalEndSession}
                setOpen={setOpenModalEndSession}
                closeAction={logoutFromSession}
                disableExitButton={disableExitButton}
                title="SESSION TERMINÉE"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert title="La session est terminée" severity="success">
                        Merci d'avoir utiliser le cloud-challenge !
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.annuler}
                            disabled={disablePdfButton}
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={downloadPdf}
                        >
                            Voir le rapport
                        </Button>
                        <Button
                            className={classes.annuler}
                            disabled={disableExitButton}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={logoutFromSession}
                            startIcon={<CloseIcon />}
                        >
                            Fermer
                        </Button>
                    </div>
                </React.Fragment>
            </ModalStaff>
            {cloudmapperLarge && (
                <Cloudmapper
                    role="player"
                    className={classes.cloudlarge}
                    accountId={applicantTeam.accountId}
                    cloudmapperLarge={cloudmapperLarge}
                    setCloudmapperLarge={setCloudmapperLarge}
                />
            )}
        </main>
    );
};
