import React from "react";
import {
    makeStyles,
    Card,
    CardMedia,
    CardContent,
    Typography,
    CardActions,
} from "@material-ui/core";
import { ScenarioFileViewer } from "./ScenarioFileViewer";

const useStylesScenarioCard = makeStyles({
    root: {},

    content: {
        height: "150px",
        overflowY: "hidden",
        textAlign: "left",
    },
    title: {
        textTransform: "uppercase",
    },
});

export const ScenarioCard: React.FC<{ scenario: any }> = ({ scenario }) => {
    const classes = useStylesScenarioCard();

    return (
        <Card className={classes.root}>
            <CardMedia
                component="img"
                alt="archi schema"
                height="140"
                image={scenario.image}
                title="archi schema"
            />
            <CardContent className={classes.content}>
                <Typography className={classes.title} gutterBottom variant="h5" component="h2">
                    {scenario.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {scenario.description}
                </Typography>
            </CardContent>
            <CardActions>
                <ScenarioFileViewer title={"Readme"} scenario={scenario} rules={false} />
                <ScenarioFileViewer title={"Règles"} scenario={scenario} rules={true} />
            </CardActions>
        </Card>
    );
};
