import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { nothingToDisplay } from "../../../common/helpers";
import Title from "../../../common/Title";
import { useScoreContext } from "../../../context/ScoreContext";
import { Grid, Typography } from "@material-ui/core";
import { CustomActiveShape } from "./CustomActiveShape";
import { PieChartTeamSelector } from "./PieChartTeamSelector";
import PieChartIcon from "@material-ui/icons/PieChart";
import { SkeletonEmptyState } from "./SkeletonEmptyState";

const COLORS = ["#0088FE", "#00C49F", "#ff0042", "#FF8042", "#02A9EA", "#FFBB28", "#FF8042"];

export const ProfitDistributionChart: React.FC = () => {
    const { gamedayScores } = useScoreContext();
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [selectedTeam, setSelectedTeam] = useState<string | false>(
        sessionStorage.getItem("GamedayPieChartSelectedTeam") ?? false
    );

    const scoredTeam: string[] = useMemo(() => {
        if (!Object.keys(gamedayScores).length) {
            return [];
        }
        return Object.keys(gamedayScores).filter(
            (team: string) => gamedayScores[team].scores.length
        );
    }, [gamedayScores]);

    useEffect(() => {
        if (Object.keys(gamedayScores).length && !selectedTeam) {
            setSelectedTeam(scoredTeam[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scoredTeam]);

    const formatedTeamProfitData: any | false = useMemo(() => {
        if (nothingToDisplay(gamedayScores) || !selectedTeam || selectedTeam === "") {
            return false;
        }
        const selectedTeamScores = gamedayScores[selectedTeam].scores;
        if (!selectedTeamScores) {
            return false;
        }
        const lastScore = selectedTeamScores[selectedTeamScores.length - 1];
        if (!lastScore ?? !lastScore.resources) {
            return false;
        }
        return Object.entries(lastScore.resources)
            .map(([key, value]) => {
                return { name: key.toUpperCase(), value: value };
            })
            .sort((a: any, b: any) => b.value - a.value);
    }, [gamedayScores, selectedTeam]);

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    const handleSelectedTeam = (teamId: string) => () => {
        sessionStorage.setItem("GamedayPieChartSelectedTeam", teamId);
        setSelectedTeam(teamId);
    };

    return (
        <div>
            <Title>Répartition des coûts par ressource</Title>
            {nothingToDisplay(gamedayScores) ? (
                <SkeletonEmptyState
                    height={320}
                    icon={<PieChartIcon />}
                    label="Le graphe des scores va apparaître ici."
                />
            ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} style={{ height: 300, fontSize: 12 }}>
                        {formatedTeamProfitData ? (
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={formatedTeamProfitData}
                                        activeIndex={activeIndex}
                                        activeShape={CustomActiveShape}
                                        onMouseEnter={onPieEnter}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={4}
                                        dataKey="value"
                                    >
                                        {formatedTeamProfitData.map(
                                            (_entry: any, index: number) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            )
                                        )}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        ) : (
                            <SkeletonEmptyState
                                height={276}
                                icon={<PieChartIcon />}
                                label="Pas de donnée pour cette équipe, veuillez en sélectionner une autre."
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Typography variant="subtitle2">Choix de l'équipe :</Typography>
                        <Typography variant="caption" color="textSecondary" paragraph>
                            Sélectionnez l'équipe pour laquelle vous voulez afficher la répartition
                            des dépenses.
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                gap: "8px 12px",
                                width: "100%",
                                flexWrap: "wrap",
                            }}
                        >
                            {scoredTeam.map((teamId) => (
                                <PieChartTeamSelector
                                    key={`pieChartTeamSelector-${teamId}`}
                                    onClick={handleSelectedTeam(teamId)}
                                    teamName={gamedayScores[teamId].name}
                                    isSelected={selectedTeam === teamId}
                                />
                            ))}
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
