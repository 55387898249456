import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Modal, { ModalProps } from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        // alignItems: 'center',
        justifyContent: "center",
        margin: theme.spacing(7, 20, 7, 20),
    },
    title: {
        textAlign: "center",
        display: "inline",
    },
    typo: {
        fontSize: "36px",
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    paper: {
        display: "inline-block",
        overflowY: "auto",
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        minWidth: "800px",
        minHeight: "200px",
    },
    button: {
        color: theme.palette.text.primary,
    },
}));
interface ModalStaffProps {
    open: boolean;
    setOpen: any;
    closeAction?: any;
    title: string;
    style?: React.CSSProperties;
    disableExitButton?: boolean;
}

const ModalStaff: React.FC<ModalProps & ModalStaffProps> = ({
    open,
    setOpen,
    closeAction,
    title,
    style,
    disableExitButton,
    children,
}) => {
    const classes = useStyles();

    const handleClose = () => {
        if (closeAction) closeAction();
        else setOpen(false);
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                style={style}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.button} style={{ textAlign: "right" }}>
                            <Button
                                disableElevation
                                disabled={disableExitButton}
                                variant="text"
                                color="primary"
                                startIcon={<CloseIcon />}
                                onClick={handleClose}
                            >
                                Fermer
                            </Button>
                        </div>
                        <div className={classes.title}>
                            <Typography className={classes.typo} color="primary" variant="h2">
                                {title}
                            </Typography>
                            <Divider variant="middle" className={classes.divider} />
                        </div>
                        {children}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};
export default ModalStaff;
