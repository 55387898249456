import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import ModalStaff from "../../common/ModalStaff";
import ScenarioReadMe from "./ScenarioReadMe";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        justifyContent: "center",
    },
    button: {
        paddingBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
}));
interface ModalScenarioReadmeProps {
    step: any;
    scenarioId: any;
    open: any;
    setOpen: any;
    rules: any;
}
const ModalScenarioReadme: React.FC<ModalScenarioReadmeProps> = ({
    step,
    scenarioId,
    open,
    setOpen,
    rules,
}) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ModalStaff
            className={classes.modal}
            open={open}
            setOpen={setOpen}
            title={`RÈGLES DU SCÉNARIO ${step}`}
        >
            <React.Fragment>
                <ScenarioReadMe id={scenarioId} rules={rules} />
                <div className={classes.button} style={{ textAlign: "center" }}>
                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        startIcon={<CloseIcon />}
                        onClick={handleClose}
                    >
                        Fermer
                    </Button>
                </div>
            </React.Fragment>
        </ModalStaff>
    );
};

export default ModalScenarioReadme;
