import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Dialog, AppBar, Toolbar, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ScenarioReadMe from "../../staffing/ScenarioReadMe";

interface ScenarioFileViewerProps {
    rules: any;
    scenario: any;
    title: string;
}

export const ScenarioFileViewer: React.FC<ScenarioFileViewerProps> = ({
    rules,
    scenario,
    title,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button
                color="primary"
                variant="contained"
                size="small"
                disableElevation={true}
                onClick={handleClickOpen}
            >
                {title}
            </Button>

            <Dialog fullScreen open={open} onClose={handleClose}>
                <AppBar>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">Étape {scenario.title}</Typography>
                        <img className="logo" src={"logo.svg"} alt="Logo" />
                    </Toolbar>
                </AppBar>
                <div style={{ margin: "100px" }}>
                    <ScenarioReadMe id={scenario.id} rules={rules} />
                </div>
            </Dialog>
        </div>
    );
};
