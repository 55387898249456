import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../theme";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
        marginBottom: 12,
    },
    pos: {
        marginBottom: 12,
    },
    logout: {
        marginBottom: 12,
    },
    typo: {
        color: theme.palette.primary.main,
        fontSize: "36px",
        marginBottom: theme.spacing(2),
    },
});

export const Stopwatch: React.FC<{ teamData: any }> = ({ teamData }) => {
    const classes = useStyles();

    const [time, setTime] = React.useState<number>(0);
    const [isActive, setIsActive] = React.useState<boolean>(false);

    React.useEffect(() => {
        const now = Date.now();
        const begin = new Date(teamData.createdAt);
        setTime(now - begin.getTime());
        setIsActive(true);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (teamData.info === "Disabled") {
            setIsActive(false);
        }
    }, [teamData]);

    React.useEffect(() => {
        let interval: NodeJS.Timer;
        if (isActive) {
            interval = setInterval(() => {
                setTime((time) => time + 1000);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive]);

    return (
        <Typography variant="h2" className={classes.typo} component="h2">
            {("0" + Math.floor((time / 3600000) % 60)).slice(-2) +
                ":" +
                ("0" + Math.floor((time / 60000) % 60)).slice(-2) +
                ":" +
                ("0" + Math.floor((time / 1000) % 60)).slice(-2)}
        </Typography>
    );
};
