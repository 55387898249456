import React from "react";
import { Grid } from "@material-ui/core";
import { GameSetupScenarioCard, GameSetupScenarioCardProps } from "./GameSetupScenarioCard";

interface GameSetupSelectScenarioProps {
    scenarioData: any;
    level: string;
}

export const GameSetupSelectScenario: React.FC<
    GameSetupSelectScenarioProps & GameSetupScenarioCardProps
> = ({ scenarioData, level, ...props }) => {
    const availableScenario: any = scenarioData[level];

    return (
        <div style={{ margin: "15px" }}>
            {Object.keys(availableScenario).map((title: string) => (
                <div key={`div-${title}`}>
                    <h3 style={{ backgroundColor: "#b8b8b8" }}>{title}</h3>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        {Object.values(availableScenario[title]).map((scenario: any) => (
                            <Grid item xs={12} sm={6} md={3} key={`grid-${scenario.gitUrl}`}>
                                <GameSetupScenarioCard
                                    key={scenario.gitUrl}
                                    {...{
                                        ...props,
                                        level,
                                        title,
                                        scenario,
                                        scenarios: availableScenario[title],
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </div>
    );
};
