import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: 4,
        padding: theme.spacing(0, 2),
        minWidth: "fit-content",
        "& > div": {
            padding: theme.spacing(2),
        },
    },
}));

interface ChartEmptyStateProps {
    icon: any;
    label: string;
    height: number;
}

export const SkeletonEmptyState: React.FC<ChartEmptyStateProps> = ({ icon, label, height }) => {
    const classes = useStyles();

    return (
        <div style={{ position: "relative" }}>
            <Skeleton animation="wave" variant="rect" style={{ height: height, borderRadius: 4 }} />
            <div className={classes.backdrop}>
                <div>
                    <Typography color="primary">{icon}</Typography>
                    <Typography variant="body2">{label}</Typography>
                    <Typography variant="body2">
                        Pour le moment, il n'y a rien à afficher.
                    </Typography>
                </div>
            </div>
        </div>
    );
};
