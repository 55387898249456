import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";

import { HeaderBarEvent } from "../staffing/staff/HeaderBarEvent";
import { Cloudmapper } from "../../common/Cloudmapper";
import ModalStaff from "../../common/ModalStaff";
import CustomAlert from "../../common/CustomAlert";
import { CLOUDMAPPER, BACKEND_API, CLOUDPDF } from "../../constant";
import { ScenarioCard } from "../staffing/staff/ScenarioCard";
import Copyright from "../../common/Copyright";
import { EndWatch } from "../staffing/staff/EndWatch";
import { ApplicantInfo } from "../../common/ApplicantInfo";
import backgroundImage from "../../assets/bg.svg";
import { CloudServiceInfo } from "../../common/CloudServiceInfo";
import { useFetch } from "../../hooks/useFetch";
import { Provider } from "../../interfaces/Types";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    item: {
        paddingBottom: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    title: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    grid: {
        marginTop: theme.spacing(2),
    },
    alert: {
        margin: theme.spacing(10, 0, 3, 0),
    },
    end: {
        display: "flex",
        justifyContent: "right",
    },
    annuler: {
        marginRight: theme.spacing(3),
    },
    typoEnd: {
        display: "flex",
        justifyContent: "right",
        marginTop: theme.spacing(2),
    },
    cloudlarge: {
        position: "absolute",
        zIndex: 1000,
        top: 388,
        left: 50,
        borderRadius: theme.spacing(2),
        width: "95%",
        height: 1300,
    },
    bg: {
        position: "absolute",
        zIndex: -1,
        width: 1160,
        height: 1160,
        left: 748,
        top: 500,
        transform: "rotate(45deg)",
    },
}));

/**
 * Dashboard grid for the staff dashboard
 *
 * @param account Account data from the websocket for the candidate
 * @param scenario scenario information for the candidate
 * @return {JSX} /staffing page when an applicant is setted
 */

interface EventProps {
    account: any;
    scenarios: any;
}

export const Event: React.FC<EventProps> = ({ account, scenarios }) => {
    const classes = useStyles();

    const cloudProvider: Provider = useMemo(() => account.provider, [account]);

    const [socketAccount, setSocketAccount] = useState<any>(account);
    const [open, setOpen] = useState<boolean>(false);
    const [openModalEndSession, setOpenModalEndSession] = useState<boolean>(false);
    const [cloudmapperLarge, setCloudmapperLarge] = useState<boolean>(false);
    const [disablePdfButton, setdisablePdfButton] = useState<boolean>(true);
    const [disableExitButton, setdisableExitButton] = useState<boolean>(true);
    const [pdfPath, setPdfPath] = useState<string>("");

    const [teamsData] = useFetch(`${BACKEND_API}/teams`);

    useEffect(() => {
        for (let i = 0; i < teamsData.length; i++) {
            if (teamsData[i].name === sessionStorage.getItem("applicant")) {
                setSocketAccount(teamsData[i]);
            }
        }
    }, [teamsData]);

    React.useEffect(() => {
        if (cloudmapperLarge) {
            window.scrollTo(0, 300);
        }
    }, [cloudmapperLarge]);

    const generatePDF = (account: any) => {
        const data = {
            accountId: account.accountId,
            lastname: account.lastname,
            firstname: account.firstname,
            name: account.name,
            email: account.email,
            endSession: account.endSession,
            createdAt: account.createdAt,
            useCase: account.useCase,
            bullets: account.bullets,
        };
        return axios.post(`${CLOUDPDF}/generate`, data);
    };

    const deleteCloudmapper = (account: any) => {
        return axios.delete(`${CLOUDMAPPER}/api/cloudmapper/${account.accountId}`);
    };
    const nukeAccount = (account: any) => {
        return axios.post(`${BACKEND_API}/solo-reset`, {
            reset: false,
            account: [account.accountId],
        });
    };
    const logout = () => {
        sessionStorage.removeItem("applicant");
        document.location.reload();
    };

    const freeAccount = (account: any) => {
        generatePDF(account)
            .then((res) => {
                setPdfPath(res.data.path);
                setdisablePdfButton(false);
                deleteCloudmapper(account);
            })
            .then(() => {
                nukeAccount(account);
            })
            .then(() => setdisableExitButton(false))
            .catch((error) => {
                console.log(error);
            });
    };

    const downloadPdf = () => {
        window.open(`${CLOUDPDF}/static/${pdfPath}`);
    };

    return (
        <main className={classes.main}>
            <HeaderBarEvent />
            <div className={classes.appBarSpacer} />
            <Container>
                <div className={classes.title}>
                    <Typography color="primary" variant="h2">
                        {" "}
                        <b>BIENVENUE </b>
                        {account.lastname.toUpperCase()} {account.firstname.toUpperCase()}
                    </Typography>
                    <Divider variant="middle" className={classes.divider} />
                </div>
                <EndWatch
                    account={socketAccount}
                    setOpenModalEndSession={setOpenModalEndSession}
                    freeAccount={freeAccount}
                />

                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Terminer la session
                </Button>

                <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
                    Terminer la session signifie que tu as fini de jouer
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <ApplicantInfo
                            role={"player"}
                            cloudProvider={cloudProvider}
                            teamData={account}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CloudServiceInfo
                            role="player"
                            cloudProvider={cloudProvider}
                            teamData={socketAccount}
                        />
                    </Grid>
                </Grid>
                <Grid className={classes.grid} container spacing={3}>
                    <Grid item xs={6}>
                        <ScenarioCard scenarios={scenarios} />
                    </Grid>
                    <Grid item xs={6}>
                        <Cloudmapper
                            role="player"
                            accountId={account.accountId}
                            cloudmapperLarge={cloudmapperLarge}
                            setCloudmapperLarge={setCloudmapperLarge}
                        />
                    </Grid>
                </Grid>
                <Copyright />
            </Container>
            <ModalStaff
                open={open}
                setOpen={setOpen}
                title="TERMINER LA SESSION"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert
                        title="Attention, tu es sur le point de terminer la session."
                        severity="error"
                    >
                        <p>
                            Terminer la session signifie que tu as fini ta session de jeu. Tu ne
                            pourras donc pas y revenir.
                        </p>
                        <p> Es-tu sûr de vouloir faire cela ?</p>
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.annuler}
                            disableElevation
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpen(false)}
                            startIcon={<CloseIcon />}
                        >
                            Annuler
                        </Button>
                        <Button
                            className={classes.annuler}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                freeAccount(socketAccount);
                                setOpen(false);
                                setOpenModalEndSession(true);
                            }}
                            startIcon={<CloseIcon />}
                        >
                            Terminer la session
                        </Button>
                    </div>
                </React.Fragment>
            </ModalStaff>
            <ModalStaff
                open={openModalEndSession}
                setOpen={setOpenModalEndSession}
                closeAction={logout}
                disableExitButton={disableExitButton}
                title="SESSION TERMINÉE"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert title="Ta session de jeu est terminée" severity="success">
                        Merci d'avoir participé !
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.annuler}
                            disabled={disablePdfButton}
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={() => downloadPdf()}
                        >
                            Voir le rapport
                        </Button>
                        <Button
                            className={classes.annuler}
                            disabled={disableExitButton}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={logout}
                            startIcon={<CloseIcon />}
                        >
                            Fermer
                        </Button>
                    </div>
                </React.Fragment>
            </ModalStaff>
            {cloudmapperLarge ? (
                <Cloudmapper
                    role="player"
                    className={classes.cloudlarge}
                    accountId={account.accountId}
                    cloudmapperLarge={cloudmapperLarge}
                    setCloudmapperLarge={setCloudmapperLarge}
                />
            ) : null}
            <img src={backgroundImage} alt="bg" className={classes.bg} />
        </main>
    );
};
