import React, { useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { CLOUDMAPPER } from "../../constant";
import { Panel } from "../../common/Panel";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/**
 * Cloudmapper component, called by /infrastuctures in the dashboard
 */

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    heading: {
        flexShrink: 0,
        flexBasis: "20%",
    },
    secondaryHeading: {
        color: theme.palette.text.secondary,
    },
}));
export const Infrastructure: React.FC<{ gameConfig: any }> = ({ gameConfig }) => {
    const classes = useStyles();

    const [containers, setContainers] = React.useState<any[]>([]);

    useEffect(() => {
        axios
            .get(`${CLOUDMAPPER}/api/cloudmapper/`)
            .then((response) => {
                setContainers(
                    response.data.filter((key: any) =>
                        gameConfig.availableAccounts.includes(key.id)
                    )
                );
            })
            .catch((error) => {
                console.log(error);
            });
    }, [gameConfig.availableAccounts]);

    return (
        <div>
            <Typography
                color="textPrimary"
                variant="h2"
                style={{ marginTop: "20px", marginBottom: "20px" }}
            >
                INFRASTRUCTURES AWS
            </Typography>
            <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "30px" }} />
            <div>
                {containers && containers.length !== 0 ? (
                    containers.map((container: any) => {
                        return (
                            <div key={container.id}>
                                <Accordion TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <h2 className={classes.heading}>{container.name}</h2>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Panel
                                            cloudmapper={container}
                                            staffing={false}
                                            large={undefined}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        );
                    })
                ) : (
                    <p>No view available</p>
                )}
            </div>
        </div>
    );
};
