import Keycloak from "keycloak-js";
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "./constant";

const keycloakConfig: Keycloak.KeycloakConfig = {
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT_ID,
};

export const keycloak: Keycloak = new Keycloak(keycloakConfig);

export const keycloakProviderInitConfig = {
    onLoad: "login-required",
};
