import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import UpdateTeam from "./UpdateTeam";
import logo from "../../assets/Empowering_Transformation.png";
import Typography from "@material-ui/core/Typography";
import { drawerWidth } from "./GamedayDashboardLayout";
import PublicIcon from "@material-ui/icons/Public";
import RulesIcon from "@material-ui/icons/ImportContacts";
import { BsFillTerminalFill } from "react-icons/bs";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import { AWS_CLOUD_CONSOLE_LINK } from "../../constant";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import { GamedayNavigationItem } from "./GamedayNavigationItem";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AssignmentTurnedInRoundedIcon from "@material-ui/icons/AssignmentTurnedInRounded";
import { GamedayDrawerScoreValue } from "./GamedayDrawerScoreValue";

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        padding: theme.spacing(4, 2),
        width: drawerWidth,
        textAlign: "left",
        color: "#fff",
        backgroundColor: "#111827",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        "&::-webkit-scrollbar": {
            width: 5,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#6b7489",
            borderRadius: theme.spacing(1),
        },
    },
    logo: {
        stroke: theme.palette.secondary.main,
        paddingLeft: theme.spacing(0.5),
        width: theme.spacing(6),
    },
    mainTitle: {
        textAlign: "center",
        fontSize: theme.spacing(4),
        lineHeight: 2,
        margin: theme.spacing(2, 0),
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        textTransform: "uppercase",
        borderRadius: 6,
    },
    sectionTitle: {
        fontSize: "0.75rem",
        fontWeight: 700,
        lineHeight: 2.5,
        margin: theme.spacing(1, 2),
        textTransform: "uppercase",
    },
    divider: {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    navHelper: {
        display: "flex",
        alignItems: "center",
    },
    tooltip: {
        fontSize: 11,
        marginLeft: theme.spacing(3),
    },
    helpContainer: {
        marginTop: "auto",
        "& > .MuiButton-root": {
            borderRadius: 6,
            width: "100%",
        },
    },
}));

export const GamedayDrawer: React.FC = () => {
    const classes = useStyles();
    const [openUpdateTeam, setOpenUpdateTeam] = useState<boolean>(false);

    const handleClickOpenUpdateTeam = () => {
        setOpenUpdateTeam(true);
    };

    const openCloudConsole = () => {
        window.open(AWS_CLOUD_CONSOLE_LINK, "_blank", "noopener,noreferrer");
    };

    const openDiscord = () => {
        window.open("https://discord.gg/Z9fzBFxHJA", "_blank", "noopener,noreferrer");
    };

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <img src={logo} alt="Cloud Challenge" className={classes.logo} draggable={false} />
                <Typography variant="h2" className={classes.mainTitle}>
                    Cloud Challenge
                </Typography>
                <Divider className={classes.divider} />
                <div>
                    <Typography className={classes.sectionTitle}>Score en temps réel</Typography>
                    <GamedayDrawerScoreValue />
                </div>
                <Divider className={classes.divider} />
                <div>
                    <Typography className={classes.sectionTitle}>Pages</Typography>
                    <GamedayNavigationItem
                        label={"Règles du jeu"}
                        icon={<RulesIcon />}
                        href="rules"
                    />
                    <GamedayNavigationItem
                        label={"Étapes à suivre"}
                        icon={<AssignmentTurnedInRoundedIcon />}
                        href="scenario"
                    />
                    <GamedayNavigationItem
                        label={"Tableau des scores"}
                        icon={<InsertChartIcon />}
                        href="scores"
                    />
                </div>
                <div>
                    <Typography className={classes.sectionTitle}>Actions</Typography>
                    <GamedayNavigationItem
                        label={
                            <div className={classes.navHelper}>
                                Mettre à jour le point d'entrée
                                <Tooltip
                                    arrow
                                    classes={{ tooltip: classes.tooltip }}
                                    placement="right"
                                    title="Il s'agit de l'adresse IP publique à partir de laquelle on peut accéder à votre infrastructure depuis un navigateur. Lisez les règles pour plus d'informations."
                                >
                                    <HelpOutlineIcon
                                        color="secondary"
                                        fontSize="small"
                                        data-testid="drawer-help-icon"
                                    />
                                </Tooltip>
                            </div>
                        }
                        icon={<PublicIcon />}
                        onClick={handleClickOpenUpdateTeam}
                    />
                    <GamedayNavigationItem
                        label={"Accéder à la console AWS"}
                        icon={<BsFillTerminalFill style={{ height: 22, width: 22 }} />}
                        onClick={openCloudConsole}
                    />
                </div>
                <Divider className={classes.divider} />
                <div className={classes.helpContainer}>
                    <Typography variant="subtitle2">Besoin d'aide ?</Typography>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        Contactez un admin
                    </Typography>
                    <Button
                        color="primary"
                        disableElevation
                        size="large"
                        variant="contained"
                        onClick={openDiscord}
                    >
                        Ouvrir le discord
                    </Button>
                </div>
            </Drawer>
            <UpdateTeam open={openUpdateTeam} setOpen={setOpenUpdateTeam} />
        </React.Fragment>
    );
};
