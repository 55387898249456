import axios from "axios";
import { BACKEND_API } from "../constant";

export default async function getScenarioData(id: string) {
    try {
        const res = await axios.get(`${BACKEND_API}/scenarios/${id}`);
        return res.data;
    } catch (error) {
        console.error(error);
        alert(
            `impossible de récupérer les scenarios du Cloud-Challenge : GET ${BACKEND_API}/scenarios`
        );
    }
}
