/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import Title from "../../common/Title";
import ScoresIcon from "@material-ui/icons/List";
import { getLastScore, getTeams, sortByAlphabeticalOrder } from "../../common/helpers";
import { SkeletonEmptyState } from "./charts/SkeletonEmptyState";
import { SortingScoreTable } from "./SortingScoreTable";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Status } from "../../interfaces/Types";

export interface Data {
    id: string;
    name: string;
    profit: number;
    up: number;
    down: number;
    status: Status;
    url: string;
}

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    autoComplete: {
        flex: 1,
        maxWidth: 400,
    },
    searchBar: {
        "& .MuiInputBase-root": {
            marginBottom: theme.spacing(2),
            color: "#a1b2c3",
            backgroundColor: "#f4f6f8",
            borderRadius: theme.spacing(1),
            "& > .MuiOutlinedInput-notchedOutline": {
                borderColor: "#a1b2c3",
                transition: theme.transitions.create(["all"]),
            },
            "& > .MuiInputAdornment-positionStart": {
                marginLeft: 6,
                marginRight: 2,
            },
        },
    },
}));

const createData = (id: any, info: any, name: any, scores: any, up: any, down: any, url = "") => {
    return {
        id,
        name: name.toLowerCase(),
        status: info,
        profit: getLastScore(scores),
        up,
        down,
        url,
    };
};

export const GamedayScoresTable: React.FC<{ state: any }> = ({ state }) => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState<string | null>(null);

    const rows: Data[] = useMemo(() => {
        return getTeams(state).map((team: any) =>
            createData(
                team.accountId,
                team.info,
                team.name,
                team.scores,
                team.up,
                team.down,
                team.url
            )
        );
    }, [state, searchValue]);

    const filteredRows: Data[] = useMemo(() => {
        return getTeams(state)
            .filter((str: any) => str.name.toLowerCase().includes(searchValue?.toLowerCase() ?? ""))
            .map((team: any) =>
                createData(
                    team.accountId,
                    team.info,
                    team.name,
                    team.scores,
                    team.up,
                    team.down,
                    team.url
                )
            );
    }, [rows, searchValue]);

    const handleOnChange = (_event: any, newValue: string | null) => {
        setSearchValue(newValue);
    };

    return (
        <React.Fragment>
            <div className={classes.header}>
                <Title>Tableau des scores</Title>
                {!!rows.length && (
                    <SearchBar rows={filteredRows} value={searchValue} onChange={handleOnChange} />
                )}
            </div>
            {rows.length ? (
                <SortingScoreTable rows={filteredRows} state={state} />
            ) : (
                <SkeletonEmptyState
                    height={300}
                    icon={<ScoresIcon />}
                    label="Le tableau des scores des équipes va apparaitre ici."
                />
            )}
        </React.Fragment>
    );
};

interface SearchBarProps {
    rows: Data[];
    value: any;
    onChange: any;
}

const SearchBar: React.FC<SearchBarProps> = ({ rows, value, onChange }) => {
    const classes = useStyles();
    return (
        <Autocomplete
            id="search-scores-table-teamname"
            freeSolo
            disableClearable
            className={classes.autoComplete}
            options={rows.sort(sortByAlphabeticalOrder).map((option) => option.name)}
            value={value}
            onInputChange={onChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    className={classes.searchBar}
                    placeholder="Nom d'équipe..."
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        type: "search",
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchRoundedIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};
