import React, { useEffect, useMemo, useState } from "react";
import { GamedayLegendChip } from "./GamedayLegendChip";
import { useScoreContext } from "../../context/ScoreContext";
import { Menu, Button, makeStyles, Tooltip } from "@material-ui/core";
import { getLastScore, sortByAlphabeticalOrder } from "../../common/helpers";
import { ProfitValue } from "./ProfitValue";

const useStyles = makeStyles((theme) => ({
    menu: {
        "& > .MuiPopover-paper": {
            "&::-webkit-scrollbar": {
                width: 5,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#a4b4d7",
                borderRadius: theme.spacing(1),
            },
        },
    },
    choseTeamButton: {
        width: "100%",
        minHeight: 43,
        fontWeight: 400,
        borderRadius: theme.spacing(0.75),
        marginBottom: theme.spacing(1),
        color: "#fff",
        backgroundColor: "#263556",
        "&:hover": {
            backgroundColor: "#30446e",
        },
        "&.Mui-disabled": {
            color: "#ffffff61",
            backgroundColor: "initial",
        },
    },
    teamValue: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        fontSize: 14,
        gap: theme.spacing(1),
        "& > span": {
            flex: 1,
            lineHeight: 1.25,
            textTransform: "uppercase",
            borderRight: "1px solid #8097c8",
        },
    },
}));

export const GamedayDrawerScoreValue: React.FC = () => {
    const classes = useStyles();

    const { gamedayScores } = useScoreContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedTeam, setSelectedTeam] = useState<string | false>(
        localStorage.getItem("GamedayDrawerSelectedTeam") ?? false
    );
    const [localStorageHasError, setLocalStorageHasError] = useState<boolean>(true);

    const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectNewTeam = (teamId: string) => () => {
        if (localStorage.getItem("GamedayDrawerSelectedTeam") === teamId) {
            localStorage.removeItem("GamedayDrawerSelectedTeam");
            setSelectedTeam(false);
        } else {
            setSelectedTeam(teamId);
            localStorage.setItem("GamedayDrawerSelectedTeam", teamId);
        }
        handleClose();
    };

    const noTeamAvailable = useMemo(() => !Object.keys(gamedayScores).length, [gamedayScores]);

    useEffect(() => {
        if (selectedTeam && !noTeamAvailable) {
            if (!gamedayScores[selectedTeam]) {
                setSelectedTeam(false);
                localStorage.removeItem("GamedayDrawerSelectedTeam");
            } else {
                setLocalStorageHasError(false);
            }
        }
    }, [gamedayScores, noTeamAvailable, selectedTeam]);

    if (noTeamAvailable) {
        return (
            <Tooltip
                arrow
                aria-label="no team registered"
                placement="right"
                title="Vous pourrez choisir votre équipe pour afficher votre score ici."
            >
                <span>
                    <Button
                        disableElevation
                        size="large"
                        onClick={showMenu}
                        className={classes.choseTeamButton}
                        disabled={true}
                    >
                        Aucune équipe enregistrée
                    </Button>
                </span>
            </Tooltip>
        );
    }
    return (
        <React.Fragment>
            <Menu
                id="select-team-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        maxHeight: 280,
                        overflowY: "auto",
                        padding: "8px 16px",
                    },
                }}
            >
                {Object.values(gamedayScores)
                    .sort(sortByAlphabeticalOrder)
                    .map((value: any) => (
                        <GamedayLegendChip
                            key={`legend-${value.name}`}
                            name={value.name}
                            color={value.color}
                            handleClick={selectNewTeam(value.accountId)}
                            isChecked={selectedTeam === value.accountId}
                        />
                    ))}
            </Menu>
            <Button
                disableElevation
                size="large"
                variant="contained"
                onClick={showMenu}
                className={classes.choseTeamButton}
            >
                {selectedTeam && !localStorageHasError ? (
                    <div className={classes.teamValue}>
                        <span>{gamedayScores[selectedTeam].name}</span>
                        <ProfitValue value={getLastScore(gamedayScores[selectedTeam].scores)} />
                    </div>
                ) : (
                    "Choisir mon équipe"
                )}
            </Button>
        </React.Fragment>
    );
};
