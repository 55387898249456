import React, { useState } from "react";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import { Typography } from "@material-ui/core";
import { CloudServiceStatus } from "../../common/CloudServiceStatus";
import { ProfitValue } from "./ProfitValue";
import { Data } from "./GamedayScoresTable";
import { Alert, AlertTitle } from "@material-ui/lab";

type Order = "asc" | "desc";

interface HeadCell {
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
    { id: "name", numeric: false, label: "Nom de l'équipe" },
    { id: "profit", numeric: true, label: "Profits" },
    { id: "up", numeric: true, label: "Service UP" },
    { id: "down", numeric: true, label: "Service DOWN" },
    { id: "status", numeric: false, label: "Statut" },
    { id: "url", numeric: false, label: "URL du service" },
];

const EnhancedTableHead: React.FC<EnhancedTableProps> = ({
    classes,
    order,
    orderBy,
    onRequestSort,
}) => {
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
        },
        table: {
            minWidth: 750,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        tableRow: {
            "&:hover": {
                backgroundColor: "#f8f9fb",
            },
        },
        tablePagination: {
            height: 52,
            minHeight: "52px !important",
            marginTop: theme.spacing(1),
        },
    })
);

const StyledTableCell = withStyles((theme) =>
    createStyles({
        root: {
            fontSize: 14,
            border: "none",
            padding: theme.spacing(1, 2),
        },
        head: {
            padding: theme.spacing(2),
            color: "#637381",
            backgroundColor: "#f4f6f8",
            "&:first-child": {
                borderRadius: theme.spacing(1, 0, 0, 1),
            },
            "&:last-child": {
                borderRadius: theme.spacing(0, 1, 1, 0),
            },
        },
    })
)(TableCell);

const UpDownValue: React.FC<{ value: number; totalValue: number }> = ({ value, totalValue }) => {
    const formatTimestamp = (totalSeconds: number) => {
        var measuredTime = new Date(totalSeconds * 1000);
        const [dateAndHours, min, sec] = measuredTime.toISOString().split(":");
        return `${
            dateAndHours.slice(-2) === "00" ? "" : `${dateAndHours.slice(-2)}h `
        }${min}m ${sec.slice(0, 2)}s`;
    };

    return (
        <React.Fragment>
            {Math.round((value / totalValue || 0) * 100)} %
            <Typography variant="caption" component="div" color="textSecondary">
                {formatTimestamp(value * 3)}
            </Typography>
        </React.Fragment>
    );
};

/**
 * Component for sorting Gameday's scores table.
 * All algorithms come frome Material-UI v4.
 * Refer to https://v4.mui.com/components/tables/#sorting-amp-selecting for more documentation.
 */

export const SortingScoreTable: React.FC<{
    state: any;
    rows: Data[];
}> = ({ rows, state }) => {
    const classes = useStyles();
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof Data>("profit");
    const [rowsPerPage, setRowsPerPage] = useState<number>(rows.length > 5 ? 10 : 5);
    const [page, setPage] = useState<number>(0);

    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {!rows.length && <EmptyState />}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow
                                        tabIndex={-1}
                                        key={row.name}
                                        className={classes.tableRow}
                                    >
                                        <StyledTableCell
                                            component="th"
                                            id={`enhanced-table-checkbox-${index}`}
                                            scope="row"
                                            padding="none"
                                        >
                                            <Chip
                                                key={`chip-${row.id}`}
                                                size="small"
                                                label={row.name}
                                                style={{
                                                    backgroundColor: `${state[row.id].color}33`,
                                                    border: `1px solid ${state[row.id].color}`,
                                                }}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <ProfitValue value={row.profit} />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <UpDownValue
                                                value={row.up}
                                                totalValue={row.up + row.down}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <UpDownValue
                                                value={row.down}
                                                totalValue={row.up + row.down}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <CloudServiceStatus size="small" status={row.status} />
                                        </StyledTableCell>
                                        <StyledTableCell
                                            style={{
                                                maxWidth: 160,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            {row.url ? (
                                                <a href={row.url} target="_BLANK" rel="noreferrer">
                                                    {row.url}
                                                </a>
                                            ) : (
                                                <Typography variant="caption" color="textSecondary">
                                                    Pensez à compléter votre URL
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, { value: rows.length, label: "Voir toutes" }]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage="Nombre d'équipes par page :"
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{ toolbar: classes.tablePagination }}
                />
            </Paper>
        </div>
    );
};

const EmptyState: React.FC = () => {
    return (
        <TableRow>
            <TableCell colSpan={6}>
                <Alert severity="warning" style={{ marginTop: 16 }}>
                    <AlertTitle>Vous ne trouvez pas ce que vous cherchez ?</AlertTitle>
                    <Typography variant="body2">
                        On dirait que vous êtes allé trop loin ! Réessayez avec un autre nom
                        d'équipe. 😉
                    </Typography>
                </Alert>
            </TableCell>
        </TableRow>
    );
};
