import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { GameConfig } from "../../interfaces/GameConfig";
import { Loader } from "../../context/LoaderContext";
import { GamedayPageSideNavigation } from "./GamedayPageSideNavigation";
import ScenarioReadMe from "../../common/ScenarioReadMe";
import { CustomAccordion } from "../../admin/staffing/staff/CustomAccordion";
import { Scenario } from "../../interfaces/Scenario";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { headerHeight } from "./GamedayDashboardLayout";

const navWidth = 172;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > div[id]": {
            scrollMarginTop: 60,
        },
    },
    main: {
        margin: theme.spacing(0, 4),
        width: `calc(100% - 64px - ${navWidth}px)`,
        "& > .MuiTypography-root": {
            margin: theme.spacing(4, 0),
        },
    },
    nav: {
        width: navWidth,
        "& > div": {
            position: "sticky",
            top: 96,
            padding: theme.spacing(2),
        },
    },
    //Offset trick to handle header size when navigating to element
    anchorOffset: {
        marginTop: -headerHeight - 16,
        paddingTop: headerHeight + 16,
    },
}));

interface GamedayRulesPageProps {
    gameConfig: GameConfig;
    gameConfigHasError: boolean;
    isGameConfigLoading: boolean;
}

export const GamedayScenarioPage: React.FC<GamedayRulesPageProps> = ({
    gameConfig,
    gameConfigHasError,
    isGameConfigLoading,
}) => {
    const classes = useStyles();
    useDocumentTitle("Cloud Challenge - Scénario");

    const areScenarioStepDefined = useMemo(() => !!gameConfig.steps?.length ?? false, [gameConfig]);

    if (isGameConfigLoading) {
        return <Loader />;
    }
    if (gameConfigHasError) {
        return (
            <Alert severity="warning">
                <AlertTitle>Une erreur est survenue</AlertTitle>
                <Typography variant="body2">
                    Il est impossible d'afficher les règles du Gameday. Essayez de rafraîchir la
                    page, ou contactez un administrateur.
                </Typography>
            </Alert>
        );
    }

    const rulesNavigationLinks = areScenarioStepDefined
        ? {
              title: {
                  href: "#",
                  label: "Le scénario",
              },
              links: gameConfig.steps.map((scenario: Scenario, index: number) => {
                  return {
                      label: `${index + 1}. ${scenario.title.toUpperCase()}`,
                      href: `#etape-${scenario.title}`,
                  };
              }),
          }
        : {
              title: {
                  href: "#",
                  label: "Rien à afficher 😥",
              },
          };

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.main}>
                    <Typography id="scenarios" variant="h2">
                        ⬇️ LES ÉTAPES À SUIVRE
                    </Typography>
                    <div>
                        {areScenarioStepDefined ? (
                            gameConfig.steps.map((scenario: Scenario, index: number) => (
                                <div
                                    id={`etape-${scenario.title}`}
                                    key={`scenario-${index + 1}-${scenario.title}`}
                                    className={classes.anchorOffset}
                                >
                                    <CustomAccordion
                                        bgColor={"#fff"}
                                        title={`Étape ${
                                            index + 1
                                        } : ${scenario.title.toUpperCase()}`}
                                        defaultExpanded={!index}
                                    >
                                        <ScenarioReadMe
                                            id={`${scenario.id}`}
                                            gameday
                                            rules={true}
                                            userDataUrl={scenario.userData}
                                            showUserData={true}
                                        />
                                    </CustomAccordion>
                                </div>
                            ))
                        ) : (
                            <Alert severity="warning">
                                <AlertTitle>Aucun gameday en cours</AlertTitle>
                                <Typography variant="body2">
                                    Il ne semble avoir aucun gameday en cours, donc rien à faire !
                                    Si cela ne vous semble pas normal, essayez de rafraîchir la
                                    page, ou contactez un administrateur.
                                </Typography>
                            </Alert>
                        )}
                    </div>
                </div>
                <GamedayPageSideNavigation links={[rulesNavigationLinks]} />
            </div>
        </React.Fragment>
    );
};
