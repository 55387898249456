import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography, Box, Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import logo from "../assets/logo.svg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    appBar: {
        flexGrow: 1,
    },
    header: {
        display: "flex",
    },
    logo: {
        marginRight: theme.spacing(2),
    },
    typo: {
        fontSize: 40,
        paddingBottom: theme.spacing(0.5),
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        backgroundColor: "white",
        color: theme.palette.primary.main,
        textTransform: "uppercase",
    },
}));

const UserAvatar: React.FC<{ name: string }> = ({ name }) => {
    const classes = useStyles();

    return (
        <Avatar className={classes.avatar}>
            <Typography>{name[0]}</Typography>
        </Avatar>
    );
};

export const StaffingHeaderBar: React.FC<{ name: string }> = ({ name }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const logout = () => {
        navigate("..");
    };

    return (
        <AppBar elevation={0} className={classes.appBar}>
            <Container>
                <Toolbar disableGutters className={classes.appBar}>
                    <Box display="flex" flexGrow={1}>
                        <img className={classes.logo} src={logo} alt="Logo" />
                        <Typography className={classes.typo} variant="h2">
                            RECRUTEMENT
                        </Typography>
                    </Box>
                    <Button color="inherit" onClick={() => logout()}>
                        <Typography>Déconnexion</Typography>
                    </Button>
                    <UserAvatar name={name} />
                </Toolbar>
            </Container>
        </AppBar>
    );
};
