import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Copyright from "../../common/Copyright";
import { GamedayHeaderBar } from "./GamedayHeaderBar";
import { GamedayDrawer } from "./GamedayDrawer";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

export const drawerWidth = 280;
export const headerHeight = 72;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        marginLeft: drawerWidth,
        marginTop: headerHeight,
        padding: theme.spacing(4),
        width: "100%",
        textAlign: "left",
    },
    container: {
        padding: theme.spacing(4, 0),
    },
}));

/**
 * Displays Gameday dashboard Layout.
 *
 * All child element of this route are rendered instead of the `Outlet` component.
 * Refer to https://reactrouterdotcom.fly.dev/docs/en/v6/getting-started/tutorial#nested-routes for more documentation.
 *
 * @return {JSX} Gameday dashboard Headerbar, drawer and child's routes.
 */

export const GamedayDashboardLayout: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/player/gameday") {
            navigate("rules");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GamedayHeaderBar />
            <GamedayDrawer />
            <div className={classes.content}>
                <Outlet />
                <Box pt={4}>
                    <Copyright />
                </Box>
            </div>
        </div>
    );
};
