import React, { useState } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../../../theme";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
        marginBottom: 12,
    },
    pos: {
        marginBottom: 12,
    },
    logout: {
        marginBottom: 12,
    },
    typo: {
        color: theme.palette.primary.main,
        fontSize: "36px",
        marginBottom: theme.spacing(2),
    },
});

interface EndWatchProps {
    account: any;
    freeAccount: any;
    setOpenModalEndSession: any;
}

export const EndWatch: React.FC<EndWatchProps> = ({
    account,
    freeAccount,
    setOpenModalEndSession,
}) => {
    const classes = useStyles();

    const [time, setTime] = useState<number>(0);
    const [isActive, setIsActive] = useState<boolean>(false);

    React.useEffect(() => {
        let now = Date.now();
        let end = new Date(account.endSession);
        setTime(end.getTime() - now);
        setIsActive(true);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (account.info === "Disabled") {
            setIsActive(false);
        }

        // eslint-disable-next-line
    }, [account]);

    React.useEffect(() => {
        let interval: NodeJS.Timer;
        if (isActive) {
            interval = setInterval(() => {
                setTime((time) => time - 1000);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [isActive]);

    React.useEffect(() => {
        if (time < 0) {
            setIsActive(false);
            freeAccount(account);
            setOpenModalEndSession(true);
        }
        // eslint-disable-next-line
    }, [time]);

    return (
        <Typography variant="h2" className={classes.typo} component="h2">
            {isActive
                ? ("0" + Math.floor((time / 3600000) % 60)).slice(-2) +
                  ":" +
                  ("0" + Math.floor((time / 60000) % 60)).slice(-2) +
                  ":" +
                  ("0" + Math.floor((time / 1000) % 60)).slice(-2)
                : "00:00:00"}
        </Typography>
    );
};
