/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_API } from "../../constant";
// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { useForm } from "../../hooks/useForm";
import { useCustomAlertContext } from "../../context/CustomAlertContext";
import { ScenarioDictionnary } from "../../interfaces/Scenario";
import { StaffSetupForm } from "./staffSetup/StaffSetupForm";
import { StaffSetupFormStepper } from "./staffSetup/StaffSetupFormStepper";
import { useNavigate } from "react-router-dom";
import { useScenarioDataContext } from "../../context/ScenarioDataContext";
import { useLoaderContext } from "../../context/LoaderContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5, 2),
        backgroundColor: "#FFFFFF",
    },
    title: {
        textAlign: "center",
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
    container: {
        display: "flex",
    },
    formContainer: {
        flex: 1,
    },
}));

export interface formValues {
    selectedScenarioSteps: number[];
    username: string;
    levelCandidate: string;
    cloudService: string;
}

export const StaffSetup: React.FC = () => {
    const classes = useStyles();
    const [stepperIndex, setStepperIndex] = useState<number>(0);
    const [emptyState, setEmptyState] = useState<boolean>(false);
    const [levelListScenarios, setLevelListScenarios] = useState<ScenarioDictionnary>({});
    const { setIsLoading } = useLoaderContext();

    const modelObjectForm: formValues = {
        username: "",
        cloudService: "",
        levelCandidate: "",
        selectedScenarioSteps: [],
    };

    const navigate = useNavigate();

    const { values, setValues, handleInputChange, resetForm } = useForm(modelObjectForm);
    const { scenarioData, isScenarioDataLoading } = useScenarioDataContext();
    const { showCustomAlert } = useCustomAlertContext();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (checkFormValues()) {
            await createStaffingSession();
            setIsLoading(false);
            resetForm();
        }
    };

    const handleScenarioClick = (scenarioTitle: string, index: number) => {
        let newValues: formValues;
        if (values.scenarioTitle === scenarioTitle) {
            if (values.selectedScenarioSteps.includes(index)) {
                newValues = {
                    ...values,
                    selectedScenarioSteps: values.selectedScenarioSteps.filter(
                        (elem: number) => elem !== index
                    ),
                };
            } else {
                newValues = {
                    ...values,
                    selectedScenarioSteps: [...values.selectedScenarioSteps, index].sort(),
                };
            }
        } else {
            newValues = {
                ...values,
                scenarioTitle,
                selectedScenarioSteps: [],
            };
        }
        setValues(newValues);
    };

    const checkChosenScenarios = (scenarioFirstStep: number, scenarioLastStep: number) => {
        if (!values.selectedScenarioSteps.length) {
            return false;
        } else {
            for (let index = scenarioFirstStep; index < scenarioLastStep; index++) {
                const consecutiveScenarioSelected = values.selectedScenarioSteps.includes(index);
                if (!consecutiveScenarioSelected) {
                    return false;
                }
            }
        }
        return true;
    };

    const getFirstScenarioGitUrl = (): string => {
        return levelListScenarios[values.scenarioTitle][values.selectedScenarioSteps[0]].gitUrl;
    };

    const checkFormValues = (): boolean => {
        if (values.username === "") {
            showCustomAlert(
                "error",
                "Formulaire incomplet",
                "Veuillez indiquez le nom du candidat."
            );
            return false;
        }
        if (values.levelCandidate === "") {
            showCustomAlert(
                "error",
                "Formulaire incomplet",
                "Veuillez sélectionner le niveau du candidat."
            );
            return false;
        }
        if (values.cloudService === "") {
            showCustomAlert(
                "error",
                "Formulaire incomplet",
                "Veuillez choisir le service Cloud sur lequel vous voulez tester le candidat."
            );
            return false;
        }
        if (
            !checkChosenScenarios(
                values.selectedScenarioSteps[0],
                values.selectedScenarioSteps[values.selectedScenarioSteps.length - 1]
            )
        ) {
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "Veuillez séléctionner un ou plusieurs scénarios successifs."
            );
            return false;
        }
        return true;
    };

    const createStaffingSession = () => {
        setIsLoading(true);
        showCustomAlert(
            "info",
            "Chargement",
            "Veuillez patienter lors de la création du compte..."
        );
        const data = {
            username: values.username,
            levelCandidate: values.levelCandidate,
            scenarioStepStart: values.selectedScenarioSteps[0],
            scenarioStepEnd: values.selectedScenarioSteps[values.selectedScenarioSteps.length - 1],
            scenarioGitUrl: getFirstScenarioGitUrl(),
            useCase: "Staffing",
            provider: values.cloudService,
        };
        return axios
            .post(`${BACKEND_API}/teams`, data)
            .then((response) => {
                if (response.data.status === 200) {
                    showCustomAlert(
                        "success",
                        "C'est parti !",
                        "Le compte candidat a été correctement créé."
                    );
                    navigate(`/admin/staffing/${values.username}`);
                } else {
                    switch (response.data.error.code) {
                        case "EntityAlreadyExists":
                            showCustomAlert(
                                "error",
                                "Une erreur est survenue",
                                "Nom d'utilisateur déjà pris."
                            );
                            break;
                        case "UsernameAlreadyExists":
                            showCustomAlert(
                                "error",
                                "Une erreur est survenue",
                                "Ce nom d'utilisateur est déjà pris."
                            );
                            break;
                        case "NoMoreAccounts":
                            showCustomAlert(
                                "error",
                                "Une erreur est survenue",
                                "Plus de comptes diponibles."
                            );
                            break;
                        default:
                            showCustomAlert(
                                "error",
                                "Une erreur est survenue.",
                                `Veuillez réessayer (${JSON.stringify(response.data.error)}).`
                            );
                            break;
                    }
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                showCustomAlert(
                    "error",
                    "Une erreur est survenue.",
                    `Veuillez réessayer (${error}).`
                );
            });
    };

    useEffect(() => {
        const levelCandidate = values.levelCandidate;
        setLevelListScenarios(scenarioData[levelCandidate]);
        setValues({
            ...values,
            selectedScenarioSteps: [],
        });
    }, [values.levelCandidate]);

    useEffect(() => {
        // TEMPORARY SOLUTION WHILE API IS NOT SENDING CORRECT INFORMATION
        let awsScenarios: ScenarioDictionnary = {};
        let azureScenarios: ScenarioDictionnary = {};
        if (scenarioData[values.levelCandidate]) {
            Object.keys(scenarioData[values.levelCandidate]).forEach((scenario) => {
                if (scenario.includes("azure")) {
                    azureScenarios = {
                        ...azureScenarios,
                        [scenario]: scenarioData[values.levelCandidate][scenario],
                    };
                } else {
                    awsScenarios = {
                        ...awsScenarios,
                        [scenario]: scenarioData[values.levelCandidate][scenario],
                    };
                }
            });
        }
        if (values.cloudService === "AWS") {
            setEmptyState(Object.keys(awsScenarios).length === 0);
        }
        if (values.cloudService === "Azure") {
            setEmptyState(Object.keys(azureScenarios).length === 0);
        }
        setLevelListScenarios(values.cloudService === "AWS" ? awsScenarios : azureScenarios);
    }, [values.cloudService, values.levelCandidate]);

    useEffect(() => {
        if (values.levelCandidate && values.username) {
            setStepperIndex(1);
        }
        if (values.levelCandidate && values.username && values.cloudService) {
            setStepperIndex(2);
        }
    }, [values]);

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography color="primary" variant="h2">
                    <b>NOUVELLE SESSION </b>DE RECRUTEMENT
                </Typography>
                <Divider variant="middle" className={classes.divider} />
            </div>
            <div className={classes.container}>
                <div>
                    <StaffSetupFormStepper activeStep={stepperIndex} />
                </div>
                <div className={classes.formContainer}>
                    {!isScenarioDataLoading && (
                        <StaffSetupForm
                            stepperIndex={stepperIndex}
                            isEmpty={emptyState}
                            values={values}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            levelListScenarios={levelListScenarios}
                            handleScenarioClick={handleScenarioClick}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
