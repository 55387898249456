import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { CustomAccordion } from "./CustomAccordion";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ModalScenarioReadme from "../ModalScenarioReadme";
import checked from "../../../assets/checked.svg";
import unchecked from "../../../assets/unchecked.svg";
import { Team } from "../../../interfaces/Team";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    icon: {
        color: theme.palette.primary.main,
    },
    block: {
        marginBottom: theme.spacing(1),
    },
    details: {
        display: "block",
        minHeight: "220px",
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    button: {
        width: "100%",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(0.5, 1.5),
        background: "#FFFFFF",
    },
    bulletElement: {
        minHeight: 44,
        "& > * > img": {
            padding: "7px 0",
        },
        "& > * > .MuiTypography-root": {
            fontSize: 13,
            lineHeight: 1.2,
        },
        "& > * > .MuiTimelineConnector-root": {
            backgroundColor: "#c1d0f5",
        },
    },
}));

interface AccordionScenarioProps {
    title: string;
    step: number;
    description: string;
    defaultExpanded: boolean;
    scenarioId: string;
    rules: boolean;
    teamData: Team;
}

interface BulletElementProps {
    isLast: boolean;
    isCompleted: boolean;
    label: string;
}

const BulletElement: React.FC<BulletElementProps> = ({ isCompleted, label, isLast }) => {
    const classes = useStyles();
    return (
        <TimelineItem className={classes.bulletElement}>
            <TimelineOppositeContent style={{ display: "none" }}></TimelineOppositeContent>
            <TimelineSeparator>
                <img
                    src={isCompleted ? checked : unchecked}
                    alt={isCompleted ? "checked" : "unchecked"}
                />
                {!isLast && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Typography color={isCompleted ? "textPrimary" : "textSecondary"}>
                    {label}
                </Typography>
            </TimelineContent>
        </TimelineItem>
    );
};

export const AccordionScenario: React.FC<AccordionScenarioProps> = ({
    title,
    step,
    description,
    defaultExpanded,
    scenarioId,
    rules,
    teamData,
}) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleDetails = () => {
        setOpenModal(true);
    };

    return (
        <CustomAccordion
            defaultExpanded={defaultExpanded}
            className={classes.root}
            title={`Étape ${step} : ${title.toUpperCase()}`}
            bgColor={"#F0F4FB"}
        >
            <Box display="flex" alignItems="center" flexDirection="row">
                <div>
                    <Typography variant="subtitle2" paragraph>
                        <i>{description}</i>.
                    </Typography>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        color="primary"
                        onClick={handleDetails}
                    >
                        Règles fournies au candidat
                    </Button>
                    <Typography variant="body2">Progression du candidat :</Typography>
                    <Timeline>
                        {Object.keys(teamData.bullets).map((bulletTitle, index: number) => (
                            <BulletElement
                                key={`${bulletTitle}-${index}`}
                                label={teamData.bullets[bulletTitle].goal}
                                isLast={index === Object.keys(teamData.bullets).length - 1}
                                isCompleted={teamData.bullets[bulletTitle].completed}
                            />
                        ))}
                    </Timeline>
                </div>
                <Divider className={classes.divider} />
                <ModalScenarioReadme
                    open={openModal}
                    setOpen={setOpenModal}
                    rules={rules}
                    scenarioId={scenarioId}
                    step={step}
                />
            </Box>
        </CustomAccordion>
    );
};
