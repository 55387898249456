import React from "react";
import axios from "axios";
import { S3_SCENARIOS_URL } from "../constant";

import { makeStyles } from "@material-ui/core";
import getScenarioData from "./functions";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import CustomAlert from "./CustomAlert";
import Box from "@material-ui/core/Box";
import ClipboardUserData from "./ClipboardUserData";
import ReactMarkdown from "react-markdown";
//@ts-ignore
import rehypeHighlight from "rehype-highlight";

const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: "#EBF8FF",
        padding: theme.spacing(1, 2, 0.25, 2),
    },
    markdownStaffing: {
        textAlign: "left",
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        "& blockquote": {
            "& p": {
                marginBottom: "1em",
            },
        },
        "& p": {
            paddingBottom: "1em",
        },
        "& pre": {
            justifyContent: "left",
        },
        "& h2": {
            marginBottom: "8px",
        },
        "& ul": {
            paddingLeft: "16px",
            paddingBottom: "16px",
        },
    },
    markdownGameday: {
        textAlign: "left",
        marginBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),

        "& blockquote": {
            "& p": {
                marginBottom: "1em",
            },
        },
        "& p": {
            margin: theme.spacing(2, 0),
        },
        "& img": {
            width: "100%",
            height: "auto",
        },
        "& table": {
            borderCollapse: "collapse",
            backgroundColor: "#f4f6f8",
            borderLeft: "4px solid #ced8f4",
        },
        "& th, td": {
            borderBottom: "1px solid #eaeff7",
            padding: theme.spacing(1, 2),
        },
        "& th": {
            textTransform: "uppercase",
            padding: theme.spacing(1.5, 2.5),
        },
        "& pre": {
            justifyContent: "left",
        },
        "& h2": {
            marginBottom: "8px",
        },
        "& h4": {
            margin: theme.spacing(1, 0),
        },
        "& ul": {
            paddingLeft: "16px",
            paddingBottom: "16px",
        },
    },
}));

interface ScenarioReadMeProps {
    id: string;
    rules: any;
    userDataUrl: string;
    showUserData: boolean;
    gameday: any;
}

const ScenarioReadMe: React.FC<ScenarioReadMeProps> = ({
    id,
    rules,
    userDataUrl,
    showUserData,
    gameday,
}) => {
    const classes = useStyles();
    const [scenario, setScenario] = React.useState({});
    const [userData, setUserData] = React.useState("");
    const [input, setInput] = React.useState("");

    const components: any = {
        p({
            node,
            inline,
            className,
            children,
            type,
            ...props
        }: {
            node: any;
            inline: string;
            className: string;
            children: any;
            type: string;
        }) {
            const match = children[0].type === "strong";
            return match ? (
                <CustomAlert
                    title={"Important"}
                    severity={"warning"}
                    message={children}
                    {...props}
                />
            ) : (
                <p children={children} {...props} />
            );
        },

        em: ({ node, ...props }: { node: any }) => <strong {...props} />,
        h3: "h4",
        blockquote: ({ node, ...props }: { node: any }) => (
            <Box className={classes.box} {...props} />
        ),
        code({
            node,
            inline,
            className,
            children,
            ...props
        }: {
            node: string;
            inline: string;
            className: string;
            children: React.ReactNode;
        }) {
            const match = /language-(\w+)/.exec(className);
            return !inline && match ? (
                showUserData ? (
                    <div
                        style={{
                            minWidth: 400,
                            width: "calc(100vw - 680px)",
                            overflowX: "scroll",
                            backgroundColor: "#f4f6f8",
                            borderLeft: "4px solid #ced8f4",
                            paddingLeft: 16,
                        }}
                    >
                        <ClipboardUserData userData={userData} />
                        <br />
                        <code className={className} {...props}>
                            {children}
                        </code>
                    </div>
                ) : (
                    <ClipboardUserData userData={userData} />
                )
            ) : (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
        img: ({ alt, src, title }: { alt: string; src: string; title: string }) => (
            <img alt={alt} src={src} title={title} style={{ maxWidth: 525 }} />
        ),
    };

    const getUserData = async (userDataUrl: any) => {
        try {
            const res = await axios.get(`${userDataUrl}`, { headers: { Authorization: "" } });
            return res.data;
        } catch (error: any) {
            if (error.response.status === 404) return "not found";
        }
    };

    const getInput = async () => {
        try {
            const scenario = await getScenarioData(id);
            const userDataText = rules === true ? await getUserData(userDataUrl) : "";
            const instructionsUrl = rules === true ? scenario.rules : scenario.readme;

            if (scenario) {
                // Remove Authorization header in HTTP request
                const instructionsFile = await axios.get(instructionsUrl, {
                    headers: { Authorization: "" },
                });
                const instructionsText = await instructionsFile.data;

                setScenario(scenario);
                setInput(instructionsText);
                setUserData(userDataText);
            }
        } catch (err) {
            console.error("Problem reading markdown file", err);
        }
    };

    const buildImageUri = (scenario: any, image: any) =>
        image.startsWith("http")
            ? image
            : `${S3_SCENARIOS_URL}/${scenario.name}/${scenario.path}/${image}`;

    React.useEffect(() => {
        getInput();
        // eslint-disable-next-line
    }, [id]);

    return (
        <ReactMarkdown
            className={gameday ? classes.markdownGameday : classes.markdownStaffing}
            components={components}
            remarkPlugins={[gfm, breaks]}
            rehypePlugins={[rehypeHighlight]}
            children={input}
            transformImageUri={(image: any) => buildImageUri(scenario, image)}
        />
    );
};
export default ScenarioReadMe;
