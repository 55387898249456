import React from "react";
import {
    Typography,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody,
    Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Lottie from "lottie-react";
import moneyAnimation from "../../../assets/moneyAnimation.json";
import { CloudServiceStatus } from "../../../common/CloudServiceStatus";
import CustomAlert from "../../../common/CustomAlert";
import { Link } from "react-router-dom";
import { headerHeight } from "../GamedayDashboardLayout";

const useStyles = makeStyles((theme) => ({
    rulesContainer: {
        marginBottom: theme.spacing(3),
        "& > .MuiDivider-root": {
            margin: theme.spacing(3, 0),
        },
    },
    rulesCitation: {
        backgroundColor: "#f4f6f8",
        padding: theme.spacing(1.5, 2.5),
        borderRadius: theme.spacing(0.5),
        borderLeft: "4px solid #ced8f4",
    },
    //Offset trick to handle header size when navigating to element
    anchorOffset: {
        marginTop: -headerHeight - 16,
        paddingTop: headerHeight + 16,
    },
}));

export const GamedayGeneralRules: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.rulesContainer}>
            <Typography color="textPrimary" variant="h2" gutterBottom>
                📑 LES RÈGLES DU JEU
            </Typography>
            <Divider />
            <div id="rules" className={classes.anchorOffset}>
                <Typography variant="h3" paragraph>
                    Bienvenue au Cloud Challenge IPPON !
                </Typography>
                <Typography>
                    Ce jeu a pour but de tester vos connaissances AWS via la console web.
                </Typography>
                <Typography paragraph>
                    Consultez les règles de chaque étape pour remporter ce Game Day !
                </Typography>
                <Typography paragraph className={classes.rulesCitation}>
                    Notre serveur exécute régulièrement des requêtes sur votre application web,
                    chaque requête correctement exécutée vous rapporte de l’argent.
                </Typography>
            </div>
            <Divider />
            <div id="action" className={classes.anchorOffset}>
                <Typography variant="h3" paragraph>
                    💪 Entrez dans l'action !
                </Typography>
                <Typography paragraph>
                    Pour entrer dans la partie, veuillez d'abord suivre ces quelques étapes :
                </Typography>
                <ol className={classes.rulesCitation} style={{ paddingLeft: 24 }}>
                    <li>
                        <Typography paragraph>
                            Un administrateur va vous communiquer les credentials de votre équipe
                            via le serveur Discord,
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom>
                            Une architecture de départ sera générée automatiquement pour vous.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Si, dans le tableau des scores, la colonne <strong>Statut</strong> de
                            votre équipe est affiché comme étant
                            <span style={{ marginLeft: 8 }}>
                                <CloudServiceStatus size="small" status={"Ready"} />
                            </span>
                            , cela signifie que votre infrastructure est prête ! Dans le cas
                            contraire, veuillez patienter (cela peut prendre jusqu'à 1 minute) et à
                            défaut, contactez un administrateur.
                        </Typography>
                        <Typography paragraph variant="body2">
                            Connectez-vous à la console via le bouton
                            <b> Accéder à la console AWS</b>, ou bien en vous rendant directement
                            sur le lien généré dans votre fichier credentials et commencez à
                            explorer vos ressources.
                        </Typography>
                    </li>
                    <CustomAlert
                        title="À votre tour !"
                        message={
                            "Une fois cette deuxième étape validée, vous pouvez suivre le scénario et commencer à jouer. Mais pour pouvoir scorer, il est important de savoir mettre à jour son point d'entrée. ⬇️"
                        }
                        severity={"info"}
                    />
                    <li>
                        <Typography gutterBottom>
                            <b>Mettre à jour son point d'entrée</b> :
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Sur le dashboard, cliquez sur <b>Mettre à jour son point d'entrée</b>{" "}
                            depuis la navigation et indiquez le mot de passe du compte de votre team
                            ainsi que l'url permettant d'accéder à votre application (adresse IP
                            publique pour une EC2 ou bien le DNS Name de votre LoadBalancer par
                            exemple).
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Le serveur du jeu teste l'état de votre service en envoyant toutes les 3
                            secondes une requête vers votre endpoint.
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Si une requête prend plus d'une minute pour donner une réponse, ou bien
                            si la réponse renvoie un code d'erreur, le compteur{" "}
                            <strong>Down</strong> sera incrémenté. Si tout va bien, c'est le{" "}
                            <strong>Up</strong> !
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            N'oubliez pas de mettre à jour votre entry point si vos ressources
                            changent au cours de la partie !
                        </Typography>
                    </li>
                </ol>
            </div>
            <Divider />
            <div id="scores" className={classes.anchorOffset} style={{ display: "flex" }}>
                <div style={{ flex: 2 }}>
                    <Typography variant="h3" paragraph>
                        📈 Attention au score !
                    </Typography>
                    <Typography gutterBottom>
                        Le Cloud-Challenge étant avant tout un jeu, vous êtes en concurrence directe
                        avec les autres équipes. Chaque minute de jeu, votre score est calculé.
                    </Typography>
                    <Typography paragraph>
                        Les équipes peuvent visualiser l’évolution de leur score, minute par minute,
                        depuis les graphiques proposés par le dashboard du jeu dans l'onglet{" "}
                        <Link to="../scores" target={"_blank"}>
                            Tableau des scores
                        </Link>
                        .
                    </Typography>
                    <CustomAlert
                        title="Mise à jour des scores"
                        message={
                            "Le dashboard se met à jour automatiquement toutes les 10 secondes (il n’est donc pas nécessaire d’actualiser la page manuellement)."
                        }
                        severity={"info"}
                    />
                    <Typography paragraph color="textSecondary"></Typography>
                    <ul
                        className={classes.rulesCitation}
                        style={{ paddingLeft: 24, marginBottom: 16 }}
                    >
                        <li>
                            1 requête correctement traitée vous rapporte <strong>$8.303</strong>.
                        </li>
                        <li>
                            1 requête non traitée <strong>ne vous rapporte pas d’argent</strong> et
                            ne vous en fait pas perdre non plus.
                        </li>
                    </ul>
                    <Typography paragraph>
                        Chaque ressource que vous utilisez vous sera facturée proportionnellement à
                        son utilisation.
                    </Typography>
                </div>
                <Lottie loop style={{ flex: 1 }} animationData={moneyAnimation} />
            </div>
            <Divider />
            <div id="prices" className={classes.anchorOffset}>
                <Typography variant="h3" paragraph>
                    💸 Voici les tarifs pratiqués par instance :
                </Typography>
                <TableContainer
                    className={classes.rulesCitation}
                    style={{ paddingBottom: 8, paddingTop: 8, marginTop: 22 }}
                >
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <strong>EC2</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>$13,2 </strong>par minute, pour chaque{" "}
                                    <strong>Linux t2.micro</strong>.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <strong>Load Balancer</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>$26,46 </strong> par minute, pour chaque{" "}
                                    <strong>LoadBalancer</strong>.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                    <strong>RDS</strong>
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                    <strong>$19 </strong> par minute, pour chaque{" "}
                                    <strong>Instance RDS </strong>.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};
