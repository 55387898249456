import { useEffect } from "react";

export const useDocumentTitle = (title: string) => {
    useEffect(() => {
        const initialTitle = document.title;
        document.title = title;
        return () => {
            document.title = initialTitle;
        };
    }, [title]);
};
