import React from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import CloudCircleIcon from "@material-ui/icons/CloudCircle";
import CachedIcon from "@material-ui/icons/Cached";
import BlockIcon from "@material-ui/icons/Block";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Status } from "../interfaces/Types";

interface CloudServiceStatusProps {
    size?: "medium" | "small";
    status: Status;
}

export const CloudServiceStatus: React.FC<ChipProps & CloudServiceStatusProps> = ({
    size = "medium",
    status,
}) => {
    const theme = useTheme();

    const enumStatus = {
        init: {
            icon: <CachedIcon fontSize="small" />,
            color: theme.palette.info.main,
            tooltipText: "Compte en cours d'initialisation",
        },
        Error: {
            icon: <ErrorIcon fontSize="small" />,
            color: theme.palette.error.main,
            tooltipText: "Une erreur est survenue",
        },
        Ready: {
            icon: <CheckCircleIcon fontSize="small" />,
            color: theme.palette.success.main,
            tooltipText: "Compte prêt",
        },
        Wait: {
            icon: <CloudCircleIcon fontSize="small" />,
            color: theme.palette.warning.main,
            tooltipText: "Infrastructure en cours de création",
        },
        Disabled: {
            icon: <BlockIcon fontSize="small" />,
            color: theme.palette.text.disabled,
            tooltipText: "Compte désactivé",
        },
    };

    return (
        <Chip
            component="span"
            color="primary"
            variant="outlined"
            size={size}
            label={enumStatus[status].tooltipText}
            style={{
                color: enumStatus[status].color,
                borderColor: enumStatus[status].color,
            }}
            icon={enumStatus[status].icon}
        />
    );
};
