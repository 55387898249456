import React from "react";
// Material UI
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ScenarioCard from "./../staffSetup/ScenarioCard";
import { Scenario, ScenarioDictionnary } from "../../../interfaces/Scenario";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles((theme) => ({
    root: {
        boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
        padding: theme.spacing(1, 4),
        marginTop: theme.spacing(2),
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 40,
        textTransform: "capitalize",
        "&$expanded": {
            minHeight: 40,
        },
    },
    content: {
        margin: 0,
        "&$expanded": {
            margin: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        padding: 0,
        display: "block",
    },
})(MuiAccordionDetails);

interface StaffScenarioSelectionProps {
    values: any;
    levelListScenarios: ScenarioDictionnary;
    handleScenarioClick: any;
}

export const StaffScenarioSelection: React.FC<StaffScenarioSelectionProps> = ({
    values,
    levelListScenarios,
    handleScenarioClick,
}) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleScenarioChange =
        (scenarioName: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
            setExpanded(isExpanded ? scenarioName : false);
            handleScenarioClick(scenarioName);
        };

    const formatTitle = (title: string): string => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_scenario, _difficulty, number, ...name] = title.split("-");
        return `Scénario ${number} - ${name.join(" ")}`;
    };

    return (
        <div>
            <Typography color="textPrimary" variant="h4">
                Sélection du scénario
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
                Sélectionnez d’abord un scénario, puis une ou plusieurs étapes successives.
            </Typography>
            {Object.keys(levelListScenarios).map((title) => (
                <Accordion
                    key={`div-${title}`}
                    data-testid={title}
                    expanded={expanded === title}
                    onChange={handleScenarioChange(title)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${title}-content`}
                        id={`${title}-header`}
                    >
                        <Typography variant="h4" color="primary">
                            {formatTitle(title)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {Object.values(levelListScenarios[title]).map(
                                (scenario: Scenario, i: number) => (
                                    <ScenarioCard
                                        key={`scenario-${scenario.name}-${i}`}
                                        scenario={scenario}
                                        isSelected={
                                            values.scenarioTitle === title &&
                                            values.selectedScenarioSteps.includes(i)
                                        }
                                        step={i + 1}
                                        onClick={() => handleScenarioClick(title, i)}
                                    />
                                )
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};
