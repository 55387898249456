import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import CustomAlert from "./CustomAlert";
import { AlertProps } from "@material-ui/lab";

interface CustomAlertPopupProps {
    title: string;
    severity: AlertProps["severity"];
    open: boolean;
    message: string;
    handleClose: AlertProps["onClose"];
    handleExited?: any;
}

const CustomAlertPopup: React.FC<CustomAlertPopupProps> = ({
    title,
    severity,
    open,
    message,
    handleClose,
    handleExited,
}) => {
    return (
        <Snackbar
            key={message}
            open={open}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            style={{ maxWidth: 600, textAlign: "left" }}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
        >
            <CustomAlert
                onClose={handleClose}
                title={title}
                message={message}
                severity={severity}
            />
        </Snackbar>
    );
};
export default CustomAlertPopup;
