import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Input from "../../../common/Input";
import { Form } from "../../../common/useForms";
import Button from "@material-ui/core/Button";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
import { ScenarioDictionnary } from "../../../interfaces/Scenario";
import { StaffScenarioSelection } from "./StaffScenarioSelection";
import { formValues } from "../StaffSetup";
import { Collapse } from "@material-ui/core";
import ChipRadioFormGroup, { ChipRadioInterface } from "./ChipRadioFormGroup";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.primary,
    },
    marginBottom: {
        marginBottom: theme.spacing(3),
        "& > *": {
            marginBottom: theme.spacing(0.5),
        },
    },
    button: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.primary,
    },
}));

export interface StaffSetupFormProps {
    stepperIndex: number;
    isEmpty: boolean;
    values: formValues;
    handleInputChange: any;
    handleSubmit: (e: any) => void;
    levelListScenarios: ScenarioDictionnary;
    handleScenarioClick: (scenarioTitle: string, index: number) => void;
}

export const StaffSetupForm: React.FC<StaffSetupFormProps> = ({
    stepperIndex,
    isEmpty,
    values,
    handleInputChange,
    handleSubmit,
    levelListScenarios,
    handleScenarioClick,
}) => {
    const classes = useStyles();

    const candidateLevels: ChipRadioInterface[] = [
        { value: "easy", label: "Junior" },
        { value: "medium", label: "Confirmé" },
        { value: "hard", label: "Sénior" },
    ];

    const cloudServices: ChipRadioInterface[] = [
        { value: "AWS", label: "AWS" },
        { value: "Azure", label: "Azure" },
    ];

    return (
        <Form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.marginBottom}>
                <Typography color="error" variant="subtitle1">
                    Tous les champs sont obligatoires
                </Typography>
            </div>
            <div className={classes.marginBottom}>
                <Typography color="textPrimary" variant="h4">
                    Nom du candidat
                </Typography>
                <Typography color="textSecondary" variant="subtitle1">
                    Le nom que vous donnez est celui que le candidat devra saisir pour se connecter.
                </Typography>
                <Input name="username" value={values.username} onChange={handleInputChange} />
            </div>
            <ChipRadioFormGroup
                title={"Niveau d'expérience du candidat"}
                inputName={"levelCandidate"}
                formValue={values.levelCandidate}
                handleInputChange={handleInputChange}
                chipInputValues={candidateLevels}
            />
            <Collapse in={stepperIndex >= 1} mountOnEnter unmountOnExit>
                <ChipRadioFormGroup
                    title={"Sélection du service Cloud"}
                    helper={"Choisissez sur quel service Cloud vous souhaitez tester le candidat."}
                    inputName={"cloudService"}
                    formValue={values.cloudService}
                    handleInputChange={handleInputChange}
                    chipInputValues={cloudServices}
                />
            </Collapse>
            <Collapse in={stepperIndex >= 2} mountOnEnter unmountOnExit>
                <div>
                    <StaffScenarioSelection
                        values={values}
                        levelListScenarios={levelListScenarios}
                        handleScenarioClick={handleScenarioClick}
                    />
                    {isEmpty && <EmptyState />}
                </div>
            </Collapse>
            <div className={classes.button}>
                <Button disableElevation variant="contained" color="primary" type="submit">
                    <Grid container direction="row" alignItems="center">
                        Lancer la session
                        <ArrowForwardIcon style={{ marginLeft: "10px" }} fontSize="small" />
                    </Grid>
                </Button>
            </div>
        </Form>
    );
};

const EmptyState: React.FC = () => {
    return (
        <Alert severity="warning" style={{ marginTop: 16 }}>
            <AlertTitle>Scénario en cours d'écriture !</AlertTitle>
            <Typography variant="body2">
                Nous faisons de notre mieux pour les fournir au plus tôt. Pourquoi ne pas essayer
                avec d'autres paramètres en attendant ?
            </Typography>
        </Alert>
    );
};
