import React from "react";
import axios from "axios";

import Button from "@material-ui/core/Button";
import WarningIcon from "@material-ui/icons/Warning";

import { CLOUDMAPPER, BACKEND_API } from "../constant";

interface FreeAccountProps {
    team: any;
    text: string;
    disabled: boolean;
    style: React.CSSProperties;
}

export const FreeAccount: React.FC<FreeAccountProps> = ({ team, text, disabled, style }) => {
    /**
     * Function called with the function nukeIAM
     * @param accountId : account_number to destroy Cloudmapper container
     */

    const deleteCloudmapper = async (accountId: string) => {
        try {
            await axios.delete(`${CLOUDMAPPER}/api/cloudmapper/${accountId}`);
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Function called with the function nukeIAM.
     * @param accountId : account id to nuke
     */

    const launchFreeAccount = async (accountId: string) => {
        try {
            await axios.post(`${BACKEND_API}/solo-reset`, {
                reset: false,
                account: [accountId],
            });
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Function called with the FREE ACCOUNT button, call script to nuke ressources and IAM.
     * @param accountId : account id to nuke
     */

    const freeAccount = async (team: any) => {
        await deleteCloudmapper(team.accountId);
        await launchFreeAccount(team.accountId);

        sessionStorage.removeItem("applicant");
        document.location.reload();
    };

    return (
        <Button
            disabled={disabled}
            style={style}
            disableElevation
            variant="contained"
            color="primary"
            onClick={() => freeAccount(team)}
            endIcon={<WarningIcon />}
        >
            {text}
        </Button>
    );
};
