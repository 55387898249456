import subnet from "./icons/azure/subnet.svg";
import vm from "./icons/azure/vm.svg";
import networkInterface from "./icons/azure/network-interface.svg";
import bePool from "./icons/azure/be-pool.svg";
import vmss from "./icons/azure/vmss.svg";
import loadBalancer from "./icons/azure/load-balancer.svg";
import virtualNetwork from "./icons/azure/virtual-network.svg";
import ipAdress from "./icons/azure/ip-adress.svg";
import nsg from "./icons/azure/nsg.svg";
import sg from "./icons/azure/sg.svg";
import cube from "./icons/cube.svg";

export const cytoscapeStyles = [
    {
        selector: '[type = "azure-subnets"]',
        style: {
            "background-opacity": 0,
            "background-image": subnet,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-virtualMachines"]',
        style: {
            "background-opacity": 0,
            "background-image": vm,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-networkInterfaces"]',
        style: {
            "background-opacity": 0,
            "background-image": networkInterface,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-backendAddressPools"]',
        style: {
            "background-opacity": 0,
            "background-image": bePool,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-virtualMachineScaleSets"]',
        style: {
            "background-opacity": 0,
            "background-image": vmss,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-loadBalancers"]',
        style: {
            "background-opacity": 0,
            "background-image": loadBalancer,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-virtualNetworks"]',
        style: {
            "background-opacity": 0,
            "background-image": virtualNetwork,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-frontendIPConfigurations"]',
        style: {
            "background-opacity": 0,
            "background-image": ipAdress,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-publicIPAddresses"]',
        style: {
            "background-opacity": 0,
            "background-image": ipAdress,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-networkSecurityGroups"]',
        style: {
            "background-opacity": 0,
            "background-image": nsg,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: '[type = "azure-securityRules"]',
        style: {
            "background-opacity": 0,
            "background-image": sg,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
    {
        selector: "node",
        style: {
            label: "data(name)",
            width: 75,
            height: 75,
        },
    },
    {
        selector: "edge",
        style: {
            width: 5,
            opacity: 0.8,
            "line-color": "#888",
            "curve-style": "unbundled-bezier",
            "control-point-distances": 20,
            "control-point-weights": 0.5,
            "target-arrow-shape": "triangle",
        },
    },
    {
        selector: "edge.admin",
        style: {
            width: 10,
            label: "admin",
            "line-color": "#f00",
            "target-arrow-color": "#f00",
        },
    },
    {
        selector: "edge.assume_role",
        style: {
            "line-color": "#999",
            label: "assume_role",
            "target-arrow-color": "#999",
        },
    },
    {
        selector: "edge.assume_role_read",
        style: {
            "line-color": "#999",
            "target-arrow-color": "#999",
            label: "assume_role_read",
            "line-style": "dashed",
        },
    },
    {
        selector: "edge.directconnect",
        style: {
            "line-color": "#36f",
            "line-style": "dashed",
            label: "directconnect",
            "target-arrow-color": "#36f",
            "target-arrow-shape": "none",
        },
    },
    {
        selector: ":selected",
        css: {
            "background-color": "#ff0",
            "background-opacity": 0.5,
            "background-image-opacity": 0.7,
        },
    },
    {
        selector: ".hiddenNeighbors",
        css: {
            "border-width": 5,
            "border-color": "#000",
        },
    },
    {
        selector: ".highlight",
        css: {
            width: 5,
            opacity: 1,
            "line-color": "#888",
        },
    },
    {
        selector: ".cy-expand-collapse-collapsed-node",
        css: {
            "background-opacity": 0,
            "background-image": cube,
            "background-fit": "contain",
            "background-clip": "none",
        },
    },
];
