import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import { Chip, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    informationTextDisplay: {
        height: 44,
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        "& > .MuiIconButton-root": {
            marginLeft: "auto",
        },
    },
}));

interface ChipInformationProps {
    label: string;
    value: string;
    disableCopy?: boolean;
}

export const ChipInformation: React.FC<ChipInformationProps> = ({ label, value, disableCopy }) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);

    const handleCopyValue = () => {
        if (disableCopy) {
            return;
        }
        navigator.clipboard.writeText(value);
        setOpen(true);
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => setOpen(false), 1500);
        }
    }, [open]);

    return (
        <div className={classes.informationTextDisplay}>
            <Typography variant="button">{label} : </Typography>
            <Tooltip
                arrow
                open={open}
                placement="top"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Valeur copiée avec succès"
            >
                <Chip
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={value}
                    onClick={handleCopyValue}
                />
            </Tooltip>
            {!disableCopy && (
                <IconButton onClick={handleCopyValue}>
                    <FileCopyIcon fontSize="small" />
                </IconButton>
            )}
        </div>
    );
};
