import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GamedayGeneralRules } from "./GamedayGeneralRules";
import gamedayBanner from "../../../assets/gameday-banner.png";
import { GamedayPageSideNavigation } from "../GamedayPageSideNavigation";
import { drawerWidth } from "../GamedayDashboardLayout";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const navWidth = 172;

const useStyles = makeStyles((theme) => ({
    rulesBanner: {
        // Full screen width minus drawer's width, minus padding size, minus default scrollbar width
        width: `calc(100vw - ${drawerWidth}px - 64px - 20px)`,
        // Aspect ratio of ths image is 4.56:1
        height: `calc((100vw - ${drawerWidth}px - 64px - 20px) / 4.56)`,
        backgroundColor: theme.palette.text.primary,
        marginBottom: theme.spacing(4),
        "& > img": {
            width: "100%",
            height: "auto",
        },
    },
    root: {
        display: "flex",
        "& > div[id]": {
            scrollMarginTop: 60,
        },
    },
    main: {
        margin: theme.spacing(0, 4),
        width: `calc(100% - 64px - ${navWidth}px)`,
    },
    nav: {
        width: navWidth,
        "& > div": {
            position: "sticky",
            top: 96,
            padding: theme.spacing(2),
        },
    },
    jumbotron: {
        backgroundColor: "#e8f4fd",
        padding: theme.spacing(4),
        animation: "$glow 4s ease-in-out infinite",
    },
    "@keyframes glow": {
        "0%": {
            backgroundColor: "#e8f4fd",
        },
        "50%": {
            backgroundColor: "#c9e5fa",
        },
        "100%": {
            backgroundColor: "#e8f4fd",
        },
    },
    link: {
        textDecoration: "none",
    },
}));

export const GamedayRulesPage: React.FC = () => {
    const classes = useStyles();

    useDocumentTitle("Cloud Challenge - Règles");

    const rulesNavigationLinks = [
        {
            title: {
                href: "#rules",
                label: "Les règles",
            },
            links: [
                {
                    href: "#action",
                    label: "Déroulement",
                },
                {
                    href: "#scores",
                    label: "Scores",
                },
                {
                    href: "#prices",
                    label: "Tarifs",
                },
            ],
        },
        {
            title: {
                href: "#start",
                label: "Jouer",
            },
        },
    ];

    return (
        <React.Fragment>
            <div className={classes.rulesBanner}>
                <img alt="Bienvenue au Cloud Challenge" src={gamedayBanner} />
            </div>
            <div className={classes.root}>
                <div className={classes.main}>
                    <GamedayGeneralRules />
                    <div className={classes.jumbotron}>
                        <Typography id="start" variant="h2" color="textPrimary" paragraph>
                            À MON TOUR ! 😉
                        </Typography>
                        <Link to="../scenario" className={classes.link}>
                            <Button
                                disableElevation
                                color="primary"
                                size="large"
                                variant="contained"
                            >
                                Lire le scénario et commencer à jouer
                                <ArrowForwardIcon style={{ marginLeft: "10px" }} fontSize="small" />
                            </Button>
                        </Link>
                    </div>
                </div>
                <GamedayPageSideNavigation links={rulesNavigationLinks} />
            </div>
        </React.Fragment>
    );
};
