/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import axios, { AxiosResponse } from "axios";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { CLOUDPDF, CLOUDMAPPER, BACKEND_API } from "../../constant";
import { StaffingHeaderBar } from "../../common/StaffingHeaderBar";
import CustomAlert from "../../common/CustomAlert";
import ModalStaff from "../../common/ModalStaff";
import { Cloudmapper } from "../../common/Cloudmapper";
import { useNavigate, useParams } from "react-router-dom";
import { useStaffingValues } from "../../hooks/useStaffingValues";
import { AdminStaffContent } from "./AdminStaffContent";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: theme.spacing(3),
    },
    end: {
        display: "flex",
        justifyContent: "right",
    },
    cancel: {
        marginRight: theme.spacing(3),
    },
    typoEnd: {
        display: "flex",
        justifyContent: "right",
        marginTop: theme.spacing(2),
    },
    cloudLarge: {
        position: "absolute",
        zIndex: 1000,
        top: 388,
        left: 50,
        borderRadius: theme.spacing(2),
        width: "95%",
        height: 1300,
    },
}));

/**
 * Page for managing staffing session.
 *
 * @return {JSX} /staffing/:name - session page when an applicant is setted
 */
export const AdminStaff: React.FC = () => {
    const classes = useStyles();

    const [open, setOpen] = useState<boolean>(false);
    const [cloudmapperLarge, setCloudmapperLarge] = useState<boolean>(false);
    const [disablePdfButton, setDisablePDFButton] = useState<boolean>(true);
    const [disableExitButton, setDisableExitButton] = useState<boolean>(true);
    const [openModalEndSession, setOpenModalEndSession] = useState<boolean>(false);
    const [pdfPath, setPDFPath] = useState<string>("");

    const navigate = useNavigate();
    const applicantName = useParams().applicantName as string;

    const { areStaffingValuesLoaded, applicantTeam, selectedScenarios } =
        useStaffingValues(applicantName);

    const downloadPDF = () => {
        window.open(`${CLOUDPDF}/static/${pdfPath}`);
    };

    const generateAccountPDF = () => {
        const data = {
            accountId: applicantTeam.accountId,
            lastname: applicantTeam.name,
            firstname: applicantTeam.name,
            name: applicantTeam.name,
            email: applicantTeam.email,
            endSession: applicantTeam.endSession,
            createdAt: applicantTeam.createdAt,
            useCase: applicantTeam.useCase,
            bullets: applicantTeam.bullets,
        };
        return axios.post(`${CLOUDPDF}/generate`, data);
    };

    const deleteCloudmapperAccount = (): Promise<AxiosResponse<any>> => {
        return axios.delete(`${CLOUDMAPPER}/api/cloudmapper/${applicantTeam.accountId}`);
    };

    const nukeAccount = (): Promise<AxiosResponse<any>> => {
        return axios.post(`${BACKEND_API}/solo-reset`, {
            reset: false,
            account: [applicantTeam.accountId],
        });
    };

    const logoutFromSession = () => {
        navigate("/admin/staffing");
    };

    const releaseAccountFromSession = () => {
        generateAccountPDF()
            .then((res) => {
                setPDFPath(res.data.path);
                setDisablePDFButton(false);
                deleteCloudmapperAccount();
            })
            .then(() => {
                nukeAccount();
            })
            .then(() => setDisableExitButton(false))
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCloseSession = () => {
        releaseAccountFromSession();
        setOpen(false);
        setOpenModalEndSession(true);
    };

    if (!areStaffingValuesLoaded) {
        return <StaffingHeaderBar name={applicantName} />;
    }
    return (
        <main className={classes.main}>
            <AdminStaffContent
                applicantTeam={applicantTeam}
                selectedScenarios={selectedScenarios}
                cloudmapperLarge={cloudmapperLarge}
                setCloudmapperLarge={setCloudmapperLarge}
                setOpen={setOpen}
            />

            <ModalStaff
                open={open}
                setOpen={setOpen}
                title="TERMINER LA SESSION"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert
                        title="Attention, vous êtes sur le point de terminer la session."
                        severity="error"
                        message={""}
                    >
                        Terminer la session signifie que le candidat a fini sa session de test. Il
                        ne pourra donc pas y revenir.
                        <br />
                        Êtes-vous sûr de vouloir faire cela ?
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.cancel}
                            disableElevation
                            variant="outlined"
                            color="primary"
                            onClick={() => setOpen(false)}
                            startIcon={<CloseIcon />}
                        >
                            Annuler
                        </Button>
                        <Button
                            className={classes.cancel}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={handleCloseSession}
                            startIcon={<CloseIcon />}
                        >
                            Terminer la session
                        </Button>
                    </div>
                    <Typography
                        className={classes.typoEnd}
                        color="textSecondary"
                        variant="subtitle1"
                    >
                        Terminer la session générera un rapport
                    </Typography>
                </React.Fragment>
            </ModalStaff>

            <ModalStaff
                open={openModalEndSession}
                setOpen={setOpenModalEndSession}
                closeAction={logoutFromSession}
                disableExitButton={disableExitButton}
                title="SESSION TERMINÉE"
                style={{ margin: "300px" }}
            >
                <React.Fragment>
                    <CustomAlert title="La session est terminée" severity="success" message={""}>
                        Merci d'avoir utiliser le cloud-challenge ! Le rapport peut prendre quelques
                        minutes à se générer.
                    </CustomAlert>
                    <div className={classes.end}>
                        <Button
                            className={classes.cancel}
                            disabled={disablePdfButton}
                            disableElevation
                            variant="contained"
                            color="secondary"
                            onClick={downloadPDF}
                        >
                            Voir le rapport
                        </Button>
                        <Button
                            className={classes.cancel}
                            disabled={disableExitButton}
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={logoutFromSession}
                            startIcon={<CloseIcon />}
                        >
                            Fermer
                        </Button>
                    </div>
                </React.Fragment>
            </ModalStaff>

            {cloudmapperLarge && (
                <Cloudmapper
                    role="admin"
                    className={classes.cloudLarge}
                    accountId={applicantTeam.accountId}
                    cloudmapperLarge={cloudmapperLarge}
                    setCloudmapperLarge={setCloudmapperLarge}
                />
            )}
        </main>
    );
};
