import React from "react";
import { InputBase, InputBaseProps } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";

const CustomInput = withStyles((theme) =>
    createStyles({
        input: {
            padding: theme.spacing(1, 1.5),
            position: "relative",
            fontSize: 16,
            width: "auto",
            transition: theme.transitions.create(["border-color", "box-shadow"]),
            "&:focus": {
                boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
                borderColor: theme.palette.primary.main,
            },
        },
    })
)(InputBase);

interface InputProps {
    name: string;
    value: any;
    onChange: any;
}

const Input: React.FC<InputBaseProps & InputProps> = ({ name, value, onChange, ...props }) => {
    return (
        <CustomInput
            name={name}
            value={value}
            placeholder={props.type === "password" ? "••••••" : "Aa"}
            onChange={onChange}
            {...props}
        />
    );
};
export default Input;
