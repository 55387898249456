import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// @ts-ignore
import socketIOClient from "socket.io-client";
import logo from "../assets/logo.svg";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import HomeIcon from "@material-ui/icons/Home";
import { Button, Snackbar, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import WarningIcon from "@material-ui/icons/Warning";

// Common
import { Accounts } from "./gameday/Accounts";
import { BACKEND_API, CLOUDMAPPER } from "../constant";
import { useGameConfigContext } from "../context/GameConfigContext";
import { useGameConfigHook } from "../hooks/useGameConfig";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbarIcon: {
        paddingTop: "20px",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 500,
    },
    link: {
        color: "inherit",
    },
}));

/**
 * Call contents of the danger zone and returns the page /dangerzone
 * @return {JSX} /dangerzone page.
 */

export const DashboardDanger: React.FC = () => {
    const classes = useStyles();

    const [openLogger, setOpenLogger] = React.useState<boolean>(false);
    const { gameConfig, isGameConfigLoading } = useGameConfigContext();
    const { fetchGameConfig } = useGameConfigHook();
    // ----- Functions to reset ----- //

    const reset = async () => {
        if (!window.confirm("Are you sure ?")) {
            return;
        }
        await deleteAllCloudmapper();
        await launchReset();
    };

    // Used by reset function
    const deleteAllCloudmapper = async () => {
        let response;
        let accounts = [];
        try {
            response = await axios.get(`${CLOUDMAPPER}/api/cloudmapper/`);
            accounts = response.data;
            if (accounts.length !== 0) {
                for (const key in accounts) {
                    if (Object.hasOwnProperty.call(accounts, key)) {
                        await axios.delete(`${CLOUDMAPPER}/api/cloudmapper/${accounts[key].id}`);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const launchReset = async () => {
        try {
            const response = await axios.post(`${BACKEND_API}/reset`);
            if (response.status === 200) {
                setOpenLogger(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={classes.appBar}>
                <Toolbar>
                    <IconButton className={classes.toolbarIcon} color="inherit">
                        <Link className={classes.link} to="/admin">
                            <HomeIcon />
                        </Link>
                    </IconButton>
                    <Typography variant="h2" style={{ fontSize: "40px" }}>
                        Danger Zone
                    </Typography>
                    <img className="logo" src={logo} alt="Logo" />
                </Toolbar>
            </AppBar>

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                {!isGameConfigLoading && (
                    <Container maxWidth="lg" className={classes.container}>
                        <Accounts gameConfig={gameConfig} gameDay={false} />
                        <Button
                            disableElevation
                            variant="contained"
                            style={{ backgroundColor: "red", color: "white", margin: "10px" }}
                            onClick={reset}
                            color="secondary"
                            endIcon={<WarningIcon />}
                        >
                            Reset all accounts
                        </Button>
                        {openLogger && (
                            <Logger
                                openLogger={openLogger}
                                setOpenLogger={setOpenLogger}
                                updateGameConfigInfo={fetchGameConfig}
                            />
                        )}
                    </Container>
                )}
            </main>
        </div>
    );
};

interface LoggerProps {
    openLogger: boolean;
    setOpenLogger: any;
    updateGameConfigInfo: () => Promise<void>;
}

interface LoggerState {
    logs: string;
    open: boolean;
}

class Logger extends React.Component<LoggerProps, LoggerState> {
    constructor(props: LoggerProps) {
        super(props);
        this.state = {
            logs: "",
            open: true,
        };
    }

    componentDidMount() {
        const socket = socketIOClient(`${BACKEND_API}/admin`);
        socket.on("socketAdmin", (data: string) => {
            this.setState({ logs: `${this.state.logs}\n\n${data}` });
        });

        socket.on("disconnect", () => {
            this.setState({ open: false }, () => {
                this.props.updateGameConfigInfo();
                this.props.setOpenLogger(false);
            });
        });
    }

    shouldComponentUpdate(nextProps: LoggerProps, nextState: LoggerState) {
        if (
            this.state.logs !== nextState.logs ||
            nextState.open === false ||
            this.state.open === false
        ) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        return (
            <Snackbar open={true}>
                <MuiAlert severity="success" elevation={6} variant="filled">
                    <div
                        style={{
                            padding: "15px",
                            width: "700px",
                            height: "300px",
                            overflow: "scroll",
                            backgroundColor: "black",
                            textAlign: "left",
                        }}
                    >
                        <code>{this.state.logs}</code>
                    </div>
                </MuiAlert>
            </Snackbar>
        );
    }
}
