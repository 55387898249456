import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardGameday as AdminDashboardGameday } from "./admin/gameday/DashboardGameday";
import { DashboardStaffing } from "./common/DashboardStaffing";
import { DashboardDanger as AdminDashboardDanger } from "./admin/DashboardDanger";
import { ScenarioDataProvider } from "./context/ScenarioDataContext";
import WelcomePage from "./common/WelcomePage";
import { GameConfigProvider } from "./context/GameConfigContext";
import { ScoreContextProvider } from "./context/ScoreContext";

const AdminRoutes: React.FC = () => {
    return (
        <ScenarioDataProvider>
            <Routes>
                <Route index element={<WelcomePage role={"admin"} />} />
                <Route
                    path="gameday/*"
                    element={
                        <ScoreContextProvider>
                            <GameConfigProvider>
                                <AdminDashboardGameday />
                            </GameConfigProvider>
                        </ScoreContextProvider>
                    }
                />
                <Route path="staffing/*" element={<DashboardStaffing role={"admin"} />} />
                <Route
                    path="dangerzone"
                    element={
                        <GameConfigProvider>
                            <AdminDashboardDanger />
                        </GameConfigProvider>
                    }
                />
                <Route path="*" element={<Navigate to="/admin" replace={true} />} />
            </Routes>
        </ScenarioDataProvider>
    );
};

export default AdminRoutes;
