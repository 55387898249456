import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    stepper: {
        textAlign: "left",
        backgroundColor: "#FFFFFF",
        position: "sticky",
        top: theme.spacing(2),
    },
}));

type StaffSetupFormStepperProps = {
    activeStep: number;
};

export const StaffSetupFormStepper: React.FC<StaffSetupFormStepperProps> = ({ activeStep }) => {
    const classes = useStyles();
    const steps: string[] = ["Le candidat", "Le service", "Les scénarios"];

    return (
        <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
            {steps.map((label, index) => (
                <Step key={`${label}-${index}`}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
