import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
    FormControl,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    CardActions,
} from "@material-ui/core";
import { ScenarioFileViewer } from "../components/ScenarioFileViewer";

const useStylesScenarioCard = makeStyles({
    content: {
        height: "150px",
        overflowY: "hidden",
        textAlign: "left",
    },
});

export interface GameSetupScenarioCardProps {
    level: string;
    title: string;
    scenario: any;
    scenarios: any;
    handleLaunch: any;
    stepStart: number;
    setStartStep: any;
    stepEnd: number;
    setEndStep: any;
}

export const GameSetupScenarioCard: React.FC<GameSetupScenarioCardProps> = ({
    level,
    title,
    scenario,
    scenarios,
    handleLaunch,
    stepStart,
    setStartStep,
    stepEnd,
    setEndStep,
}) => {
    const classes = useStylesScenarioCard();

    const handleClick = (e: { stopPropagation: () => void }): void => {
        e.stopPropagation();
        if (window.confirm("Etes-vous sûr de vouloir lancer le jeu avec cette configuration ?")) {
            handleLaunch(level, title, scenarios, stepStart, stepEnd);
        }
    };

    const handleStartStep = (event: { target: any; stopPropagation?: any }): void => {
        event.stopPropagation && event.stopPropagation();
        if (event.target.checked) setStartStep(scenario.gitUrl);
        else {
            setStartStep("");
            setEndStep("");
        }
    };

    const handleEndStep = (event: { target: any; stopPropagation?: any }): void => {
        event.stopPropagation && event.stopPropagation();
        if (event.target.checked) setEndStep(scenario.gitUrl);
        else setEndStep("");
    };

    const handleCardClick = (): void => {
        if (!stepStart) {
            handleStartStep({ target: { checked: true } });
        } else if (stepStart && !stepEnd) {
            handleEndStep({ target: { checked: true } });
        } else if (stepStart && stepEnd) {
            handleEndStep({ target: { checked: false } });
        }
    };

    const PrintStepStart: React.FC = () => {
        if (stepStart && stepStart !== scenario.gitUrl) {
            return null;
        }
        const checked = stepStart === scenario.gitUrl;

        return (
            <FormControlLabel
                control={<Checkbox color="primary" checked={checked} onClick={handleStartStep} />}
                label={`Commencer ici`}
            />
        );
    };

    const PrintStepEnd: React.FC = () => {
        if (!stepStart) {
            return null;
        }
        if (stepEnd && stepEnd !== scenario.gitUrl) {
            return null;
        }
        if (scenario.gitUrl < stepStart) {
            return null;
        }
        const checked = stepEnd === scenario.gitUrl;
        return (
            <FormControlLabel
                control={<Checkbox color="primary" checked={checked} onClick={handleEndStep} />}
                label={`Terminer ici`}
            />
        );
    };

    let style: React.CSSProperties = {};
    if (stepStart && scenario.gitUrl < stepStart) {
        style = { backgroundColor: "#e4e3e6" };
    }
    if (stepEnd && scenario.gitUrl > stepEnd) {
        style = { backgroundColor: "#e4e3e6" };
    }

    return (
        <Card style={style} onClick={handleCardClick}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="archi schema"
                    height="140"
                    image={scenario.image}
                    title="archi schema"
                />
                <CardContent className={classes.content}>
                    <Typography gutterBottom variant="h5">
                        {scenario.title.toUpperCase()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {scenario.description}
                    </Typography>
                </CardContent>
                <div style={{ textAlign: "left", marginLeft: "13px", height: "84px" }}>
                    <FormControl component="fieldset">
                        <FormGroup>
                            <PrintStepStart />
                            <PrintStepEnd />
                        </FormGroup>
                    </FormControl>
                </div>
            </CardActionArea>

            <CardActions>
                <ScenarioFileViewer scenario={scenario} rules={false} title={"Readme"} />
                <ScenarioFileViewer scenario={scenario} rules={true} title={"Règles"} />
                {stepStart && stepEnd && stepEnd === scenario.gitUrl && (
                    <Button
                        onClick={handleClick}
                        size="small"
                        variant="contained"
                        disableElevation={true}
                        color="secondary"
                    >
                        Lancer le jeu
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};
