import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AccordionScenario } from "./AccordionScenario";
import { Team } from "../../../interfaces/Team";
import { Scenario } from "../../../interfaces/Scenario";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
}));

interface ListAccordionScenariosProps {
    selectedScenarios: Scenario[];
    teamData: Team;
}

export const ListAccordionScenarios: React.FC<ListAccordionScenariosProps> = ({
    selectedScenarios,
    teamData,
}) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {selectedScenarios.map((scenario: Scenario, i: number) => (
                <div key={`scenario-${i}`} className={classes.root}>
                    <AccordionScenario
                        defaultExpanded={i === 0}
                        key={scenario.id}
                        scenarioId={`${scenario.id}`}
                        rules={true}
                        title={scenario.title}
                        step={i + 1}
                        description={scenario.description}
                        teamData={teamData}
                    />
                </div>
            ))}
        </React.Fragment>
    );
};
