import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import homepageIllustration from "../assets/homepage_illustration.svg";
import logo_blue from "../assets/logo_blue.svg";

const useStyles = makeStyles((theme) => ({
    homePage: {
        display: "grid",
        gridTemplateColumns: "7fr 5fr",
        gap: theme.spacing(2),
        height: "100vh",
        textAlign: "left",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    leftPanel: {
        paddingLeft: theme.spacing(16),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(6),
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3.5),
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
            height: "100vh",
        },
    },
    rightPanel: {
        backgroundColor: "#FAFBFF",
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(16),
        paddingBottom: theme.spacing(4),
        display: "flex",
        flexDirection: "column",
        gap: "3rem",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    title: {
        margin: 0,
        fontSize: "6vw",
        lineHeight: 0.9,
        textTransform: "uppercase",
        fontFamily: "Saira Extra Condensed",
        fontWeight: 500,
        color: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
            fontSize: "4rem",
        },
    },
    boldTitle: {
        display: "block",
        fontWeight: 900,
    },
    divider: {
        height: 8,
        width: 192,
        backgroundColor: theme.palette.secondary.main,
    },
    buttonContainer: {
        display: "flex",
        gap: theme.spacing(3),
    },
    link: {
        textDecoration: "none",
    },
    button: {
        width: 192,
    },
    image: {
        width: "100%",
        height: "auto",
        maxWidth: 380,
    },
    logo: {
        width: 180,
    },
}));

const HomePage: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.homePage}>
            <div className={classes.leftPanel}>
                <h1 className={classes.title}>
                    Bienvenue sur le <span className={classes.boldTitle}>Cloud Challenge</span>
                </h1>
                <div className={classes.divider} />
                <Typography gutterBottom>
                    Ce jeu a pour but de tester vos connaissances sur le Cloud via la console web.
                    Notre serveur exécute régulièrement des requêtes sur votre application web, et
                    chaque requête correctement exécutée vous rapporte de l’argent. Entrez dans
                    l'action !
                </Typography>
                <div className={classes.buttonContainer}>
                    <Link to="/player" className={classes.link}>
                        <Button
                            data-testid="player-button"
                            color="primary"
                            disableElevation
                            size="large"
                            variant="contained"
                            className={classes.button}
                        >
                            Je suis joueur
                        </Button>
                    </Link>
                    <Link to="/admin" className={classes.link}>
                        <Button
                            data-testid="admin-button"
                            color="primary"
                            disableElevation
                            size="large"
                            variant="outlined"
                            className={classes.button}
                        >
                            Je suis admin
                        </Button>
                    </Link>
                </div>
            </div>
            <div className={classes.rightPanel}>
                <img
                    src={homepageIllustration}
                    className={classes.image}
                    draggable={false}
                    alt="Bienvenue au Cloud Challenge"
                />
                <img src={logo_blue} className={classes.logo} draggable={false} alt="IPPON" />
            </div>
        </div>
    );
};
export default HomePage;
