import React, { ReactElement } from "react";
// Material UI
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Avatar } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import ReplayIcon from "@material-ui/icons/Replay";
import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { BACKEND_API } from "../../constant";
import { Loader } from "../../context/LoaderContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
        backgroundColor: "#FFFFFF",
    },
    title: {
        textAlign: "center",
    },
    body: {
        textAlign: "left",
        paddingTop: theme.spacing(5),
    },
    divider: {
        marginTop: theme.spacing(3),
    },
    name: {
        display: "flex",
        alignItems: "center",
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        textTransform: "uppercase",
    },
    tableRow: {
        "&:hover": {
            backgroundColor: "#F0F4FB !important",
            cursor: "pointer !important",
        },
    },
    cell: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

interface ExistingStaffProps {
    createNewSession: (_event: any, newValue: number) => void;
}

export const ExistingStaff: React.FC<ExistingStaffProps> = ({ createNewSession }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [staffingAccounts, staffingAccountsIsLoading, staffingAccountsHasError] = useFetch(
        `${BACKEND_API}/teams?usecase=Staffing`
    );

    const getLevelLabel = (level: string) => {
        switch (level) {
            case "easy":
                return "Junior";
            case "medium":
                return "Confirmé";
            case "hard":
                return "Senior";
            default:
                return "Junior";
        }
    };

    const getStatusChip = (info: any) => {
        switch (info) {
            case "Ready":
                return <Chip label="En cours" style={{ backgroundColor: "#00CCA5" }} />;
            case "Disabled":
                return <Chip label="Désactivé" />;
            default:
                return <Chip label="En création" />;
        }
    };

    const handleNavigation = (name: string) => () => {
        navigate(`/admin/staffing/${name}`);
    };

    if (staffingAccountsHasError) {
        return (
            <div style={{ marginTop: 24 }}>
                <ExistingStaffAlert
                    severity="error"
                    title={"Une erreur est survenue"}
                    body={"Quelque chose s'est mal passé, veuillez réessayer."}
                />
            </div>
        );
    }
    if (staffingAccountsIsLoading) {
        return <Loader />;
    }
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography color="primary" variant="h2">
                    <b>TROUVEZ UNE SESSION </b>DE RECRUTEMENT
                </Typography>
                <Divider variant="middle" className={classes.divider} />
            </div>
            <div className={classes.body}>
                {staffingAccounts?.length ? (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <b>Participant</b>
                                </TableCell>
                                <TableCell>
                                    <b>Niveau</b>
                                </TableCell>
                                <TableCell>
                                    <b>Date</b>
                                </TableCell>
                                <TableCell>
                                    <b>Statut</b>
                                </TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {staffingAccounts.map((row: any) => (
                                <TableRow
                                    key={row.name}
                                    hover
                                    className={classes.tableRow}
                                    onClick={handleNavigation(row.name)}
                                >
                                    <TableCell className={classes.cell}>
                                        <div className={classes.name}>
                                            <Avatar className={classes.avatar}>
                                                <Typography>{row.name[0]}</Typography>
                                            </Avatar>
                                            <p>{row.name}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {getLevelLabel(row.levelCandidate)}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {new Date(row.createdAt).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        {getStatusChip(row.info)}
                                    </TableCell>
                                    <TableCell className={classes.cell}>
                                        <Button
                                            onClick={handleNavigation(row.name)}
                                            startIcon={<ReplayIcon />}
                                        >
                                            Reprendre la session
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <ExistingStaffAlert
                        severity={"warning"}
                        title={"Aucune session n'est actuellement en cours !"}
                        body={
                            "Pas de panique, vous pouvez créer une nouvelle session de Staffing dès maintenant."
                        }
                        actionButton={
                            <Button
                                color="secondary"
                                disableElevation
                                variant="contained"
                                onClick={() => createNewSession(undefined, 0)}
                            >
                                Créer une session
                            </Button>
                        }
                    />
                )}
            </div>
        </div>
    );
};

interface ExistingStaffAlertProp {
    title: string;
    body: string;
    severity: AlertProps["severity"];
    actionButton?: ReactElement;
}

const ExistingStaffAlert: React.FC<ExistingStaffAlertProp> = ({
    title,
    body,
    severity,
    actionButton,
}) => {
    return (
        <Alert severity={severity} action={actionButton}>
            <AlertTitle>{title}</AlertTitle>
            <Typography variant="body2">{body}</Typography>
        </Alert>
    );
};
