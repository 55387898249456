import React from "react";
import {
    Button,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@material-ui/core";
import axios from "axios";
import { BACKEND_API, REGION } from "../../constant";
import Input from "../../common/Input";
import Typography from "@material-ui/core/Typography";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../common/useForms";
import Select from "@material-ui/core/Select";
import { useCustomAlertContext } from "../../context/CustomAlertContext";

export const Chaos: React.FC<{ scores: any }> = ({ scores }) => {
    const { showCustomAlert } = useCustomAlertContext();

    const [az, setAz] = React.useState({
        [`${REGION}a`]: false,
        [`${REGION}b`]: false,
        [`${REGION}c`]: false,
    });

    const handleChangeAz = (event: any) => {
        setAz({ ...az, [event.target.name]: event.target.checked });
    };

    const modelObjectForm = {
        penalty: 0,
        accountId: "",
    };

    const { values, handleInputChange, resetForm } = useForm(modelObjectForm);

    const handleSubmit: any = (e: Event) => {
        e.preventDefault();
        axios
            .post(`${BACKEND_API}/scores/penalty`, values)
            .then((_response) => {
                showCustomAlert("success", "Succès !", "La penalité a été correctement appliquée.");
            })
            .catch((_error) => {
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "La penalité n'a pas pu être appliquée."
                );
            })
            .finally(() => {
                resetForm();
            });
    };

    const startChaos = (route: string, data = {}) => {
        showCustomAlert("info", "Veuillez patienter...", "");
        axios
            .post(`${BACKEND_API}/${route}`, data)
            .then((response) => {
                if (response.data.status === 200) {
                    showCustomAlert("success", "Une erreur est survenue", response.data.response);
                } else {
                    showCustomAlert(
                        "error",
                        "Une erreur est survenue",
                        "Quelque chose s'est mal passé, veuillez réessayer."
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "Quelque chose s'est mal passé, veuillez réessayer."
                );
            });
    };

    const startChaosEc2 = () => startChaos("chaos/killRandomEc2");
    const startChaosAz = () =>
        startChaos("chaos/killAllEc2InAZ", { az: Object.keys(az).filter((k) => az[k] === true) });

    return (
        <div>
            <div style={{ padding: "50px", boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)" }}>
                <Typography color="textPrimary" variant="h2">
                    CHAOS
                </Typography>
                <Divider variant="middle" style={{ marginTop: "20px", marginBottom: "20px" }} />

                <div style={{ textAlign: "left" }}>
                    <div style={{ marginTop: "20px" }}>
                        <Typography color="textSecondary" variant="subtitle1">
                            Permet de terminer une instance EC2 alétoire pour toutes les équipes{" "}
                        </Typography>
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={startChaosEc2}
                            style={{ marginTop: "5px" }}
                        >
                            Terminer une Instance EC2 pour toutes les teams
                        </Button>
                    </div>

                    <div style={{ marginTop: "20px" }}>
                        <Typography color="textSecondary" variant="subtitle1">
                            Permet de terminer toutes les instance sur une ou plusieurs AZ{" "}
                        </Typography>
                        <FormControl component="fieldset">
                            <FormGroup>
                                {Object.entries(az).map(([k, v]) => (
                                    <FormControlLabel
                                        key={k}
                                        control={
                                            <Checkbox
                                                checked={v}
                                                onChange={handleChangeAz}
                                                name={k}
                                            />
                                        }
                                        label={k}
                                    />
                                ))}
                            </FormGroup>
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={startChaosAz}
                            >
                                Terminer les instances sur ces AZ
                            </Button>
                        </FormControl>
                    </div>
                </div>
            </div>
            <div
                style={{
                    padding: "50px",
                    marginTop: "10px",
                    boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
                }}
            >
                <Typography color="textPrimary" variant="h2">
                    PÉNALITÉS
                </Typography>
                <Divider variant="middle" style={{ marginTop: "20px", marginBottom: "20px" }} />
                <div style={{ textAlign: "left" }}>
                    {typeof scores !== "undefined" ? (
                        <Form onSubmit={handleSubmit}>
                            <Typography color="textSecondary" variant="subtitle1">
                                Permet de choisir une équipe et de lui appliquer une pénalité de
                                points en cas de triche
                            </Typography>
                            <Input
                                name="penalty"
                                value={values.penalty}
                                onChange={handleInputChange}
                                style={{ marginTop: "10px", marginRight: "20px", maxWidth: "60px" }}
                            />
                            <Select
                                disableUnderline
                                native
                                value={values.accountId}
                                onChange={handleInputChange}
                                name="accountId"
                                style={{ marginRight: "20px" }}
                            >
                                <option aria-label="none" value="" />
                                {Object.values(scores).map((team: any) => {
                                    return (
                                        <option key={team.accountId} value={team.accountId}>
                                            {team.name}
                                        </option>
                                    );
                                })}
                            </Select>
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Appliquer
                            </Button>
                        </Form>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
