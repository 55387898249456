import { Chip, Divider, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useScoreContext } from "../../../context/ScoreContext";
import { ChartTeamChip } from "../ChartTeamChip";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        position: "relative",
        padding: theme.spacing(1.5, 2),
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(6px)",
        boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
        "& > .MuiChip-root": {
            backgroundColor: "#809eee21",
            color: theme.palette.text.primary,
        },
        "& > * > .MuiSvgIcon-root": {
            color: theme.palette.text.primary,
        },
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    teamChip: {
        display: "flex",
        gap: theme.spacing(2),
        marginBottom: 2,
    },
    overlay: {
        position: "absolute",
        bottom: 0,
        top: "calc(100% - 80px)",
        left: 0,
        right: 0,
        background: "linear-gradient(0deg, rgba(255,255,255,1) 12%, rgba(255,255,255,0) 100%)",
    },
}));

interface ScoreCustomTooltipProps {
    active: boolean;
    payload: any;
    label: string;
}

export const ScoreCustomTooltip: React.FC<ScoreCustomTooltipProps> = ({
    active,
    payload,
    label,
}) => {
    const classes = useStyles();
    const { gamedayScores } = useScoreContext();

    const maxTooltipLength: number = 14;

    if (active && payload && payload.length) {
        return (
            <Paper className={classes.customTooltip}>
                <Chip
                    label={new Date(label).toLocaleTimeString().slice(0, -3)}
                    size="small"
                    icon={<WatchLaterIcon />}
                />
                <Divider className={classes.divider} />
                {payload
                    .slice(0, maxTooltipLength + 1)
                    .sort((a: any, b: any) => b.value - a.value)
                    .map((elem: any) => (
                        <div className={classes.teamChip} key={gamedayScores[elem.dataKey].name}>
                            <ChartTeamChip
                                name={gamedayScores[elem.dataKey].name}
                                color={gamedayScores[elem.dataKey].color}
                            />
                            <strong style={{ marginLeft: "auto" }}>${elem.value}</strong>
                        </div>
                    ))}
                {payload.length > maxTooltipLength && (
                    <React.Fragment>
                        <div className={classes.overlay} />
                    </React.Fragment>
                )}
            </Paper>
        );
    }
    return null;
};
