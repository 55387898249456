import React from "react";
import clsx from "clsx";
import { ChartTeamChip } from "./ChartTeamChip";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    checked: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        padding: theme.spacing(1, 1, 1, 2),
        fontSize: 14,
        cursor: "pointer",
        borderRadius: 6,
        backgroundColor: "#f4f6f8",
        border: "1px solid transparent",
        transition: theme.transitions.create(["all"]),
        marginBottom: theme.spacing(1),
        "& > .MuiSvgIcon-root": {
            marginLeft: "auto",
            fontSize: 16,
            transition: theme.transitions.create(["all"]),
            color: theme.palette.text.secondary,
        },
    },
    unchecked: {
        opacity: 0.35,
        backgroundColor: "transparent",
        "& > .MuiSvgIcon-root": {
            opacity: 0,
        },
        "&:hover": {
            opacity: 1,
            backgroundColor: "#fff",
        },
    },
}));

interface GamedayLegendChipProps {
    handleClick: any;
    name: string;
    color: string;
    isChecked: boolean;
}

export const GamedayLegendChip = React.forwardRef(
    ({ name, color, handleClick, isChecked }: GamedayLegendChipProps, _ref) => {
        const classes = useStyles();

        return (
            <MenuItem
                onClick={handleClick}
                className={clsx(classes.checked, !isChecked && classes.unchecked)}
            >
                <ChartTeamChip name={name} color={color} />
                <CloseIcon />
            </MenuItem>
        );
    }
);
