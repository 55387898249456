import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    icon: {
        width: 16,
        height: 16,
        border: "1px solid #003CDC",
        backgroundColor: "#FFFFFF",
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#003CDC",
        backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        transition: theme.transitions.create(["all"]),
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            transform: "translate(-1px, -1px)",
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#003CDC",
        },
    },
}));

interface CustomCheckboxProps {
    checked: boolean;
    name: string;
    onChange?: CheckboxProps["onChange"];
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = (props) => {
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "decorative checkbox" }}
            {...props}
        />
    );
};
export default CustomCheckbox;
