import React, { useState } from "react";
import axios from "axios";

import { BACKEND_API, CLOUDMAPPER } from "../../constant";
import { ScenarioCard } from "./components/Scenario";

import { Button, Grid, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useCustomAlertContext } from "../../context/CustomAlertContext";

export const ResetGame: React.FC<{ gameConfig: any }> = ({ gameConfig }) => {
    const { showCustomAlert } = useCustomAlertContext();

    const [isPending, setIsPending] = useState<boolean>(false);
    const [showUser, setShowUser] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickShowUser = () => {
        setShowUser(!showUser);
    };

    const stopGame = (): Promise<void> =>
        new Promise((resolve, reject) => {
            if (!window.confirm("Etes-vous sûr de vouloir arrêter jeu ?")) {
                return;
            }
            setIsPending(true);
            axios
                .post(`${BACKEND_API}/stopGame`, {
                    account: gameConfig.availableAccounts,
                })
                .then((_) => {
                    resolve();
                    showCustomAlert("success", "Succès", "La partie a été stoppée !");
                })
                .catch((err) => {
                    showCustomAlert(
                        "error",
                        "Une erreur est survenue",
                        "Quelque chose s'est mal passé, veuillez réessayer.",
                    );
                    reject(err);
                })
                .finally(() => setIsPending(false));
        });

    const deleteAllCloudmapper = () =>
        new Promise((resolve, reject) => {
            axios
                .get(`${CLOUDMAPPER}/api/cloudmapper/`)
                .then((response) => {
                    if (response.data.length !== 0) {
                        response.data.forEach((team: { id: any }) => {
                            axios
                                .delete(`${CLOUDMAPPER}/api/cloudmapper/${team.id}`)
                                .then((_) => resolve(true));
                        });
                    } else resolve(true);
                })
                .catch((err) => {
                    showCustomAlert(
                        "error",
                        "Une erreur est survenue - dans la suppression du cloud mapper",
                        "Quelque chose s'est mal passé, veuillez réessayer.",
                    );
                    reject(err);
                });
        });

    const launchReset = () =>
        new Promise((resolve, reject) => {
            axios
                .post(`${BACKEND_API}/solo-reset`, {
                    reset: true,
                    account: gameConfig.availableAccounts,
                })
                .then((_) => resolve(true))
                .catch((err) => {
                    showCustomAlert(
                        "error",
                        "Une erreur est survenue dans la suppression dans le backend",
                        "Quelque chose s'est mal passé, veuillez réessayer.",
                    );
                    reject(err);
                });
        });

    const reset = async () => {
        if (!window.confirm("Etes-vous sûr de vouloir réinitialiser tout le jeu ?")) {
            return;
        }
        setIsPending(true);
        deleteAllCloudmapper()
            .then((_) => launchReset())
            .then((_) =>
                showCustomAlert(
                    "info",
                    "En cours...",
                    "La partie et les comptes sont en cours de nettoyage.",
                ),
            )
            .catch((err) => {
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "Quelque chose s'est mal passé, veuillez réessayer.",
                );
                console.error(err);
            });
    };

    return (
        <div
            style={{
                padding: "30px",
                paddingLeft: "70px",
                boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
            }}
        >
            <Typography
                color="textPrimary"
                variant="h2"
                style={{ marginTop: "20px", marginBottom: "20px" }}
            >
                INFOS PARTIE
            </Typography>
            <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "30px" }} />

            <div style={{ textAlign: "left", marginTop: "50px" }}>
                <Typography color="textPrimary" variant="h4" style={{ marginBottom: "10px" }}>
                    Date de lancement : <b>{new Date(gameConfig.startDate).toLocaleString()}</b>
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography color="textPrimary" variant="h4" style={{ marginRight: "10px" }}>
                        Utilisateur :
                        <b>{showUser ? gameConfig.gamedayCognitoUser.username : "*****"}</b>
                    </Typography>
                    <IconButton aria-label="toggle user visibility" onClick={handleClickShowUser}>
                        {showUser ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                    <Typography color="textPrimary" variant="h4" style={{ marginRight: "10px" }}>
                        Mot de passe :
                        <b>{showPassword ? gameConfig.gamedayCognitoUser.password : "*****"}</b>
                    </Typography>
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div>
                <Typography color="textPrimary" variant="h4" style={{ marginBottom: "10px" }}>
                    Comptes AWS :
                </Typography>
                <ul style={{ paddingLeft: "50px", marginBottom: "10px" }}>
                    <Typography color="textPrimary" variant="h4">
                        {gameConfig.availableAccounts.map((account: string) => (
                            <li key={account}>
                                <b>{account}</b>
                            </li>
                        ))}
                    </Typography>
                </ul>
                <Typography color="textPrimary" variant="h4" style={{ marginBottom: "10px" }}>
                    Exercices :
                </Typography>

                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {gameConfig.steps.map(
                        (scenario: { description: React.Key | null | undefined }) => (
                            <Grid key={scenario.description} item xs={12} sm={6} md={3}>
                                <ScenarioCard scenario={scenario} />
                            </Grid>
                        ),
                    )}
                </Grid>
            </div>

            <Button
                variant="contained"
                color="secondary"
                disableElevation
                style={{ margin: "10px" }}
                onClick={stopGame}
                disabled={isPending}
            >
                Arrêter la partie
            </Button>

            <Button
                variant="contained"
                color="secondary"
                disableElevation
                style={{ margin: "10px" }}
                onClick={reset}
                disabled={isPending}
            >
                Nuker le jeu
            </Button>
        </div>
    );
};
