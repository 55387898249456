import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TrendingDownRoundedIcon from "@material-ui/icons/TrendingDownRounded";
import TrendingUpRoundedIcon from "@material-ui/icons/TrendingUpRounded";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profit: {
            display: "flex",
            alignItems: "center",
            gap: 8,
            "& > .MuiTypography-root": {
                fontWeight: "bold",
            },
            "& > .MuiSvgIcon-root": {
                fontSize: 18,
                padding: 2,
                borderRadius: "100%",
            },
        },
    })
);

export const ProfitValue: React.FC<{ value: number }> = ({ value }) => {
    const classes = useStyles();
    return (
        <div className={classes.profit}>
            {value > 0 ? (
                <TrendingUpRoundedIcon
                    style={{
                        backgroundColor: "#00cca533",
                        color: "#00cca5",
                    }}
                />
            ) : (
                <TrendingDownRoundedIcon
                    style={{
                        backgroundColor: "#FF4C4133",
                        color: "#FF4C41",
                    }}
                />
            )}
            <Typography variant="body2">{`$${Math.round(value)}`}</Typography>
        </div>
    );
};
