import React from "react";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { GameSetupFormValues } from "./GameSetup";

interface GameSetupSelectLevelProps {
    values: GameSetupFormValues;
    handleInputChange: () => void;
}

export const GameSetupSelectLevel: React.FC<GameSetupSelectLevelProps> = ({
    values,
    handleInputChange,
}) => {
    return (
        <FormControl component="fieldset">
            <FormLabel style={{ marginBottom: 8 }}>Choisir le niveau de difficulté</FormLabel>
            <RadioGroup
                aria-label="difficultyLevel"
                name="difficultyLevel"
                value={values.difficultyLevel}
                onChange={handleInputChange}
            >
                <FormControlLabel value="easy" control={<Radio color="primary" />} label="Facile" />
                <FormControlLabel
                    value="medium"
                    control={<Radio color="primary" />}
                    label="Confirmé"
                />
                <FormControlLabel
                    value="hard"
                    control={<Radio color="primary" />}
                    label="Difficile"
                />
            </RadioGroup>
        </FormControl>
    );
};
