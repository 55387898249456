import React, { useEffect, useState } from "react";
import axios from "axios";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import { EASY, BACKEND_API } from "../../../constant";
import { GameSetupStepContent } from "./GameSetupStepContent";
import { useGameConfigHook } from "../../../hooks/useGameConfig";
import { useCustomAlertContext } from "../../../context/CustomAlertContext";
import { useForm } from "../../../hooks/useForm";
import { useReload } from "../../../hooks/useReload";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

export interface GameSetupFormValues {
    difficultyLevel: string;
    selectedAccounts: string[];
}

interface GameSetupProps {
    scenarioData: any;
    gameConfig: any;
}

export const GameSetup: React.FC<GameSetupProps> = ({ gameConfig, scenarioData }) => {
    const classes = useStyles();

    const reuseAccounts: boolean =
        gameConfig && gameConfig.availableAccounts && gameConfig.availableAccounts.length;

    const steps: string[] = [
        "Sélection du niveau de difficulté",
        "Sélection des comptes",
        "Sélection du scénario",
    ];

    const modelObjectForm: GameSetupFormValues = {
        difficultyLevel: EASY || "",
        selectedAccounts: [],
    };

    const { values, handleInputChange, setValues } = useForm(modelObjectForm);
    const [activeStep, setActiveStep] = useState<number>(reuseAccounts ? 2 : 0);
    const [stepStart, setStartStep] = useState<string>("");
    const [stepEnd, setEndStep] = useState<string>("");

    const reloadHook = useReload();
    const gameConfigHook = useGameConfigHook();
    const { showCustomAlert } = useCustomAlertContext();

    const handleNext = (): void => {
        if (activeStep === 0 && !values.difficultyLevel) {
            showCustomAlert(
                "warning",
                "Informations incorrectes",
                "Veuillez choisir la difficulté du jeu.",
            );
            return;
        }
        if (activeStep === 1 && !values.selectedAccounts.length) {
            showCustomAlert(
                "warning",
                "Informations incorrectes",
                "Veuillez choisir au moins un compte joueur.",
            );
            return;
        }
        if (activeStep === 0 && reuseAccounts) {
            setActiveStep(2);
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (): void => {
        if (reuseAccounts) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleLaunch = (
        level: any,
        title: any,
        scenarios: any[],
        stepStart: number,
        stepEnd: number,
    ) => {
        showCustomAlert("info", "Veuillez patienter...", "Nous sommes en train de créer le jeu.");
        const steps = scenarios.filter(
            (s: { gitUrl: number }) => s.gitUrl >= stepStart && s.gitUrl <= stepEnd,
        );
        const data = {
            level: values.difficultyLevel,
            accounts: reuseAccounts ? undefined : values.selectedAccounts,
            title,
            gitUrl: stepStart,
            steps,
        };
        axios
            .post(`${BACKEND_API}/config`, data)
            .then((response) => {
                if (response.data.status === 200) {
                    showCustomAlert("success", "Tout est bon !", "Le jeu a été créé avec succès.");
                    gameConfigHook.fetchGameConfig();
                    reloadHook.reloadPage();
                }
            })
            .catch((error) => {
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "Quelque chose s'est mal passé, veuillez réessayer.",
                );
            });
    };

    const hooks = {
        values,
        handleInputChange,
        setValues,
        handleLaunch,
        stepStart,
        setStartStep,
        stepEnd,
        setEndStep,
    };

    return (
        <div>
            <div style={{ boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)" }}>
                <Typography
                    color="textPrimary"
                    variant="h2"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                    Lancement du Cloud Challenge
                </Typography>
                <Divider variant="middle" style={{ marginTop: "30px", marginBottom: "30px" }} />
                <Stepper activeStep={activeStep} alternativeLabel style={{ background: "#fff" }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div style={{ padding: "15px" }}>
                    {activeStep === steps.length ? (
                        <Typography className={classes.instructions}>
                            All steps completed
                        </Typography>
                    ) : (
                        <div>
                            <div className={classes.instructions}>
                                <GameSetupStepContent
                                    stepIndex={activeStep}
                                    scenarioData={scenarioData}
                                    hooks={hooks}
                                />
                            </div>
                            <div>
                                {!reuseAccounts && (
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        disableElevation={true}
                                        className={classes.backButton}
                                    >
                                        Précédent
                                    </Button>
                                )}
                                {activeStep <= steps.length - 2 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disableElevation={true}
                                        onClick={handleNext}
                                    >
                                        Suivant
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
