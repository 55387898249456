import React, { useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Leader } from "./Leader";
import { GamedayScoresTable } from "./GamedayScoresTable";
import { useScoreContext } from "../../context/ScoreContext";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ScoreEvolutionChart } from "./charts/ScoreEvolutionChart";
import { nothingToDisplay, getTeamsAccountIds, getLastProfit } from "../../common/helpers";
import { ProfitDistributionChart } from "./charts/ProfitDistributionChart";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const ChartCard = withStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: theme.spacing(2),
        transition: theme.transitions.create(["all"]),
        boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
    },
}))(Paper);

export const GamedayScoresPage: React.FC = () => {
    useDocumentTitle("Cloud Challenge - Scores");
    const { gamedayScores, stateHasError } = useScoreContext();

    const leaderValues: { name: string; profit: number } | false = useMemo(() => {
        if (nothingToDisplay(gamedayScores)) {
            return false;
        } else {
            const leaderId = getTeamsAccountIds(gamedayScores).reduce(
                (leaderId, currentTeamId) =>
                    getLastProfit(gamedayScores[leaderId]) >
                    getLastProfit(gamedayScores[currentTeamId])
                        ? leaderId
                        : currentTeamId,
                Object.keys(gamedayScores)[0]
            );
            return getLastProfit(gamedayScores[leaderId]) === -Infinity
                ? false
                : {
                      name: gamedayScores[leaderId].name,
                      profit: getLastProfit(gamedayScores[leaderId]),
                  };
        }
    }, [gamedayScores]);

    if (stateHasError) {
        return (
            <Alert severity="warning">
                <AlertTitle>Une erreur est survenue</AlertTitle>
                <Typography variant="body2">
                    Il est impossible d'afficher les scores des différents participants du Gameday
                    pour l'instant. Essayez de rafraîchir la page, ou contactez un administrateur.
                </Typography>
            </Alert>
        );
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} lg={9}>
                <ChartCard>
                    <ScoreEvolutionChart />
                </ChartCard>
            </Grid>
            <Grid item xs={12} lg={3}>
                <ChartCard style={{ backgroundColor: leaderValues ? "#d1e9fc" : "#fff" }}>
                    <Leader leaderValues={leaderValues} />
                </ChartCard>
            </Grid>
            <Grid item xs={12}>
                <ChartCard>
                    <GamedayScoresTable state={gamedayScores} />
                </ChartCard>
            </Grid>
            <Grid item xs={12}>
                <ChartCard>
                    <ProfitDistributionChart />
                </ChartCard>
            </Grid>
        </Grid>
    );
};
