import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Typography } from "@material-ui/core";

const navWidth = 172;

const useStyles = makeStyles((theme) => ({
    nav: {
        width: navWidth,
        "& > div": {
            position: "sticky",
            top: 96,
            padding: theme.spacing(2),
        },
    },
    list: {
        position: "sticky",
        top: 96,
        padding: theme.spacing(2),
        "& > *": {
            marginBottom: theme.spacing(0.5),
        },
        "& > ul": {
            padding: 0,
            margin: 0,
        },
    },
    navigationLink: {
        listStyle: "none",
        cursor: "pointer",
        padding: theme.spacing(0.5, 0.75),
        borderLeft: "2px solid transparent",
        "&:hover": {
            borderLeft: "2px solid #e3e3e9",
        },
        "& > .MuiLink-root": {
            display: "block",
            textDecoration: "none",
        },
    },
    indent: {
        "& > *": {
            paddingLeft: theme.spacing(2.5),
        },
    },
}));

const NavigationLink: React.FC<{
    href: string;
    label: string;
}> = ({ href, label }) => {
    const classes = useStyles();

    return (
        <li className={classes.navigationLink}>
            <Link href={href} color="textSecondary" variant="body2">
                {label}
            </Link>
        </li>
    );
};

interface navigationLink {
    href: string;
    label: string;
}

interface GamedayPageSideNavigationProps {
    links: { title: navigationLink; links?: navigationLink[] }[];
}

export const GamedayPageSideNavigation: React.FC<GamedayPageSideNavigationProps> = ({ links }) => {
    const classes = useStyles();

    return (
        <nav className={classes.nav} aria-label="rules navigation">
            <div className={classes.list}>
                <Typography>Navigation</Typography>
                <ul>
                    {links.map((section) => (
                        <React.Fragment key={section.title.label}>
                            <NavigationLink href={section.title.href} label={section.title.label} />
                            <div className={classes.indent}>
                                {section.links &&
                                    section.links.map((link) => (
                                        <NavigationLink
                                            key={link.label}
                                            href={link.href}
                                            label={link.label}
                                        />
                                    ))}
                            </div>
                        </React.Fragment>
                    ))}
                </ul>
            </div>
        </nav>
    );
};
