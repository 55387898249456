import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import BugReportRoundedIcon from "@material-ui/icons/BugReportRounded";
import Tooltip from "@material-ui/core/Tooltip";
import Popover from "@material-ui/core/Popover";
import { Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 3,
        },
        absolute: {
            zIndex: 2000,
            position: "fixed",
            bottom: theme.spacing(2),
            left: `calc(100vw - 92px)`,
        },
    })
);

export const BugReport: React.FC = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <React.Fragment>
            <Backdrop open={open} className={classes.backdrop}>
                <Popover
                    onClose={handleClose}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <iframe
                        src="https://docs.google.com/forms/d/e/1FAIpQLSeewBOKRh0l1ICbQTnyv474ETxW7HXjya7ojn5d6jcDqwCkJw/viewform?embedded=true"
                        width={640}
                        height={560}
                        frameBorder={0}
                        marginHeight={16}
                        marginWidth={16}
                        title="Bug form"
                    >
                        Chargement…
                    </iframe>
                </Popover>
            </Backdrop>
            <Tooltip arrow title="J'ai trouvé un bug !" aria-label="Bug found">
                <Fab
                    color="primary"
                    aria-label="Bug found"
                    className={classes.absolute}
                    onClick={handleClick}
                >
                    <BugReportRoundedIcon />
                </Fab>
            </Tooltip>
        </React.Fragment>
    );
};
