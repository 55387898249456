import React, { createContext, useContext, useEffect, useState } from "react";
import { ScenarioData } from "./../interfaces/Scenario";
import axios from "axios";
import { BACKEND_API } from "../constant";
import { useCustomAlertContext } from "./CustomAlertContext";

interface ScenarioDataContextProps {
    scenarioData: ScenarioData;
    isScenarioDataLoading: boolean;
    scenarioDataHasError: boolean;
}

const ScenarioDataContext = createContext<ScenarioDataContextProps>({
    scenarioData: {},
    isScenarioDataLoading: false,
    scenarioDataHasError: false,
});

export const useScenarioDataContext = () => useContext(ScenarioDataContext);

export const ScenarioDataProvider: React.FC = ({ children }) => {
    const [scenarioData, setScenarioData] = useState<ScenarioData>({});
    const [isScenarioDataLoading, setIsScenarioDataLoading] = useState<boolean>(true);
    const [scenarioDataHasError, setScenarioDataHasError] = useState<boolean>(false);

    useEffect(() => {
        setScenarioDataHasError(false);
        const fetchData = async () => {
            setIsScenarioDataLoading(true);
            axios
                .get(`${BACKEND_API}/scenarios`)
                .then((response) => {
                    setScenarioData(response.data);
                    setIsScenarioDataLoading(false);
                })
                .catch((error) => {
                    setScenarioDataHasError(true);
                    console.error(error);
                    setIsScenarioDataLoading(false);
                });
        };
        fetchData();
    }, []);

    const { showCustomAlert } = useCustomAlertContext();

    useEffect(() => {
        if (scenarioDataHasError) {
            showCustomAlert(
                "error",
                "Erreur",
                "impossible de récupérer les scenarios du Cloud-Challenge."
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioDataHasError]);

    return (
        <ScenarioDataContext.Provider
            value={{ scenarioData, isScenarioDataLoading, scenarioDataHasError }}
        >
            {children}
        </ScenarioDataContext.Provider>
    );
};
