import React from "react";
import axios from "axios";
import Iframe from "react-iframe";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { CLOUDMAPPER } from "../constant";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    root: {
        flexGrow: 1,
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    line: {
        "& > *": {
            display: "inline",
            margin: theme.spacing(1),
        },
        marginBottom: theme.spacing(2),
    },
}));

interface PanelProps {
    cloudmapper: any;
    staffing: boolean;
    large: any;
}

export const Panel: React.FC<PanelProps> = ({ cloudmapper, staffing, large }) => {
    const classes = useStyles();

    const [urlStatic] = React.useState(
        `${CLOUDMAPPER}/static/${cloudmapper.name}/?${Math.round(Math.random() * 100).toString()}`
    );
    const [urlStatus] = React.useState(CLOUDMAPPER + "/api/cloudmapper/status/" + cloudmapper.id);
    const [urlRefresh] = React.useState(CLOUDMAPPER + "/api/cloudmapper/" + cloudmapper.id);

    React.useEffect(() => {
        const intrvl = setInterval(() => isAlive(), 3000);
        return () => clearInterval(intrvl);
    });

    const refreshIframe = () => {
        const iframes = document.getElementsByClassName(cloudmapper.id);
        Object.values(iframes).forEach((iframe: any) => {
            iframe.src = `${CLOUDMAPPER}/static/${cloudmapper.name}/?${Math.round(
                Math.random() * 100
            ).toString()}`;
        });
    };

    const isAlive = () => {
        axios
            .get(urlStatus)
            .then((resp) => {
                const status = resp.data;
                if (status === "ready") {
                    refreshIframe();
                    refresh();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const refresh = () => {
        axios.post(urlRefresh).catch((error) => console.log(error));
    };

    const open = () => {
        window.open(urlStatic, "_blank", "noopener");
    };

    const height = () => {
        if (large) {
            return "800px";
        }
        return "500px";
    };

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Iframe
                    url={urlStatic}
                    width="100%"
                    height={height()}
                    className={cloudmapper.id}
                    position="relative"
                    loading="auto"
                />
            </Grid>
            {staffing ? null : (
                <Grid item xs={12}>
                    <div className={classes.line}>
                        <div>
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={() => open()}
                            >
                                Ouvrir
                            </Button>
                            <Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                onClick={() => refreshIframe()}
                                style={{ marginLeft: "10px" }}
                            >
                                Rafraichir le schema
                            </Button>
                        </div>
                    </div>
                </Grid>
            )}
        </Grid>
    );
};
