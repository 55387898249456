import React, { createContext, useContext, useEffect, useState } from "react";
import { makeStyles, Backdrop } from "@material-ui/core";
import innovateLogo from "../assets/Innovate.svg";
import { useScenarioDataContext } from "./ScenarioDataContext";
import { useGameConfigContext } from "./GameConfigContext";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 2,
    },
    innovateLogo: {
        width: 64,
        animation: "$rotate 1s linear infinite",
    },
    "@keyframes rotate": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(90deg)",
        },
    },
}));

interface LoaderContextProps {
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextProps>({
    isLoading: false,
    setIsLoading: () => {},
});

export const useLoaderContext = () => useContext(LoaderContext);

export const Loader: React.FC<{ isLoading?: boolean }> = ({ isLoading = true }) => {
    const classes = useStyles();
    return (
        <Backdrop open={isLoading} className={classes.backdrop}>
            <img
                src={innovateLogo}
                className={classes.innovateLogo}
                draggable={false}
                alt="Cloud Challenge IPPON"
            />
        </Backdrop>
    );
};
/**
 * This provider is a wrapper that renders Loader component only once at the root of the App.
 *
 * Import `setIsLoading` from it to display/hide the loader.
 */
export const LoaderContextProvider: React.FC = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isScenarioDataLoading } = useScenarioDataContext();
    const { isGameConfigLoading } = useGameConfigContext();

    useEffect(() => {
        const isDataLoading: boolean = isScenarioDataLoading || isGameConfigLoading;
        setIsLoading(isDataLoading);
    }, [isScenarioDataLoading, isGameConfigLoading]);

    return (
        <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
            <Loader isLoading={isLoading} />
            {children}
        </LoaderContext.Provider>
    );
};
