import React, { createContext, useContext, useEffect } from "react";
import { useCustomAlertContext } from "./CustomAlertContext";
import { useGameConfigHook } from "../hooks/useGameConfig";

interface GameConfigContextProps {
    gameConfig: any;
    isGameConfigLoading: boolean;
    gameConfigHasError: boolean;
}

export const GameConfigContext = createContext<GameConfigContextProps>({
    gameConfig: {},
    isGameConfigLoading: false,
    gameConfigHasError: false,
});

export const useGameConfigContext = () => useContext(GameConfigContext);

export const GameConfigProvider: React.FC = ({ children }) => {
    const { gameConfig, isGameConfigLoading, gameConfigHasError } = useGameConfigHook();
    const { showCustomAlert } = useCustomAlertContext();

    useEffect(() => {
        if (gameConfigHasError) {
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "impossible de récupérer la configuration du Cloud-Challenge."
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameConfigHasError]);

    return (
        <GameConfigContext.Provider value={{ gameConfig, isGameConfigLoading, gameConfigHasError }}>
            {children}
        </GameConfigContext.Provider>
    );
};
