import { ChangeEvent, useState } from "react";

export const useForm = (modelObject: any) => {
    const [values, setValues] = useState(modelObject);

    const handleInputChange: any = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const resetForm = () => {
        setValues(modelObject);
    };

    return {
        values,
        setValues,
        handleInputChange,
        resetForm,
    };
};
