import React from "react";
import axios from "axios";
import { BACKEND_API } from "../../constant";
// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider } from "@material-ui/core";
import Input from "../../common/Input";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../common/useForms";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
//Cognito
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../helpers/UserPool";
import { useCustomAlertContext } from "../../context/CustomAlertContext";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
        backgroundColor: "#FFFFFF",
    },
    title: {
        textAlign: "center",
    },
    body: {
        textAlign: "left",
        paddingTop: theme.spacing(5),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
    block: {
        marginBottom: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.primary,
    },
    button: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.primary,
    },
}));

export const StaffSetup: React.FC = () => {
    const classes = useStyles();
    const { showCustomAlert } = useCustomAlertContext();
    const navigate = useNavigate();

    const modelObject = {
        username: "",
        password: "",
    };

    const getCandidate = (applicant: string) => {
        axios
            .get(`${BACKEND_API}/teams/${applicant}`)
            .then((res) => {
                const account = res.data.team;
                sessionStorage.setItem("applicantName", account.name);
                navigate(`/player/staffing/${account.name}`);
                showCustomAlert(
                    "success",
                    "Bon courage !",
                    "La connexion a été effectuée avec succès, à vous de jouer !"
                );
                resetForm();
            })
            .catch((_err) => {
                showCustomAlert("error", "Problème interne", "Compte inexistant");
            });
    };

    const { values, handleInputChange, resetForm } = useForm(modelObject);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        showCustomAlert("info", "Infos", "Connexion en cours ...");
        const user = new CognitoUser({
            Username: values.username,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username: values.username,
            Password: values.password,
        });
        user.authenticateUser(authDetails, {
            onSuccess: () => {
                getCandidate(values.username);
            },
            onFailure: (err) => {
                console.error("onFailure:", err);
                if (err.code === "NotAuthorizedException") {
                    showCustomAlert(
                        "error",
                        "Informations erronées",
                        "Votre nom d'utilisateur ou mot de passe est incorrect."
                    );
                } else {
                    showCustomAlert("error", "Erreur", err.message);
                }
            },
            newPasswordRequired: (data) => {
                showCustomAlert("error", "Erreur", data.message);
            },
        });
    };

    return (
        <Card className={classes.root}>
            <div className={classes.title}>
                <Typography color="primary" variant="h2">
                    <b>BIENVENUE</b>
                    <br />
                    SUR TA SESSION DE RECRUTEMENT
                </Typography>
                <Divider variant="middle" className={classes.divider} />
            </div>
            <Form className={classes.form} onSubmit={handleSubmit}>
                <Box component="span" className={classes.block} display="block">
                    <Typography color="error" variant="subtitle1">
                        Tous les champs sont obligatoires
                    </Typography>
                </Box>
                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Nom d'utilisateur
                    </Typography>
                    <Input name="username" value={values.username} onChange={handleInputChange} />
                </Box>
                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Mot de passe
                    </Typography>
                    <Input
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={handleInputChange}
                    />
                </Box>
                <div className={classes.button}>
                    <Button disableElevation variant="contained" color="primary" type="submit">
                        <Grid container direction="row" alignItems="center">
                            Lancer la session
                            <ArrowForwardIcon style={{ marginLeft: "10px" }} fontSize="small" />
                        </Grid>
                    </Button>
                </div>
            </Form>
        </Card>
    );
};
