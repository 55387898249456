import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import logoBlue from "../assets/logo_blue.svg";
import clsx from "clsx";
import { useScenarioDataContext } from "../context/ScenarioDataContext";
import { useGameConfigContext } from "../context/GameConfigContext";
import { Role } from "../interfaces/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    logo: {
        top: 32,
        left: "50%",
        height: 70,
        position: "absolute",
        transform: "translateX(-50%)",
        zIndex: 2,
    },
    halfPage: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        filter: `grayscale(100%)`,
        transition: theme.transitions.create(["all"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.standard,
        }),
    },
    transition: {
        "&:hover": {
            filter: "grayscale(0%)",
        },
    },
    left: {
        float: "left",
        backgroundImage: "url('staffing.jpg')",
    },
    right: {
        float: "right",
        backgroundImage: "url('gameday.jpg')",
    },
    settings: {
        zIndex: 2,
        width: "100%",
        position: "absolute",
        bottom: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(3),
    },
    link: {
        textDecoration: "none",
        cursor: "default",
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

const TooltipButton = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        border: "1px solid #dadde9",
        fontSize: theme.typography.pxToRem(12),
        textAlign: "center",
    },
}))(Tooltip);

interface WelcomePageProps {
    role: Role;
}

const WelcomePage: React.FC<WelcomePageProps> = ({ role }) => {
    const { scenarioDataHasError } = useScenarioDataContext();
    const { gameConfigHasError } = useGameConfigContext();

    const fetchingDataHasError: boolean = scenarioDataHasError || gameConfigHasError;

    const classes = useStyles();

    const enumRoleContent = {
        player: {
            staffingLink: fetchingDataHasError ? "/player" : "/player/staffing",
            gamedayLink: fetchingDataHasError ? "/player" : "/player/gameday",
            staffingTooltip: (
                <React.Fragment>
                    <Typography color="inherit">Session de recrutement</Typography>
                    Vous vous faîtes tester sur vos compétences AWS !
                </React.Fragment>
            ),
            gamedayTooltip: (
                <React.Fragment>
                    <Typography color="inherit">Rejoindre la partie</Typography>
                    Affronter plusieurs équipes pour savoir qui est le meilleur architecte AWS ?
                </React.Fragment>
            ),
        },
        admin: {
            staffingLink: fetchingDataHasError ? "/admin" : "/admin/staffing",
            gamedayLink: fetchingDataHasError ? "/admin" : "/admin/gameday",
            staffingTooltip: (
                <React.Fragment>
                    <Typography color="inherit">Lancer une session de recrutement</Typography>
                    Testez le candidat sur AWS
                </React.Fragment>
            ),
            gamedayTooltip: (
                <React.Fragment>
                    <Typography color="inherit">Lancer un Cloud Challenge</Typography>
                    Faîtes affronter plusieurs équipes pour savoir qui est le meilleur architecte
                    AWS
                </React.Fragment>
            ),
        },
    };

    return (
        <div className={classes.root}>
            <Link to="/">
                <TooltipButton title={"Retourner à l'accueil"}>
                    <img
                        className={classes.logo}
                        src={logoBlue}
                        alt="IPPON"
                        data-testid="logo-link"
                    />
                </TooltipButton>
            </Link>
            <Link to={enumRoleContent[role].staffingLink} className={classes.link}>
                <div
                    className={clsx(
                        !fetchingDataHasError && classes.transition,
                        classes.halfPage,
                        classes.left
                    )}
                >
                    <TooltipButton
                        disableHoverListener={fetchingDataHasError}
                        title={enumRoleContent[role].staffingTooltip}
                    >
                        <span>
                            <Button
                                disabled={fetchingDataHasError}
                                color="primary"
                                disableElevation
                                size="large"
                                variant="contained"
                            >
                                Recrutement
                            </Button>
                        </span>
                    </TooltipButton>
                </div>
            </Link>
            <Link to={enumRoleContent[role].gamedayLink} className={classes.link}>
                <div
                    className={clsx(
                        !fetchingDataHasError && classes.transition,
                        classes.halfPage,
                        classes.right
                    )}
                >
                    <TooltipButton
                        disableHoverListener={fetchingDataHasError}
                        title={enumRoleContent[role].gamedayTooltip}
                    >
                        <span>
                            <Button
                                disabled={fetchingDataHasError}
                                color="primary"
                                disableElevation
                                size="large"
                                variant="contained"
                                style={{
                                    color: fetchingDataHasError
                                        ? "rgba(255, 255, 255, 0.18)"
                                        : "#ffffff",
                                }}
                            >
                                Gameday
                            </Button>
                        </span>
                    </TooltipButton>
                </div>
            </Link>
            {role === "admin" && (
                <div className={classes.settings}>
                    <LightTooltip title="Documentation du Cloud Challenge">
                        <IconButton
                            color="primary"
                            href="http://doc.gameday.aws.ippon.fr/"
                            target={"_blank"}
                        >
                            <InfoIcon fontSize="large" />
                        </IconButton>
                    </LightTooltip>
                    <LightTooltip title="Vers la Danger Zone">
                        <Link className={classes.link} to="/admin/dangerzone">
                            <IconButton color="primary">
                                <SettingsIcon fontSize="large" />
                            </IconButton>
                        </Link>
                    </LightTooltip>
                </div>
            )}
        </div>
    );
};
export default WelcomePage;
