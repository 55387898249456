import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";
import { BACKEND_API } from "../../constant";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { downloadCredentials } from "../../common/helpers";
import { useCustomAlertContext } from "../../context/CustomAlertContext";
import { useReload } from "../../hooks/useReload";

interface RegisterProps {
    openRegister: any;
    handleCloseRegister: any;
    id: any;
    gameConfig: any;
}

const Register: React.FC<RegisterProps> = ({
    openRegister,
    handleCloseRegister,
    id,
    gameConfig,
}) => {
    const { showCustomAlert } = useCustomAlertContext();
    const [username, setUsername] = useState<string>("");

    const trigger = async (): Promise<void> => {
        await handleSubmitRegister();
    };

    const reloadHook = useReload();
    const userNameIsValid = (name: string): boolean => {
        const regexp = /^[a-zA-Z0-9_]+$/;
        return regexp.test(name);
    };

    const handleSubmitRegister = async (): Promise<void> => {
        if (!userNameIsValid(username)) {
            showCustomAlert(
                "warning",
                "Nom invalide",
                "Veuillez n'utiliser que des lettres, des chiffres et underscore (_).",
            );
            return;
        }
        showCustomAlert("info", "Veuillez patienter...", "Nous sommes en train de créer l'équipe.");
        const data = {
            username: username,
            id,
            scenarioGitUrl: gameConfig.scenarioGitUrl,
            useCase: "Gameday",
            provider: "AWS",
        };
        let response;

        try {
            response = await axios.post(`${BACKEND_API}/teams`, data);
            if (response.data.status === 200) {
                showCustomAlert(
                    "success",
                    "Tout est bon !",
                    "L'équipe est a été enregistrée avec succès.",
                );
                downloadCredentials(
                    "AWS",
                    `${id}`,
                    username,
                    response.data.credentials.Password,
                    response.data.credentials.accessKey.AccessKey,
                );
                reloadHook.reloadPage();
            }
        } catch (error) {
            console.log(error);
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "Quelque chose s'est mal passé, veuillez réessayer.",
            );
        }
        handleCloseRegister();
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"sm"}
                open={openRegister}
                onClose={handleCloseRegister}
                aria-labelledby="form-dialog-title"
            >
                <div style={{ backgroundColor: "white" }}>
                    <DialogTitle id="form-dialog-title">
                        Enregistrer une équipe au Gameday
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Entrer son nom</DialogContentText>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            margin="dense"
                            id="name"
                            label=""
                            type="text"
                            helperText="N'utilisez que des minuscules"
                            key="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleCloseRegister}
                            color="primary"
                            startIcon={<CloseIcon />}
                        >
                            Fermer
                        </Button>
                        <Button onClick={trigger} color="primary" startIcon={<CheckIcon />}>
                            Enregistrer
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};

export default Register;
