import React from "react";
import { GameSetupSelectLevel } from "./GameSetupSelectLevel";
import { GameSetupSelectAccounts } from "./GameSetupSelectAccounts";
import { GameSetupSelectScenario } from "./GameSetupSelectScenario";
import { Alert, AlertTitle } from "@material-ui/lab";

interface GameSetupStepContentProps {
    stepIndex: any;
    scenarioData: any;
    hooks: any;
}

export const GameSetupStepContent: React.FC<GameSetupStepContentProps> = ({
    stepIndex,
    scenarioData,
    hooks,
}) => {
    switch (stepIndex) {
        case 0:
            return (
                <GameSetupSelectLevel
                    values={hooks.values}
                    handleInputChange={hooks.handleInputChange}
                />
            );
        case 1:
            return <GameSetupSelectAccounts values={hooks.values} setValues={hooks.setValues} />;
        case 2:
            return (
                <GameSetupSelectScenario
                    scenarioData={scenarioData}
                    level={hooks.values.difficultyLevel}
                    {...hooks}
                />
            );
        default:
            return (
                <Alert severity="error">
                    <AlertTitle>Une erreur est survenue</AlertTitle>
                    Quelque chose a mal tourné par ici, veuillez réessayer.
                </Alert>
            );
    }
};
