import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import ChaosIcon from "@material-ui/icons/Whatshot";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloudIcon from "@material-ui/icons/Cloud";
import { Accounts } from "./Accounts";
import { ResetGame } from "./ResetGame";
import { Infrastructure } from "./Infrastructure";
import { Chaos } from "./Chaos";
import Copyright from "../../common/Copyright";
import logo from "../../assets/logo.svg";
import { GameSetup } from "./gameday/GameSetup";
import { useScenarioDataContext } from "../../context/ScenarioDataContext";
import { useGameConfigContext } from "../../context/GameConfigContext";
import { useScoreContext } from "../../context/ScoreContext";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "none",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        backgroundColor: "#111827",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 500,
    },
}));

interface DrawerLinkProps {
    link: string;
    listItemText: string;
    icon: React.ReactNode;
}

const DrawerLink: React.FC<DrawerLinkProps> = ({ link, icon, listItemText }) => {
    return (
        <Link to={`/admin/gameday${link}`} className="text-link">
            <ListItem>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={listItemText} />
            </ListItem>
        </Link>
    );
};

export const DashboardGameday: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);

    const { scenarioData } = useScenarioDataContext();
    const { gameConfig, isGameConfigLoading } = useGameConfigContext();
    const { gamedayScores } = useScoreContext();

    const handleDrawerOpen = (): void => {
        setOpen(true);
    };

    const handleDrawerClose = (): void => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h2" style={{ fontSize: "40px" }}>
                        Gameday
                    </Typography>
                    <img className="logo" src={logo} alt="Logo" />
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                }}
                open={open}
                className="Drawer"
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon style={{ color: "white" }} />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <DrawerLink link={""} icon={<HomeIcon />} listItemText="Gameday" />
                    <DrawerLink
                        link={"/accounts"}
                        icon={<AccountCircleIcon />}
                        listItemText="Comptes"
                    />
                    <DrawerLink
                        link={"/infrastructures"}
                        icon={<CloudIcon />}
                        listItemText="Infrastructures"
                    />
                    <DrawerLink link={"/chaos"} icon={<ChaosIcon />} listItemText="Chaos" />
                </List>
                <Divider />
            </Drawer>

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                <Container maxWidth="lg" className={classes.container}>
                    {!isGameConfigLoading && (
                        <Routes>
                            <Route path="chaos" element={<Chaos scores={gamedayScores} />} />
                            <Route
                                path="accounts"
                                element={<Accounts gameConfig={gameConfig} gameDay={true} />}
                            />
                            <Route
                                path="infrastructures"
                                element={<Infrastructure gameConfig={gameConfig} />}
                            />
                            <Route
                                path="*"
                                element={
                                    gameConfig.scenarioGitUrl ? (
                                        <ResetGame gameConfig={gameConfig} />
                                    ) : (
                                        <GameSetup
                                            gameConfig={gameConfig}
                                            scenarioData={scenarioData}
                                        />
                                    )
                                }
                            />
                        </Routes>
                    )}
                </Container>
                <Copyright />
            </main>
        </div>
    );
};
