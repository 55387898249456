import React from "react";
import "./ChipRadioInput.css";

interface ChipRadioInputProps {
    isChecked: boolean;
    name: string;
    value: string;
    label: string;
    onChange: any;
}

const ChipRadioInput: React.FC<ChipRadioInputProps> = ({
    isChecked,
    value,
    name,
    label,
    onChange,
}) => {
    return (
        <div className="radio-toolbar">
            <input
                type="radio"
                id={`radio-${name}-${value}`}
                name={name}
                value={value}
                checked={isChecked}
                onChange={onChange}
            />
            <label htmlFor={`radio-${name}-${value}`}>{label}</label>
        </div>
    );
};

export default ChipRadioInput;
