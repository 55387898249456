import React from "react";
import axios from "axios";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "@material-ui/core";
import arrow from "../assets/arrow.svg";
import cross from "../assets/cross.svg";
import { CLOUDMAPPER } from "../constant";
import { Panel } from "./Panel";
import { Role } from "../interfaces/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left",
    },
    head: {
        marginBottom: theme.spacing(2),
        display: "flex",
    },
    title: {
        fontSize: "22px",
    },
    typography: {
        fontSize: "15px",
        marginBottom: theme.spacing(2),
    },
    arrow: {
        marginLeft: "auto",
        cursor: "pointer",
    },
}));

interface CloudmapperProps {
    accountId: string;
    cloudmapperLarge: any;
    setCloudmapperLarge: any;
    className?: any;
    role: Role;
}

export const Cloudmapper: React.FC<CloudmapperProps> = ({
    accountId,
    cloudmapperLarge,
    setCloudmapperLarge,
    className,
    role,
}) => {
    const classes = useStyles();

    const [cloudmapper, setCloudmapper] = React.useState<any>({});
    const [urlGet] = React.useState<string>(`${CLOUDMAPPER}/api/cloudmapper/${accountId}`);

    React.useEffect(() => {
        axios
            .get(urlGet)
            .then((resp) => {
                setCloudmapper(resp.data[0]);
            })
            .catch((error) => {
                console.log(error);
            });
        // eslint-disable-next-line
    }, []);

    const refreshIframe = () => {
        const iframes = document.getElementsByClassName(cloudmapper.id);
        Object.values(iframes).forEach((iframe: any) => {
            iframe.src = `${CLOUDMAPPER}/static/${cloudmapper.name}/?${Math.round(
                Math.random() * 100
            ).toString()}`;
        });
    };

    return (
        <Card className={className}>
            <CardContent className={classes.root}>
                <div className={classes.head}>
                    <Typography color="primary" className={classes.title}>
                        <b>
                            {role === "admin"
                                ? "Architecture AWS du candidat"
                                : "Votre architecture AWS"}
                        </b>
                    </Typography>
                    {cloudmapperLarge ? (
                        <img
                            onClick={() => setCloudmapperLarge(!cloudmapperLarge)}
                            src={cross}
                            alt="cross"
                            className={classes.arrow}
                        />
                    ) : (
                        <img
                            onClick={() => setCloudmapperLarge(!cloudmapperLarge)}
                            src={arrow}
                            alt="arrow"
                            className={classes.arrow}
                        />
                    )}
                </div>
                <Typography className={classes.typography}>
                    Le visualiseur se rafraîchit automatiquement toutes les 3 minutes. Si vous
                    souhaitez le rafraichir{" "}
                    <Link style={{ cursor: "pointer" }} onClick={() => refreshIframe()}>
                        cliquez ici.
                    </Link>
                </Typography>
                {!Object.values(cloudmapper).length ? (
                    <Skeleton animation="wave" variant="rect" style={{ height: 280 }} />
                ) : (
                    <Panel cloudmapper={cloudmapper} staffing large={cloudmapperLarge} />
                )}
            </CardContent>
        </Card>
    );
};
