import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { Cloudmapper } from "../../common/Cloudmapper";
import { Stopwatch } from "../../common/Stopwatch";
import { ScenarioCard } from "./staff/ScenarioCard";
import Copyright from "../../common/Copyright";
import { ApplicantInfo } from "../../common/ApplicantInfo";
import { StaffingHeaderBar } from "../../common/StaffingHeaderBar";
import { CloudServiceInfo } from "../../common/CloudServiceInfo";
import { Scenario } from "../../interfaces/Scenario";
import { Team } from "../../interfaces/Team";
import { Provider } from "../../interfaces/Types";
import { NetworkWatcher } from "../../common/NetworkWatcher/NetworkWatcher";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    title: {
        marginTop: theme.spacing(3),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    grid: {
        marginTop: theme.spacing(2),
    },
}));

interface PlayerStaffContentProps {
    applicantTeam: Team;
    selectedScenarios: Scenario[];
    cloudmapperLarge: boolean;
    setCloudmapperLarge: React.Dispatch<React.SetStateAction<boolean>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PlayerStaffContent: React.FC<PlayerStaffContentProps> = ({
    applicantTeam,
    selectedScenarios,
    cloudmapperLarge,
    setCloudmapperLarge,
    setOpen,
}) => {
    const classes = useStyles();

    const cloudProvider: Provider = useMemo(() => applicantTeam.provider, [applicantTeam]);

    const enumInfrastructure: { [provider in Provider]: React.ReactElement } = {
        AWS: (
            <Cloudmapper
                role="player"
                accountId={applicantTeam.accountId}
                cloudmapperLarge={cloudmapperLarge}
                setCloudmapperLarge={setCloudmapperLarge}
            />
        ),
        Azure: <NetworkWatcher applicantId={applicantTeam.accountId} role={"player"} />,
    };

    return (
        <React.Fragment>
            <StaffingHeaderBar name={applicantTeam.name} />
            <div className={classes.appBarSpacer} />
            <Container>
                <div className={classes.title}>
                    <Typography color="primary" variant="h2">
                        <b>BIENVENUE </b>
                        {applicantTeam.name.toUpperCase()}
                    </Typography>
                    <Divider variant="middle" className={classes.divider} />
                </div>
                <Stopwatch teamData={applicantTeam} />
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                >
                    Terminer la session
                </Button>

                <Typography className={classes.subtitle} color="textSecondary" variant="subtitle1">
                    Terminer la session signifie que le test est terminé
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={8}>
                        <ApplicantInfo
                            role="player"
                            cloudProvider={cloudProvider}
                            teamData={applicantTeam}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CloudServiceInfo
                            role="player"
                            cloudProvider={cloudProvider}
                            teamData={applicantTeam}
                        />
                    </Grid>
                </Grid>
                <Grid className={classes.grid} container spacing={3}>
                    <Grid item xs={6}>
                        <ScenarioCard scenarios={selectedScenarios} />
                    </Grid>
                    <Grid item xs={6}>
                        {enumInfrastructure[cloudProvider]}
                    </Grid>
                </Grid>
                <Copyright />
            </Container>
        </React.Fragment>
    );
};
