import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { theme } from "./theme";
import { CssBaseline } from "@material-ui/core";
import AppRouter from "./AppRouter";
import { CustomAlertContextProvider } from "./context/CustomAlertContext";
import { LoaderContextProvider } from "./context/LoaderContext";

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CustomAlertContextProvider>
                <LoaderContextProvider>
                    <CssBaseline />
                    <div className="App">
                        <AppRouter />
                    </div>
                </LoaderContextProvider>
            </CustomAlertContextProvider>
        </ThemeProvider>
    );
};

export default App;
