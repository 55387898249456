/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceLine,
    ResponsiveContainer,
} from "recharts";
import Title from "../../../common/Title";
import { ScoreCustomTooltip } from "./ScoreCustomTooltip";
import { ScoreCustomLegend } from "./ScoreCustomLegend";
import ChartIcon from "@material-ui/icons/Timeline";
import { SkeletonEmptyState } from "./SkeletonEmptyState";
import { useScoreContext } from "../../../context/ScoreContext";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";

export const ScoreEvolutionChart: React.FC = () => {
    const { gamedayScores } = useScoreContext();

    const [selectedTeams, setSelectedTeams] = useState<string[] | false>(false);

    const formatedScores = useMemo(() => {
        return Object.entries(gamedayScores).map(([key, value]: any) =>
            value.scores.slice(Math.max(value.scores.length - 20, 0)).map((elem: any) => {
                return {
                    timeValue: new Date(elem.timestamp).getTime(),
                    profit: elem.profit,
                    teamId: key,
                    teamName: value.name,
                };
            })
        );
    }, [gamedayScores]);

    useEffect(() => {
        if (Object.keys(gamedayScores).length && !selectedTeams) {
            const sessionStorageSelectedTeams: string[] = JSON.parse(
                sessionStorage.getItem("GamedayScoresChartSelectedTeams") || "[]"
            );
            if (sessionStorageSelectedTeams.length) {
                setSelectedTeams(sessionStorageSelectedTeams);
            } else {
                setSelectedTeams(Object.keys(gamedayScores).slice(0, 5));
            }
        }
    }, [gamedayScores]);

    const showAllTeams = () => {
        sessionStorage.setItem(
            "GamedayScoresChartSelectedTeams",
            JSON.stringify(Object.keys(gamedayScores))
        );
        setSelectedTeams(Object.keys(gamedayScores));
    };

    const hideAllTeams = () => {
        sessionStorage.setItem("GamedayScoresChartSelectedTeams", "[]");
        setSelectedTeams([]);
    };

    const handleLegendClick = (name: string) => () => {
        if (selectedTeams) {
            const sessionStorageSelectedTeams: string[] = JSON.parse(
                sessionStorage.getItem("GamedayScoresChartSelectedTeams") || "[]"
            );
            if (selectedTeams.includes(name)) {
                sessionStorage.setItem(
                    "GamedayScoresChartSelectedTeams",
                    JSON.stringify(sessionStorageSelectedTeams.filter((elem) => elem !== name))
                );
                setSelectedTeams(selectedTeams.filter((elem) => elem !== name));
            } else {
                sessionStorage.setItem(
                    "GamedayScoresChartSelectedTeams",
                    JSON.stringify([...selectedTeams, name])
                );
                setSelectedTeams([...selectedTeams, name]);
            }
        }
    };

    const profitByTimestamp: any[] = useMemo(() => {
        let flattenScores: any[] = [];
        formatedScores.forEach((teamScores: any[]) => {
            teamScores.forEach((score: any) => {
                const existingScoreIndex = flattenScores.findIndex(
                    (elem: any) => elem.timeValue === score.timeValue
                );
                if (existingScoreIndex === -1) {
                    flattenScores.push({
                        timeValue: score.timeValue,
                        [score.teamId]: Math.round(score.profit),
                    });
                } else {
                    flattenScores[existingScoreIndex] = {
                        ...flattenScores[existingScoreIndex],
                        [score.teamId]: Math.round(score.profit),
                    };
                }
            });
        });
        return flattenScores.sort((a, b) => a.timeValue - b.timeValue);
    }, [formatedScores]);

    if (!selectedTeams || !formatedScores.every((elem) => elem.length)) {
        return (
            <div>
                <Title>Évolution des scores</Title>
                <SkeletonEmptyState
                    height={371}
                    icon={<ChartIcon />}
                    label="Le graphe des scores va apparaître ici."
                />
            </div>
        );
    }
    return (
        <div>
            <Title>Évolution des scores</Title>
            <Typography variant="caption" color="textSecondary" paragraph>
                De base, seules les 5 premières équipes sont affichées sur le graphique.
            </Typography>
            <div style={{ display: "flex", height: 38 }}>
                {selectedTeams.toString() === Object.keys(gamedayScores).toString() ? (
                    <Button size="small" onClick={hideAllTeams}>
                        Cacher toutes les équipes
                    </Button>
                ) : (
                    <Button size="small" onClick={showAllTeams}>
                        Voir toutes les équipes
                    </Button>
                )}
                <ScoreCustomLegend selectedTeams={selectedTeams} handleClick={handleLegendClick} />
            </div>
            <div style={{ height: 300, fontSize: 12 }}>
                {!profitByTimestamp.length ? (
                    <SkeletonEmptyState
                        height={320}
                        icon={<ChartIcon />}
                        label="Le graphe des scores va apparaître ici."
                    />
                ) : (
                    <ResponsiveContainer>
                        <AreaChart
                            data={profitByTimestamp}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                            <defs>
                                {Object.entries(gamedayScores).map(([key, value]: any) => (
                                    <linearGradient
                                        key={`gradient-${key}`}
                                        id={`color${key}`}
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop offset="5%" stopColor={value.color} stopOpacity={0} />
                                        <stop
                                            offset="95%"
                                            stopColor={value.color}
                                            stopOpacity={0.8}
                                        />
                                    </linearGradient>
                                ))}
                            </defs>

                            <XAxis
                                stroke="#919eab"
                                dataKey="timeValue"
                                tickFormatter={(tick) =>
                                    new Date(tick).toLocaleTimeString().slice(0, -3)
                                }
                                tickLine={false}
                                padding={{ left: 32 }}
                                axisLine={false}
                            />
                            <YAxis stroke="#919eab" tickLine={false} axisLine={false} />
                            <ReferenceLine y={0} />
                            <CartesianGrid stroke="#f5f5f5" vertical={false} />

                            <Tooltip content={(props: any) => <ScoreCustomTooltip {...props} />} />
                            {Object.entries(gamedayScores).map(([key, value]: any) => {
                                return (
                                    selectedTeams.includes(key) && (
                                        <Area
                                            connectNulls
                                            key={`area-${key}`}
                                            type="monotone"
                                            dataKey={key}
                                            stroke={value.color}
                                            strokeWidth={2}
                                            fillOpacity={0.5}
                                            fill={`url(#color${key})`}
                                        />
                                    )
                                );
                            })}
                        </AreaChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};
