/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useLoaderContext } from "../context/LoaderContext";
import { useScenarioDataContext } from "../context/ScenarioDataContext";
import { useCustomAlertContext } from "../context/CustomAlertContext";
import { BACKEND_API } from "../constant";
import { useNavigate } from "react-router-dom";
import { Team } from "../interfaces/Team";
import { Scenario } from "../interfaces/Scenario";

/**
 * Custom hook handling all values from a Staffing session for both player and admin.
 *
 * @return {areStaffingValuesLoaded} If all values have finished loading
 * @return {applicantAccount} Team object, corresponding to the account used for the game
 * @return {selectedScenarios} Scenario's steps selected by the admin.
 */
export const useStaffingValues = (applicantName: string) => {
    const navigate = useNavigate();

    const [applicantTeam, setApplicantTeam] = useState<Team>({} as Team);

    const { setIsLoading } = useLoaderContext();
    const { scenarioData, isScenarioDataLoading } = useScenarioDataContext();
    const { showCustomAlert } = useCustomAlertContext();

    const fetchTeamByName = () => {
        axios
            .get(`${BACKEND_API}/teams/${applicantName}`)
            .then((res) => {
                setApplicantTeam(res.data.team);
                setIsLoading(false);
            })
            .catch(() => {
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "Cette session de staffing ne semble pas exister. Veuillez réessayer."
                );
                setIsLoading(false);
                navigate("..");
            });
    };

    useEffect(() => {
        setIsLoading(true);
        fetchTeamByName();
    }, [applicantName]);

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchTeamByName();
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    });

    const selectedScenarios: Scenario[] = useMemo(() => {
        const isApplicantTeamSet = Object.values(applicantTeam).length;
        if (isApplicantTeamSet && !isScenarioDataLoading) {
            const levelCandidate = applicantTeam.levelCandidate;
            const scenariosLevel =
                scenarioData[levelCandidate][applicantTeam.scenarioGitUrl.split("/")[5]];

            return scenariosLevel.slice(
                applicantTeam.scenarioStepStart,
                applicantTeam.scenarioStepEnd + 1
            );
        }
        return [];
    }, [applicantTeam, isScenarioDataLoading]);

    const areStaffingValuesLoaded: boolean = useMemo(() => {
        return !!Object.values(applicantTeam).length && !!Object.values(selectedScenarios).length;
    }, [applicantTeam, selectedScenarios]);

    return {
        areStaffingValuesLoaded,
        applicantTeam,
        selectedScenarios,
    };
};
