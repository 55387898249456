/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_API } from "../constant";
import { useCustomAlertContext } from "./CustomAlertContext";
import { Team } from "../interfaces/Team";

interface ScoreContextProps {
    gamedayScores: any;
    lastUpdate: Date;
    stateHasError: boolean;
}

export const ScoreContext = createContext<ScoreContextProps>({
    gamedayScores: {},
    lastUpdate: new Date(),
    stateHasError: false,
});

export const useScoreContext = () => useContext(ScoreContext);

export const ScoreContextProvider: React.FC = ({ children }) => {
    const [scores, setScores] = useState<any>(false);
    const [gamedayScores, setGamedayScores] = useState<any>(false);
    const [lastUpdate, setLastUpdate] = useState<Date>(new Date());
    const [stateHasError, setStateHasError] = useState<any>(false);

    const fetchData = () => {
        axios
            .get(`${BACKEND_API}/teams?usecase=Gameday`)
            .then((teamsResponse) => {
                axios.get(`${BACKEND_API}/scores`).then((scoresResponse) => {
                    setScores(scoresResponse.data);
                    setLastUpdate(new Date());
                    let updatedGamedayScores: any = {};
                    teamsResponse.data.forEach((team: Team) => {
                        if (scores[team.name]) {
                            team.scores = scores[team.name];
                        } else {
                            team.scores = [];
                        }
                        updatedGamedayScores[team.accountId] = team;
                    });
                    setGamedayScores(updatedGamedayScores);
                });
            })
            .catch((error) => {
                setStateHasError(true);
                console.error(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const timer = setTimeout(async () => {
            fetchData();
        }, 10000);
        return () => {
            clearTimeout(timer);
        };
    });

    const { showCustomAlert } = useCustomAlertContext();

    useEffect(() => {
        if (stateHasError) {
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "impossible de récupérer l'état de jeu du Cloud-Challenge."
            );
        }
    }, [stateHasError]);

    return (
        <ScoreContext.Provider
            value={{
                gamedayScores,
                lastUpdate,
                stateHasError,
            }}
        >
            {children}
        </ScoreContext.Provider>
    );
};
