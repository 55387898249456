import React, { useEffect, useState } from "react";
import axios from "axios";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import leftSideEvent from "../../assets/left_side_event.svg";

// Common
import { Event } from "./Event";
import { EventSetup } from "./EventSetup";
import Copyright from "../../common/Copyright";
import { BACKEND_API } from "../../constant.js";
import { useScenarioDataContext } from "../../context/ScenarioDataContext";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    content: {
        height: "100%",
        display: "flex",
    },
    image: {
        position: "sticky",
        left: 0,
    },
    left: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    right: {
        flexGrow: 1,
        overflowY: "auto",
    },
    tabs: {
        margin: theme.spacing(10),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

export const DashboardEvent: React.FC = () => {
    useDocumentTitle("Cloud Game - Ippon");

    const classes = useStyles();
    const storedApplicant = sessionStorage.getItem("applicant");
    const [applicant, setApplicant] = useState<string>(storedApplicant || "");
    const [account, setAccount] = useState<any>({});
    const [scenarios, setScenarios] = useState<any>({});
    const [backdrop, setBackdrop] = useState<boolean>(false);

    const { scenarioData, isScenarioDataLoading } = useScenarioDataContext();

    const openBackdrop = () => {
        setBackdrop(true);
    };

    const closeBackdrop = () => {
        setBackdrop(false);
    };

    useEffect(() => {
        if (applicant !== "") {
            openBackdrop();
            sessionStorage.setItem("applicant", applicant);
            axios
                .get(`${BACKEND_API}/teams/${applicant}`)
                .then((res) => {
                    setAccount(res.data.team);
                })
                .catch((err) => {
                    sessionStorage.removeItem("applicant");
                    closeBackdrop();
                });
        }
        // eslint-disable-next-line
    }, [applicant]);

    useEffect(() => {
        if (applicant !== "" && Object.values(account).length !== 0 && !isScenarioDataLoading) {
            const levelCandidate = account.levelCandidate;
            const stepStart = account.scenarioStepStart;
            const stepEnd = account.scenarioStepEnd;
            const scenariosLevel = Object.values(scenarioData[levelCandidate])[0] as any;
            const scenariosCandidate = scenariosLevel.slice(stepStart, stepEnd + 1);
            setScenarios(scenariosCandidate);
            closeBackdrop();
        }
        closeBackdrop();
        // eslint-disable-next-line
    }, [account]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            {Object.values(account).length === 0 ? (
                <main className={classes.content}>
                    <div className={classes.left}>
                        <img className={classes.image} src={leftSideEvent} alt="left_side" />
                    </div>
                    <div className={classes.right}>
                        <div className={classes.tabs}>
                            <EventSetup setApplicant={setApplicant} />
                        </div>
                        <Copyright />
                    </div>
                </main>
            ) : (
                <Event account={account} scenarios={scenarios} />
            )}
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
