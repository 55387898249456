import React from "react";
import { useScoreContext } from "../../../context/ScoreContext";
import { createStyles, makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { GamedayLegendChip } from "../GamedayLegendChip";
import { sortByAlphabeticalOrder } from "../../../common/helpers";

const useStyles = makeStyles((theme) => ({
    legend: {
        marginLeft: "auto",
        marginRight: theme.spacing(5),
        fontSize: 13,
    },
    legendBulletPoint: {
        width: 12,
        height: 12,
        borderRadius: "100%",
    },
}));

const CustomInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 200,
            "label + &": {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            fontSize: 14,
            position: "relative",
            display: "flex",
            gap: theme.spacing(2),
            padding: theme.spacing(1, 2),
            transition: theme.transitions.create(["all"]),
            "&:focus": {
                boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
            },
        },
    })
)(InputBase);

interface ScoreCustomLegendProps {
    handleClick: (name: string) => () => void;
    selectedTeams: string[];
}

export const ScoreCustomLegend: React.FC<ScoreCustomLegendProps> = ({
    handleClick,
    selectedTeams,
}) => {
    const classes = useStyles();
    const { gamedayScores } = useScoreContext();

    return (
        <div className={classes.legend}>
            <Select
                labelId="demo-mutiple-name-label"
                id="demo-mutiple-name"
                multiple
                value={selectedTeams}
                input={<CustomInput />}
                renderValue={() => {
                    switch (selectedTeams.length) {
                        case 0:
                            return "Sélectionnez une équipe";
                        case 1:
                            return `${selectedTeams.length} équipe sélectionnée`;
                        default:
                            return `${selectedTeams.length} équipes sélectionnées`;
                    }
                }}
                displayEmpty={true}
                MenuProps={{
                    PaperProps: {
                        style: {
                            padding: "8px 16px",
                            maxHeight: 280,
                            overflowY: "auto",
                        },
                    },
                }}
            >
                {Object.values(gamedayScores)
                    .sort(sortByAlphabeticalOrder)
                    .map((value: any) => (
                        <GamedayLegendChip
                            key={`legend-${value.name}`}
                            name={value.name}
                            color={value.color}
                            handleClick={handleClick(value.accountId)}
                            isChecked={selectedTeams.includes(value.accountId)}
                        />
                    ))}
            </Select>
        </div>
    );
};
