import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { ButtonBase } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    navigationItem: {
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        gap: theme.spacing(2),
        alignItems: "center",
        marginBottom: theme.spacing(0.75),
        width: "100%",
        minHeight: 48,
        padding: theme.spacing(1, 2),
        textAlign: "left",
        color: "#d1d5db",
        cursor: "pointer",
        backgroundColor: "transparent",
        borderRadius: 6,
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        textDecoration: "none",
        "&:focus-visible": {
            outline: "2px solid #fff",
        },
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
        "& > .MuiTypography-root": {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.25,
        },
    },
    activeNavigationItem: {
        color: "#6192F4",
        backgroundColor: "rgba(255, 255, 255, 0.08)",
        "& > .MuiTypography-root": {
            fontWeight: 500,
        },
    },
}));

interface NavigationItemProps {
    label: string | ReactElement;
    icon: any;
    onClick?: (e: any) => void;
    href?: string;
}

export const GamedayNavigationItem: React.FC<NavigationItemProps> = ({
    label,
    icon,
    onClick,
    href,
}) => {
    const classes = useStyles();

    if (href) {
        return (
            <NavLink
                role="tab"
                to={href}
                onClick={onClick}
                className={({ isActive }) =>
                    clsx(classes.navigationItem, isActive && classes.activeNavigationItem)
                }
            >
                {({ isActive }) => (
                    <React.Fragment>
                        {icon}
                        <Typography aria-current={isActive ? "page" : false} component="div">
                            {label}
                        </Typography>
                    </React.Fragment>
                )}
            </NavLink>
        );
    } else {
        return (
            <ButtonBase
                role="tab"
                onClick={onClick}
                aria-current={false}
                className={classes.navigationItem}
            >
                {icon}
                <Typography component="div">{label}</Typography>
            </ButtonBase>
        );
    }
};
