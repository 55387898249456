import { CognitoUserPool } from "amazon-cognito-identity-js";
import { COGNITO_USER_POOL_ID_TEAM, COGNITO_CLIENT_ID_TEAM } from "../../constant";

interface PoolData {
    UserPoolId: string;
    ClientId: string;
}
const poolData: PoolData = {
    UserPoolId: COGNITO_USER_POOL_ID_TEAM,
    ClientId: COGNITO_CLIENT_ID_TEAM,
};

export default new CognitoUserPool(poolData);
