import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import axios from "axios";
import { PRIVATE_KEY } from "./constant.js";
import jwt from "jsonwebtoken";
import PlayerRoutes from "./PlayerRoutes";
import AdminRoutes from "./AdminRoutes";
import { keycloak, keycloakProviderInitConfig } from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { KEYCLOAK_CLIENT_ID } from "./constant";
import HomePage from "./home/HomePage";
import ScrollToTop from "./hooks/ScrollToTop";

const AppRouter: React.FC = () => {
    const [isAuthSet, setIsAuthSet] = useState<any>(false);

    useEffect(() => {
        setAuth();
    }, []);

    const setAuth = () => {
        const token = jwt.sign({ gameday: "admin" }, PRIVATE_KEY);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
        setIsAuthSet(true);
    };

    return (
        <Router>
            <ScrollToTop />
            <main>
                {isAuthSet && (
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/player/*" element={<PlayerRoutes />} />
                        <Route
                            path="/admin/*"
                            element={
                                KEYCLOAK_CLIENT_ID !== "" ? (
                                    <ReactKeycloakProvider
                                        authClient={keycloak}
                                        initOptions={keycloakProviderInitConfig}
                                    >
                                        <AdminRoutes />
                                    </ReactKeycloakProvider>
                                ) : (
                                    <AdminRoutes />
                                )
                            }
                        />
                        <Route path="*" element={<Navigate to="/" replace={true} />} />
                    </Routes>
                )}
            </main>
        </Router>
    );
};

export default AppRouter;
