import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import UpdateIcon from "@material-ui/icons/Update";
import { Chip, Tooltip } from "@material-ui/core";
import logo from "../../assets/logo_blue.svg";
import { useScoreContext } from "../../context/ScoreContext";
import { drawerWidth, headerHeight } from "./GamedayDashboardLayout";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: drawerWidth,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(6px)",
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        fontSize: 40,
        color: theme.palette.primary.main,
    },
    toolbar: {
        height: headerHeight,
        paddingRight: theme.spacing(5),
    },
    logo: {
        paddingTop: 6,
        width: 120,
        marginRight: theme.spacing(2),
    },
    chip: {
        marginLeft: "auto",
    },
}));

export const GamedayHeaderBar: React.FC = () => {
    const classes = useStyles();
    const { lastUpdate } = useScoreContext();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <img className={classes.logo} src={logo} alt="IPPON" />
                <Typography variant="h2" className={classes.title}>
                    GAMEDAY
                </Typography>
                <Tooltip title="La console se met à jour toutes les 10 secondes" arrow>
                    <Chip
                        color="primary"
                        className={classes.chip}
                        icon={<UpdateIcon color="primary" />}
                        label={`Dernière mise à jour : ${lastUpdate.toLocaleTimeString()}`}
                        variant="outlined"
                    />
                </Tooltip>
            </Toolbar>
        </AppBar>
    );
};
