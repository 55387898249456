import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ChipRadioInput from "./ChipRadioInput";

const useStyles = makeStyles((theme) => ({
    marginBottom: {
        marginBottom: theme.spacing(4),
        "& > *": {
            marginBottom: theme.spacing(0.5),
        },
    },
    radioGroup: {
        display: "flex",
        gap: theme.spacing(2),
        paddingTop: theme.spacing(0.25),
    },
}));

export interface ChipRadioInterface {
    value: string;
    label: string;
}
interface ChipRadioFormGroupProps {
    title: string;
    inputName: string;
    helper?: string;
    formValue: string;
    handleInputChange: () => void;
    chipInputValues: ChipRadioInterface[];
}

const ChipRadioFormGroup: React.FC<ChipRadioFormGroupProps> = ({
    title,
    helper,
    inputName,
    chipInputValues,
    formValue,
    handleInputChange,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.marginBottom}>
            <Typography color="textPrimary" variant="h4">
                {title}
            </Typography>
            {helper && (
                <Typography color="textSecondary" variant="subtitle1">
                    {helper}
                </Typography>
            )}
            <div className={classes.radioGroup}>
                {chipInputValues.map((chipInput) => (
                    <ChipRadioInput
                        key={`chip-${inputName}-${chipInput.value}`}
                        isChecked={formValue === chipInput.value}
                        value={chipInput.value}
                        label={chipInput.label}
                        onChange={handleInputChange}
                        name={inputName}
                    />
                ))}
            </div>
        </div>
    );
};

export default ChipRadioFormGroup;
