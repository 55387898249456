import { REGION } from "../constant";
import { Provider } from "../interfaces/Types";

export const getTeams = (state: any) => Object.values(state);

export const getTeamsAccountIds = (state: any) => Object.keys(state);

export const nothingToDisplay = (state: any) => getTeamsAccountIds(state).length === 0;

export const getHoursMinutesFormat = (date: Date) =>
    `${date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;

export const convertInMinutes = (seconds: number) => Math.round(seconds / 60);

export const findPreviousScore = (dataSet: any[], timestamp: any) => {
    const previousIndex =
        dataSet.findIndex((e: { timestamp: any }) => e.timestamp === timestamp) - 1;
    return previousIndex < 0 ? undefined : dataSet[previousIndex];
};

export const getLastProfit = (team: { scores: any[] }) => {
    if (!team.scores.length || !team.scores[team.scores.length - 1].profit) return -Infinity;

    return Math.round(team.scores[team.scores.length - 1].profit);
};

export const getLastItemsOfArray = (array: any[], n: number) =>
    array.slice(Math.max(array.length - n, 0));

export const downloadCredentials = (
    cloudProvider: Provider,
    accountId: string,
    teamName: string,
    password: string,
    accessKey: any
) => {
    const awsData = `AWS Console Link: https://${accountId}.signin.aws.amazon.com/console\n
Account ID: ${accountId}\n
Username: ${teamName}\n
Region: ${REGION}\n
Password: ${password}\n
Access Key ID: ${accessKey.AccessKeyId}\n
Access Secret Key: ${accessKey.SecretAccessKey}`;

    const azureData = `Azure Portal Link: https://portal.azure.com/signin/index/ \n
Mail adress: ${accountId}\n
Username: ${teamName}\n
Region: ${REGION}\n
Password: ${password}\n
Client ID: ${accessKey.AccessKeyId}\n
Client Secret: ${accessKey.SecretAccessKey}`;

    const file = new Blob([cloudProvider === "AWS" ? awsData : azureData], { type: "text/plain" });
    const a = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = `credentials_${teamName}.txt`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
};

export const sortByAlphabeticalOrder = (a: any, b: any) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
};

export const getLastScore = (scores: any[]) => {
    const index = scores.length - 1;
    return index < 0 ? 0 : scores[index].profit;
};
