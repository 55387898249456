import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { ButtonBase, Typography } from "@material-ui/core";
import Radio, { RadioProps } from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
    teamSelector: {
        display: "flex",
        justifyContent: "center",
        paddingRight: theme.spacing(1.5),
        cursor: "pointer",
        borderRadius: 6,
        backgroundColor: "#809eee21",
        border: "1px solid transparent",
        opacity: 0.5,
        transition: theme.transitions.create(["all"]),
        "&:focus-visible": {
            outline: `2px solid ${theme.palette.primary.main}`,
        },
        "& > .MuiTypography-root": {
            fontSize: 14,
            textTransform: "capitalize",
        },
    },
    teamSelected: {
        opacity: 1,
        backgroundColor: "#00cca521",
        border: "1px solid #00cca5",
        transition: theme.transitions.create(["all"]),
    },
}));

const GreenRadio = withStyles({
    root: {
        color: "#00cca5",
        width: 32,
        height: 32,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
})((props: RadioProps) => (
    <Radio disabled={!props.checked} size="small" color="default" {...props} />
));

interface PieChartTeamSelectorProps {
    teamName: string;
    isSelected: boolean;
    onClick: () => void;
}

export const PieChartTeamSelector: React.FC<PieChartTeamSelectorProps> = ({
    teamName,
    isSelected,
    onClick,
}) => {
    const classes = useStyles();
    return (
        <ButtonBase
            onClick={onClick}
            className={clsx(classes.teamSelector, isSelected && classes.teamSelected)}
        >
            <GreenRadio checked={isSelected} />
            <Typography>{teamName}</Typography>
        </ButtonBase>
    );
};
