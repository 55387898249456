import React, { useState } from "react";
import axios from "axios";

import { Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";

import { BACKEND_API } from "../../../constant";
import Input from "../../../common/Input";
import ModalStaff from "../../../common/ModalStaff";

const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(0, 0, 3, 0),
    },
    end: {
        display: "flex",
        justifyContent: "right",
    },
    annuler: {
        marginRight: theme.spacing(3),
    },
    typoEnd: {
        display: "flex",
        justifyContent: "right",
        marginTop: theme.spacing(2),
    },
}));

interface UpdateEntrypointProps {
    openUpdateTeam: any;
    handleCloseUpdateTeam: any;
    password: any;
}
const UpdateEntrypoint: React.FC<UpdateEntrypointProps> = ({
    openUpdateTeam,
    handleCloseUpdateTeam,
    password,
}) => {
    const classes = useStyles();

    const DEFAULT_URL_STARTER = "http://";
    const [masterPassword, setMasterPassword] = useState(password);

    const [url, setUrl] = useState(DEFAULT_URL_STARTER);

    const handleSubmitUpdateTeam: any = () => {
        const data = { masterPassword, url };
        axios
            .patch(`${BACKEND_API}/teams/entrypoint`, data)
            .then(() => {
                setUrl(DEFAULT_URL_STARTER);
                handleCloseUpdateTeam();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <ModalStaff
            open={openUpdateTeam}
            setOpen={handleCloseUpdateTeam}
            title="CHANGER L'ENTRYPOINT"
            style={{ margin: "300px" }}
        >
            <React.Fragment>
                <Typography gutterBottom color="textPrimary" variant="h4">
                    Master Password
                </Typography>
                <Input
                    className={classes.input}
                    id="SecretAccessKey"
                    type="password"
                    value={masterPassword}
                    onChange={(e: any) => setMasterPassword(e.target.value)}
                    fullWidth
                    name={""}
                />
                <Typography gutterBottom color="textPrimary" variant="h4">
                    URL Entrypoint
                </Typography>
                <Input
                    className={classes.input}
                    margin="dense"
                    id="url"
                    type="url"
                    value={url}
                    onChange={(e: any) => setUrl(e.target.value)}
                    fullWidth
                    name={""}
                />

                <div className={classes.end}>
                    <Button
                        className={classes.annuler}
                        disableElevation
                        variant="outlined"
                        color="primary"
                        onClick={handleCloseUpdateTeam}
                        startIcon={<CloseIcon />}
                    >
                        Annuler
                    </Button>
                    <Button
                        className={classes.annuler}
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitUpdateTeam}
                        startIcon={<CheckIcon />}
                    >
                        Mettre à jour
                    </Button>
                </div>
            </React.Fragment>
        </ModalStaff>
    );
};

export default UpdateEntrypoint;
