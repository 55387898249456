import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#003CDC",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#FFC800",
            contrastText: "#000000",
        },
        error: {
            main: "#FF4C41",
        },
        text: {
            primary: "#000F41",
            secondary: "#747490",
        },
        background: {
            default: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: ["Roboto", '"Saira Extra Condensed"'].join(","),
        h2: {
            fontSize: "50px",
            fontFamily: "Saira Extra Condensed",
            fontWeight: 400,
        },
        h3: {
            fontSize: 22,
            fontWeight: 700,
        },
        h4: {
            fontSize: 15,
            fontWeight: 500,
        },
        h5: {
            fontSize: 15,
            fontWeight: 700,
        },
        body1: {
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: 12,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: "none",
                borderRadius: 0,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 0,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
                "&$selected": {
                    backgroundColor: "#FFC800",
                },
            },
        },
        MuiTabs: {
            indicator: {
                height: 0,
            },
        },
        MuiDivider: {
            middle: {
                background: "#FFC800",
                maxWidth: "80px",
                height: "2px",
                margin: "auto",
                marginLeft: "none",
                marginRight: "none",
            },
        },
        MuiInputBase: {
            root: {
                background: "#F0F4FB",
            },
        },
        MuiStepper: {
            root: {
                background: "#F4F4F4",
            },
        },
        MuiToolbar: {
            regular: {
                minHeight: "70px !important",
            },
        },
        MuiAccordion: {
            root: {
                borderRadius: 0,
                boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
                backgroundColor: "#ffffff",
                paddingLeft: "32px",
                paddingRight: "32px",
                paddingTop: "15px",
                paddingBottom: "15px",
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: 0,
                margin: 0,
            },
        },
        MuiCard: {
            root: {
                borderRadius: 0,
                boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
                backgroundColor: "#ffffff",
                paddingLeft: "32px",
                paddingRight: "32px",
                paddingTop: "15px",
                paddingBottom: "15px",
            },
        },
    },
    props: {
        MuiAppBar: {
            elevation: 0,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiPaper: {
            elevation: 0,
        },
    },
});
