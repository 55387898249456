import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { StaffSetup } from "./StaffSetup";
import { ExistingStaff } from "./ExistingStaff";
import { Slide } from "@material-ui/core";

const useStyles = makeStyles((_theme) => ({
    tabpanel: {
        boxShadow: "0px 0px 74px rgba(0, 60, 220, 0.05)",
    },
}));

interface TabPanelProps {
    value: any;
    index: any;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, index, children, ...props }) => {
    const classes = useStyles();
    return (
        <div
            className={classes.tabpanel}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...props}
        >
            {value === index && children}
        </div>
    );
};
export const TabSession: React.FC = () => {
    const [value, setValue] = useState<number>(0);

    const handleChange = (_event: any, newValue: number) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Tabs value={value} onChange={handleChange}>
                <Tab label="Nouvelle session" />
                <Tab label="Session existante" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Slide in={value === 0} direction="left">
                    <div>
                        <StaffSetup />
                    </div>
                </Slide>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Slide in={value === 1} direction="right">
                    <div>
                        <ExistingStaff createNewSession={handleChange} />
                    </div>
                </Slide>
            </TabPanel>
        </React.Fragment>
    );
};
