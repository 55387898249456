import React from "react";
// import axios from 'axios';
// import { BACKEND_API } from '../constant';
// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider } from "@material-ui/core";
import Input from "../../common/Input";
import { Form } from "../../common/useForms";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";
//Cognito
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../helpers/UserPool";
import { PRIVATE_KEY } from "../../constant";
import { useForm } from "../../hooks/useForm";
import { useCustomAlertContext } from "../../context/CustomAlertContext";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
        backgroundColor: "#FFFFFF",
    },
    title: {
        textAlign: "center",
    },
    body: {
        textAlign: "left",
        paddingTop: theme.spacing(5),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
    block: {
        marginBottom: theme.spacing(2),
    },
    form: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.primary,
    },
    button: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.primary,
    },
}));

export const GamedayLogin: React.FC<{ setToken: React.Dispatch<React.SetStateAction<string>> }> = ({
    setToken,
}) => {
    const classes = useStyles();

    useDocumentTitle("Cloud Challenge - Connexion");

    const modelObject = {
        username: "",
        password: "",
    };

    const { values, handleInputChange, resetForm } = useForm(modelObject);
    const { showCustomAlert } = useCustomAlertContext();

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        showCustomAlert("info", "Infos", "Connexion en cours ...");
        const user = new CognitoUser({
            Username: values.username,
            Pool: UserPool,
        });
        const authDetails = new AuthenticationDetails({
            Username: values.username,
            Password: values.password,
        });
        user.authenticateUser(authDetails, {
            onSuccess: () => {
                showCustomAlert("success", "Succès", "Connexion avec succès");
                setToken(PRIVATE_KEY);
                resetForm();
            },
            onFailure: (err) => {
                console.error("onFailure:", err);
                switch (err.code) {
                    case "NotAuthorizedException":
                        showCustomAlert(
                            "error",
                            "Erreur",
                            "Nom d'utilisateur ou mot de passe incorrect",
                        );
                        break;
                    default:
                        showCustomAlert("error", "Erreur", err.message);
                        break;
                }
            },
            newPasswordRequired: (data) => {
                showCustomAlert("error", "Erreur", data.message);
                console.log("newPasswordRequired");
            },
        });
    };

    return (
        <Card className={classes.root}>
            <div className={classes.title}>
                <Typography color="primary" variant="h2">
                    <b>BIENVENUE</b>
                    <br />
                    SUR LE GAMEDAY
                </Typography>
                <Divider variant="middle" className={classes.divider} />
            </div>
            <Form className={classes.form} onSubmit={handleSubmit}>
                <Box component="span" className={classes.block} display="block">
                    <Typography color="error" variant="subtitle1">
                        Tous les champs sont obligatoires.
                    </Typography>
                </Box>
                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Nom d'utilisateur Gameday
                    </Typography>
                    <Input name="username" value={values.username} onChange={handleInputChange} />
                </Box>
                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Mot de passe Gameday
                    </Typography>
                    <Input
                        name="password"
                        type="password"
                        value={values.password}
                        onChange={handleInputChange}
                    />
                </Box>
                <div className={classes.button}>
                    <Button disableElevation variant="contained" color="primary" type="submit">
                        <Grid container direction="row" alignItems="center">
                            Accèder au GameDay
                            <ArrowForwardIcon style={{ marginLeft: "10px" }} fontSize="small" />
                        </Grid>
                    </Button>
                </div>
            </Form>
        </Card>
    );
};
