/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "@material-ui/core";
import { Role } from "../../interfaces/Types";
import { Cytoscape } from "./Cytoscape";
import Skeleton from "@material-ui/lab/Skeleton";
import { useFetch } from "../../hooks/useFetch";
import { BACKEND_API } from "../../constant";
import { headerHeight } from "../../player/gameday/GamedayDashboardLayout";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left",
    },
    head: {
        marginBottom: theme.spacing(2),
        display: "flex",
    },
    title: {
        fontSize: "22px",
    },
    typography: {
        fontSize: "15px",
        marginBottom: theme.spacing(2),
    },
    arrow: {
        marginLeft: "auto",
        cursor: "pointer",
    },
}));
interface NetworkWatcherProps {
    role: Role;
    applicantId: string;
}

export const NetworkWatcher: React.FC<NetworkWatcherProps> = ({ role, applicantId }) => {
    const classes = useStyles();
    const [
        netWatcherData,
        _netWatcherDataIsLoading,
        _netWatcherDataHasError,
        refetchNetWatcherData,
    ] = useFetch(`${BACKEND_API}/teams/${applicantId}/networkWatcher`);

    useEffect(() => {
        const timer = setTimeout(async () => {
            refetchNetWatcherData();
        }, 180000);
        return () => {
            clearTimeout(timer);
        };
    });

    const enumStyle: { [role in Role]: React.CSSProperties } = {
        admin: {},
        player: { position: "sticky", top: headerHeight + 16 },
    };

    const enumTitle: { [role in Role]: string } = {
        admin: "Architecture Azure du candidat",
        player: "Votre architecture Azure",
    };

    return (
        <Card style={enumStyle[role]}>
            <CardContent className={classes.root}>
                <div className={classes.head}>
                    <Typography color="primary" className={classes.title}>
                        <b>{enumTitle[role]}</b>
                    </Typography>
                </div>
                <Typography className={classes.typography}>
                    Le visualiseur se rafraîchit automatiquement toutes les 3 minutes. Si vous
                    souhaitez le rafraichir{" "}
                    <Link style={{ cursor: "pointer" }} onClick={refetchNetWatcherData}>
                        cliquez ici.
                    </Link>
                </Typography>
                {!netWatcherData.resources?.length ? (
                    <Skeleton animation="wave" variant="rect" style={{ height: 280 }} />
                ) : (
                    <Cytoscape data={netWatcherData} />
                )}
            </CardContent>
        </Card>
    );
};
