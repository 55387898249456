import React from "react";
import { Alert, AlertProps, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    alert: {
        margin: theme.spacing(3, 0, 3, 0),
    },
}));

interface CustomAlertProps {
    title: string;
    severity: AlertProps["severity"];
    message?: string;
    onClose?: AlertProps["onClose"];
}

const CustomAlert: React.FC<CustomAlertProps> = ({
    title,
    severity,
    message,
    onClose,
    children,
}) => {
    const classes = useStyles();
    return (
        <Alert className={classes.alert} onClose={onClose} severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {message}
            {children}
        </Alert>
    );
};

export default CustomAlert;
