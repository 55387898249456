import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
// Common
import { StaffSetup } from "../player/staffing/StaffSetup";
import { TabSession } from "../admin/staffing/TabSession";
import Copyright from "./Copyright";
import leftSideStaffing from "../assets/left_side_staffing.svg";
import { AdminStaff } from "../admin/staffing/AdminStaff";
import { PlayerStaff } from "../player/staffing/PlayerStaff";
import backgroundImage from "../assets/bg.svg";
import { Route, Routes } from "react-router-dom";
import { Role } from "../interfaces/Types";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    content: {
        height: "100%",
        display: "flex",
    },
    left: {
        // Image MUST BE 100% of screen height and stuck to the left
        // As its aspect ratio is 508/900 = 56.4%, the with of its container muste be 57vh
        width: "57vh",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    image: {
        position: "fixed",
        left: 0,
        height: "100vh",
    },
    right: {
        flexGrow: 1,
        overflowY: "auto",
        overflowX: "hidden",
    },
    tabs: {
        margin: theme.spacing(5),
    },
    backgroundImage: {
        position: "fixed",
        zIndex: -1,
        width: "40vw",
        height: "40vw",
        right: 0,
        top: 0,
        transform: "scaleY(-1)",
    },
}));

interface DashboardStaffingProps {
    role: Role;
}

export const DashboardStaffing: React.FC<DashboardStaffingProps> = ({ role }) => {
    const classes = useStyles();

    const enumRoleContent: any = {
        player: {
            setupComponent: <StaffSetup />,
            staffComponent: <PlayerStaff />,
        },
        admin: {
            setupComponent: <TabSession />,
            staffComponent: <AdminStaff />,
        },
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Routes>
                <Route
                    path=""
                    element={
                        <div className={classes.content}>
                            <div className={classes.left}>
                                <img
                                    className={classes.image}
                                    src={leftSideStaffing}
                                    alt="Bienvenue sur l'espace recrutement du Cloud Challenge"
                                />
                            </div>
                            <div className={classes.right}>
                                <div className={classes.tabs}>
                                    {enumRoleContent[role].setupComponent}
                                </div>
                                <Copyright />
                            </div>
                        </div>
                    }
                />
                <Route path=":applicantName" element={enumRoleContent[role].staffComponent} />
            </Routes>
            <img src={backgroundImage} alt="backgroundImage" className={classes.backgroundImage} />
        </div>
    );
};
