import React, { useState, useEffect } from "react";
import { Typography, Card, MobileStepper, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ScenarioReadMe from "../../../common/ScenarioReadMe";
import bravo from "../../../assets/bravo.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "left",
    },
    stepper: {
        textAlign: "center",
        justifyContent: "center",
    },
    title: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(3, 0, 3, 0),
    },
    start: {
        justifyContent: "center",
    },
    previous: {
        justifyContent: "left",
    },
    next: {
        justifyContent: "right",
        marginLeft: "auto",
    },
}));

export const ScenarioCard: React.FC<{ scenarios: any }> = ({ scenarios }) => {
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState<number>(0);
    const [scenariosCandidate, setScenariosCandidate] = useState<any[]>([]);

    useEffect(() => {
        setScenariosCandidate(scenarios);
    }, [scenarios]);

    const handleNext = () => {
        if (activeStep <= scenariosCandidate.length - 1)
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Card className={classes.root}>
            <Typography className={classes.title} color="primary" variant="h3">
                Scénario à réaliser
            </Typography>
            <Divider className={classes.divider} />

            <Box>
                {activeStep < Object.values(scenariosCandidate).length ? (
                    <Box>
                        <Typography
                            style={{ textAlign: "center" }}
                            color="textPrimary"
                            variant="h3"
                        >
                            Étape {activeStep + 1}: {scenariosCandidate[activeStep].description}{" "}
                        </Typography>
                        <Divider className={classes.divider} />
                        <ScenarioReadMe
                            id={scenariosCandidate[activeStep].id}
                            rules={true}
                            userDataUrl={scenariosCandidate[activeStep].userData}
                            showUserData={false}
                            gameday={undefined}
                        />
                    </Box>
                ) : (
                    <Box>
                        <Box display="flex" justifyContent="center">
                            <img src={bravo} alt="bravo" />
                        </Box>
                        <Box display="flex" justifyContent="center" style={{ marginTop: "25px" }}>
                            <Typography color="primary" variant="h3">
                                Bravo !
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Typography color="primary" variant="h3">
                                Tu viens de terminer la session de test !
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" style={{ marginTop: "25px" }}>
                            <Typography>
                                N’hésites pas à vérifier ton infrastructure via le visualiseur avant
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Typography>
                                de cliquer sur “Terminer la session” en haut de page.
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            style={{ marginBottom: "50px" }}
                        >
                            <Typography>
                                <b>Merci d’avoir pris le temps de passer cet entretien !</b>
                            </Typography>
                        </Box>
                    </Box>
                )}
                {activeStep === 0 ? (
                    <Box display="flex" justifyContent="center">
                        <Button
                            disableElevation
                            className={classes.start}
                            color="primary"
                            variant="contained"
                            onClick={handleNext}
                            endIcon={<ArrowForwardIcon />}
                        >
                            Commencer
                        </Button>
                    </Box>
                ) : null}
                {activeStep < scenariosCandidate.length && activeStep > 0 ? (
                    <Box display="flex">
                        <Button
                            disableElevation
                            className={classes.previous}
                            color="primary"
                            variant="outlined"
                            onClick={handleBack}
                            startIcon={<ArrowBackIcon />}
                        >
                            Etape précédente
                        </Button>
                        <Button
                            disableElevation
                            className={classes.next}
                            color="primary"
                            variant="contained"
                            onClick={handleNext}
                            endIcon={<ArrowForwardIcon />}
                        >
                            Etape suivante
                        </Button>
                    </Box>
                ) : null}
                {activeStep === scenariosCandidate.length ? (
                    <Box display="flex" justifyContent="center">
                        <Button
                            disableElevation
                            className={classes.previous}
                            color="primary"
                            variant="outlined"
                            onClick={handleBack}
                            startIcon={<ArrowBackIcon />}
                        >
                            Etape précédente
                        </Button>
                    </Box>
                ) : null}
                {Object.values(scenariosCandidate).length !== 0 ? (
                    <MobileStepper
                        variant="dots"
                        steps={scenariosCandidate.length + 1}
                        position="static"
                        activeStep={activeStep}
                        className={classes.stepper}
                        backButton={undefined}
                        nextButton={undefined}
                    />
                ) : null}
            </Box>
        </Card>
    );
};
