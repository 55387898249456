import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { GamedayDashboardLayout } from "./GamedayDashboardLayout";
import { GamedayLogin } from "./GamedayLogin";
import { PRIVATE_KEY } from "../../constant";
import Copyright from "../../common/Copyright";
import leftSideStaffing from "../../assets/left_side_staffing.svg";
import { Routes, Route, Navigate } from "react-router-dom";
import { GamedayScoresPage } from "./GamedayScoresPage";
import { GamedayRulesPage } from "./rules/GamedayRulesPage";
import { useGameConfigHook } from "../../hooks/useGameConfig";
import { GamedayScenarioPage } from "./GamedayScenarioPage";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        display: "flex",
    },
    left: {
        // Image MUST BE 100% of screen height and stuck to the left
        // As its aspect ratio is 508/900 = 56.4%, the with of its container muste be 57vh
        width: "57vh",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    image: {
        position: "fixed",
        left: 0,
        height: "100vh",
    },
    right: {
        flexGrow: 1,
        overflowY: "auto",
    },
    tabs: {
        margin: theme.spacing(5),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

/**
 * Displays Gameday dashboard for players.
 *
 * If user is not connected, it renders `GamedayLogin` on `/player/gameday` route.
 *
 * If user is conntected, it renders the gameday router.
 * The `/rules` and `/scores` routes are nested inside `GamedayDashboardLayout`, refer to https://reactrouterdotcom.fly.dev/docs/en/v6/getting-started/tutorial#nested-routes for more documentation.
 *
 * @return {JSX} Gameday dashboard if user is connected, gameday login else.
 */

export const DashboardGameday: React.FC = () => {
    const classes = useStyles();
    const storedApplicant = localStorage.getItem("token");
    const [token, setToken] = React.useState<string>(storedApplicant || "");

    const { gameConfig, gameConfigHasError, isGameConfigLoading } = useGameConfigHook();

    React.useEffect(() => {
        if (token !== "") {
            localStorage.setItem("token", token);
        }
        // eslint-disable-next-line
    }, [token, setToken]);

    return (
        <div>
            <CssBaseline />
            {token !== PRIVATE_KEY ? (
                <main className={classes.content}>
                    <div className={classes.left}>
                        <img className={classes.image} src={leftSideStaffing} alt="left_side" />
                    </div>
                    <div className={classes.right}>
                        <div className={classes.tabs}>
                            <GamedayLogin setToken={setToken} />
                        </div>
                        <Copyright />
                    </div>
                </main>
            ) : (
                <Routes>
                    <Route path="" element={<GamedayDashboardLayout />}>
                        <Route path="rules" element={<GamedayRulesPage />} />
                        <Route
                            path="scenario"
                            element={
                                <GamedayScenarioPage
                                    gameConfig={gameConfig}
                                    gameConfigHasError={gameConfigHasError}
                                    isGameConfigLoading={isGameConfigLoading}
                                />
                            }
                        />
                        <Route path="scores" element={<GamedayScoresPage />} />
                        <Route
                            path={"/"}
                            element={<Navigate to="/player/gameday/rules" replace={true} />}
                        />
                        <Route
                            path={"*"}
                            element={<Navigate to="/player/gameday/rules" replace={true} />}
                        />
                    </Route>
                </Routes>
            )}
        </div>
    );
};
