import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, Divider } from "@material-ui/core";
import { BACKEND_API } from "../../constant";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useCustomAlertContext } from "../../context/CustomAlertContext";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        margin: theme.spacing(1, 0, 2.5, 0),
    },
    textField: {
        marginBottom: theme.spacing(2),
        "& > .MuiInputBase-root": {
            backgroundColor: "#fff",
        },
        "& > .MuiOutlinedInput-root": {
            borderRadius: theme.spacing(0.5),
        },
    },
}));

interface UpdateTeamProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateTeam: React.FC<UpdateTeamProps> = ({ open, setOpen }) => {
    const classes = useStyles();

    const DEFAULT_URL_STARTER = "http://";
    const [masterPassword, setMasterPassword] = useState<string>("");
    const [url, setUrl] = useState<string>(DEFAULT_URL_STARTER);

    const { showCustomAlert } = useCustomAlertContext();

    const handleClose = () => {
        setMasterPassword("");
        setUrl(DEFAULT_URL_STARTER);
        setOpen(false);
    };

    const handleSubmitUpdateTeam = () => {
        const data = { masterPassword, url };
        axios
            .patch(`${BACKEND_API}/teams/entrypoint`, data)
            .then((response) => {
                if (response.data.status === 200) {
                    showCustomAlert("success", "Succès", "L'équipe a bel et bien été mise à jour.");
                } else {
                    showCustomAlert(
                        "error",
                        "Une erreur est survenue",
                        "Quelque chose s'est mal passé, veuillez réessayer."
                    );
                }
            })
            .catch((error) => {
                console.log(error);
                showCustomAlert(
                    "error",
                    "Une erreur est survenue",
                    "Quelque chose s'est mal passé, veuillez réessayer."
                );
            });
        handleClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Mettre à jour le point d'entrée</DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText variant="body2" className={classes.subtitle}>
                        Pour mettre à jour votre point d'entrée, merci d'entrer votre mot de passe
                        (celui du fichier credentials_[nom].txt) et votre nouvelle adresse IP.
                    </DialogContentText>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        autoFocus={true}
                        id="SecretAccessKey"
                        label="Mot de passe"
                        type="password"
                        value={masterPassword}
                        onChange={(e) => setMasterPassword(e.target.value)}
                        fullWidth
                        className={classes.textField}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        id="url"
                        label="Application URL"
                        type="url"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        fullWidth
                        className={classes.textField}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<CloseIcon />}>
                        Annuler
                    </Button>

                    <Button
                        onClick={handleSubmitUpdateTeam}
                        color="primary"
                        startIcon={<CheckIcon />}
                    >
                        Mettre à jour
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpdateTeam;
