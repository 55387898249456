import React from "react";
import Typography from "@material-ui/core/Typography";
import Title from "../../common/Title";
import LeaderIcon from "@material-ui/icons/EmojiEvents";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Lottie from "lottie-react";
import crownAnimation from "../../assets/crownAnimation.json";
import { SkeletonEmptyState } from "./charts/SkeletonEmptyState";

const useStyles = makeStyles((theme) => ({
    leaderContainer: {
        padding: theme.spacing(0, 1),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& > img": {
            width: 100,
            margin: "0 auto",
        },
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    leaderTitle: {
        fontSize: 22,
        fontWeight: "bold",
        textTransform: "capitalize",
    },
    crownAnimation: {
        height: "auto",
        width: "100%",
        maxWidth: 256,
        margin: "auto",
    },
}));

interface LeaderProps {
    leaderValues:
        | false
        | {
              name: string;
              profit: number;
          };
}
export const Leader: React.FC<LeaderProps> = ({ leaderValues }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Title>Leader</Title>
            {leaderValues ? (
                <div className={classes.leaderContainer}>
                    <Lottie
                        className={classes.crownAnimation}
                        loop
                        animationData={crownAnimation}
                    />
                    <div>
                        <Typography variant="caption">BALANCE</Typography>
                        <Typography className={classes.leaderTitle}>
                            $ {leaderValues.profit}
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography variant="caption">ÉQUIPE</Typography>
                        <Typography className={classes.leaderTitle}>{leaderValues.name}</Typography>
                    </div>
                </div>
            ) : (
                <SkeletonEmptyState
                    icon={<LeaderIcon />}
                    height={371}
                    label="Le leader du Cloud Challenge va appraître ici prochainement !"
                />
            )}
        </React.Fragment>
    );
};
