import React from "react";
import axios from "axios";
import { S3_SCENARIOS_URL } from "../../constant";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import CustomAlert from "../../common/CustomAlert";
import getScenarioData from "../../common/functions";

const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: "#EBF8FF",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    markdown: {
        textAlign: "left",
        marginBottom: theme.spacing(3),
        "& blockquote": {
            "& p": {
                marginBottom: "1em",
            },
        },
        "& p": {
            paddingBottom: "1em",
        },
        "& pre": {
            justifyContent: "left",
        },
        "& h2": {
            marginBottom: "8px",
        },
        "& ul": {
            paddingLeft: "16px",
            paddingBottom: "16px",
        },
    },
}));

interface ScenarioReadMeProps {
    id: any;
    rules: any;
}

const ScenarioReadMe: React.FC<ScenarioReadMeProps> = ({ id, rules }) => {
    const classes = useStyles();
    const [scenario, setScenario] = React.useState({});
    const [input, setInput] = React.useState("");

    const components = {
        p({
            node,
            inline,
            className,
            children,
            type,
            ...props
        }: {
            node: any;
            inline: string;
            className: string;
            children: any;
            type: string;
        }) {
            const match = children[0].type === "strong";
            return match ? (
                <CustomAlert
                    title={"Important"}
                    severity={"warning"}
                    message={children}
                    {...props}
                />
            ) : (
                <p children={children} {...props} />
            );
        },

        em: ({ node, ...props }: { node: any }) => <strong {...props} />,
        h3: "h4",
        blockquote: ({ node, ...props }: { node: any }) => (
            <Box className={classes.box} {...props} />
        ),
        img: ({ alt, src, title }: { alt: string; src: string; title: string }) => (
            <img alt={alt} src={src} title={title} style={{ maxWidth: 550 }} />
        ),
    };

    const getInput = async () => {
        try {
            const scenario = await getScenarioData(id);
            const instructionsUrl = rules === true ? scenario.rules : scenario.readme;

            if (scenario) {
                // Remove Authorization header in HTTP request
                const instructionsFile = await axios.get(instructionsUrl,{headers:{"Authorization":""}});
                const instructionsText = await instructionsFile.data;

                setScenario(scenario);
                setInput(instructionsText);
            }
        } catch (err) {
            console.error("Problem reading markdown file", err);
        }
    };

    const buildImageUri = (scenario: any, image: any) =>
        image.startsWith("http")
            ? image
            : `${S3_SCENARIOS_URL}/${scenario.name}/${scenario.path}/${image}`;

    React.useEffect(() => {
        getInput();
        // eslint-disable-next-line
    }, [id]);

    return (
        <ReactMarkdown
            className={classes.markdown}
            components={components}
            remarkPlugins={[gfm, breaks]}
            children={input}
            transformImageUri={(image: any) => buildImageUri(scenario, image)}
        />
    );
};
export default ScenarioReadMe;
