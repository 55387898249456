/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";

export const useFetch = (fetchUrl: string) => {
    const [data, setData] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [refetchIndex, setRefetchIndex] = useState<number>(0);

    const refetch = () => setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(fetchUrl)
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setHasError(true);
                console.error(error);
                setIsLoading(false);
            });
    }, [refetchIndex]);

    return [data, isLoading, hasError, refetch];
};
