import React, { Dispatch, FormEvent, SetStateAction } from "react";
import axios from "axios";
import { BACKEND_API } from "../../constant";
// Material UI
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Grid from "@material-ui/core/Grid";

import Input from "../../common/Input";
import { useForm } from "../../hooks/useForm";
import { Form } from "../../common/useForms";
import { useCustomAlertContext } from "../../context/CustomAlertContext";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(5),
        backgroundColor: "#FFFFFF",
    },
    title: {
        textAlign: "center",
    },
    body: {
        textAlign: "left",
        paddingTop: theme.spacing(5),
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
    block: {
        marginBottom: theme.spacing(3),
    },
    inline: {
        marginRight: theme.spacing(10),
    },
    form: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.primary,
    },
    button: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.primary,
    },
}));

export const EventSetup: React.FC<{ setApplicant: Dispatch<SetStateAction<string>> }> = ({
    setApplicant,
}) => {
    const classes = useStyles();

    const modelObject = {
        lastname: "",
        firstname: "",
        username: "",
        email: "",
    };

    const checkForm = (values: any) => {
        let formIsValid = true;
        if (values.email === "") {
            formIsValid = false;
            showCustomAlert("error", "Erreur", "Email obligatoire");
        }

        return formIsValid;
    };

    const { values, handleInputChange, resetForm } = useForm(modelObject);
    const { showCustomAlert } = useCustomAlertContext();

    const registerPlayer = (values: any) => {
        const data = {
            username: values.username,
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            levelCandidate: "easy",
            scenarioStepStart: 0,
            scenarioStepEnd: 2,
            useCase: "Event",
            scenarioGitUrl:
                "https://gitlab.ippon.fr/devops-cloud-capitalisation/gameday/scenario-easy-01-simple-webapp/scenario-step01-asg.git",
        };

        if (!/^[a-zA-Z]+$/.test(data.username)) {
            showCustomAlert(
                "error",
                "Erreur",
                "Nom d'utilisateur invalide. Il doit uniquement contenir des caractères minuscules :)"
            );
            return;
        }

        showCustomAlert(
            "info",
            "Infos",
            "Patiente quelques instants lors de la création du compte... "
        );
        axios
            .post(`${BACKEND_API}/teams`, data)
            .then((response) => {
                if (response.data.status === 200) {
                    showCustomAlert("success", "Succès", "Compte de jeu créé");
                    setApplicant(values.username);
                    return true;
                } else {
                    switch (response.data.error.code) {
                        case "EntityAlreadyExists":
                            showCustomAlert("error", "Erreur", "Nom d'utilisateur déjà pris");
                            break;
                        case "UsernameAlreadyExists":
                            showCustomAlert("error", "Erreur", "Nom d'utilisateur déjà pris");
                            break;
                        case "NoMoreAccounts":
                            showCustomAlert(
                                "info",
                                "Infos",
                                "Plus de place dans le jeu diponible. Réessaie plus tard stp :)"
                            );
                            break;
                        case "CloudmapperDown":
                            showCustomAlert(
                                "info",
                                "Infos",
                                "Aïe, problème interne. Réessaie plus tard stp :)"
                            );
                            break;
                        case "ValidationError":
                            showCustomAlert(
                                "error",
                                "Erreur",
                                "Nom d'utilisateur invalide. Il doit uniquement contenir des caractères en minuscule :)"
                            );
                            break;
                        default:
                            showCustomAlert(
                                "error",
                                "Problème interne",
                                JSON.stringify(response.data.error)
                            );
                            break;
                    }
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (checkForm(values) && registerPlayer(values)) {
            resetForm();
        }
    };

    return (
        <Card className={classes.root}>
            <div className={classes.title}>
                <Typography color="primary" variant="h2">
                    <b>BIENVENUE</b>
                    SUR TA SESSION DE JEU
                </Typography>
                <Divider variant="middle" className={classes.divider} />
            </div>
            <Form className={classes.form} onSubmit={handleSubmit}>
                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Ippon te propose un jeu concours #Cloud !
                    </Typography>
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Une fois la session lancée, tu auras 15 minutes pour compléter un maximum
                        d'étapes...
                    </Typography>
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Ne sois pas frustré(e), il n'est pas possible de finir le jeu dans le temps
                        imparti.
                    </Typography>
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        A la clé : un lot à remporter pour le plus rapide et efficace !
                    </Typography>
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Bonne chance, et que le meilleur gagne !
                    </Typography>
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        N'hésite pas à mettre en plein écran pour une expérience optimale !
                    </Typography>
                </Box>
                <Box component="span" className={classes.block} display="block">
                    <Typography color="error" variant="subtitle1">
                        Tous les champs sont obligatoires
                    </Typography>
                </Box>

                <Box className={classes.block} flexDirection="row" display="flex">
                    <Box className={classes.inline}>
                        <Typography gutterBottom color="textPrimary" variant="h4">
                            Nom
                        </Typography>
                        <Input
                            name="lastname"
                            value={values.lastname}
                            onChange={handleInputChange}
                            required
                        />
                    </Box>
                    <Box className={classes.inline}>
                        <Typography gutterBottom color="textPrimary" variant="h4">
                            Prénom
                        </Typography>
                        <Input
                            name="firstname"
                            value={values.firstname}
                            onChange={handleInputChange}
                            required
                        />
                    </Box>
                </Box>
                {/* 
                <Box component="span" className={classes.block} display="block" >

                </Box> */}

                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Nom d'utilisateur
                    </Typography>
                    <Input
                        name="username"
                        value={values.username}
                        onChange={handleInputChange}
                        required
                    />
                </Box>

                <Box component="span" className={classes.block} display="block">
                    <Typography gutterBottom color="textPrimary" variant="h4">
                        Email
                    </Typography>
                    <Input
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleInputChange}
                        required
                    />
                </Box>

                <div className={classes.button}>
                    <Button disableElevation variant="contained" color="primary" type="submit">
                        <Grid container direction="row" alignItems="center">
                            Lancer la session
                            <ArrowForwardIcon style={{ marginLeft: "10px" }} fontSize="small" />
                        </Grid>
                    </Button>
                </div>
            </Form>
        </Card>
    );
};
