import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CustomCheckbox from "../../../common/CustomCheckbox";
import { Button, makeStyles } from "@material-ui/core";
import ModalScenarioReadme from "../ModalScenarioReadme";
import { Scenario } from "../../../interfaces/Scenario";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        textAlign: "left",
        backgroundColor: "#F0F4FB",
        padding: theme.spacing(0, 3),
        margin: theme.spacing(1, 0),
        cursor: "pointer",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:hover": {
            boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
            borderColor: theme.palette.primary.main,
        },
    },
    body: {
        flex: 1,
        padding: theme.spacing(2, 0),
        "& > *": {
            margin: theme.spacing(0.5, 0),
        },
    },
    button: {
        padding: "8px 24px",
        background: "#FFFFFF",
    },
}));

interface ScenarioCardProps {
    step: number;
    scenario: Scenario;
    isSelected: boolean;
    onClick: any;
}

const ScenarioCard: React.FC<ScenarioCardProps> = ({ scenario, step, isSelected, onClick }) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleDetails = () => {
        setOpenModal(true);
    };

    return (
        <div className={classes.root}>
            <Box marginRight={2} onClick={onClick}>
                <CustomCheckbox name={scenario.name} checked={isSelected} />
            </Box>
            <div className={classes.body} onClick={onClick}>
                <Typography variant="h4">
                    Étape {step} : {scenario.title.toUpperCase()}
                </Typography>
                <Typography variant="body2">{scenario.description}</Typography>
            </div>
            <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={handleDetails}
            >
                Détails
            </Button>
            <ModalScenarioReadme
                open={openModal}
                setOpen={setOpenModal}
                rules={false}
                scenarioId={scenario.id}
                step={step}
            />
        </div>
    );
};

export default ScenarioCard;
