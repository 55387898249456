import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useCustomAlertContext } from "../context/CustomAlertContext";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2, 0),
    },
}));

const ClipboardUserData: React.FC<{ userData: string }> = ({ userData }) => {
    const classes = useStyles();
    const { showCustomAlert } = useCustomAlertContext();

    const copyToClipboard = (textToCopy: string) => {
        // Navigator clipboard api needs a secure context (https).
        // Else we need to use the text area magic trick.
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise<void>((res, rej) => {
                document.execCommand("copy") ? res() : rej();
                textArea.remove();
            });
        }
    };

    const handleCopy = () => {
        if (!!userData) {
            copyToClipboard(userData);
            showCustomAlert(
                "success",
                "Copié avec succès",
                "Le script est sauvegardé dans votre presse-papier."
            );
        } else {
            showCustomAlert(
                "error",
                "Une erreur est survenue",
                "Veuillez copier le script à la main."
            );
        }
    };

    return (
        <Button
            className={classes.root}
            disableElevation
            onClick={handleCopy}
            color="primary"
            variant="contained"
        >
            Copier le script
        </Button>
    );
};

export default ClipboardUserData;
