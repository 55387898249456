import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            width: "80%",
            margin: theme.spacing(1),
        },
    },
}));

export const Form: React.FC<React.HTMLAttributes<HTMLFormElement> & { onSubmit: any }> = ({
    onSubmit,
    children,
    ...props
}) => {
    const classes = useStyles();
    return (
        <form className={classes.root} autoComplete="off" onSubmit={onSubmit} {...props}>
            {children}
        </form>
    );
};
