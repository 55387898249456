import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { ChipInformation } from "./ChipInformation";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { downloadCredentials } from "./helpers";
import { Link } from "@material-ui/core";
import { Provider, Role } from "../interfaces/Types";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: "22px",
    },
    icon: {
        color: theme.palette.primary.main,
    },
    details: {
        display: "block",
    },
    typography: {
        fontSize: "15px",
        marginBottom: theme.spacing(2),
    },
    link: {
        cursor: "pointer",
        marginBottom: theme.spacing(2),
    },
    downloadCredentials: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        "&> .MuiTypography-root": {
            fontWeight: "bold",
        },
    },
}));

interface ApplicantInfoProps {
    cloudProvider: Provider;
    teamData: any;
    role: Role;
}

type EnumRoleContent = {
    [role in Role]: {
        title: React.ReactElement;
        content: React.ReactElement;
    };
};

type EnumProviderContent = {
    [provider in Provider]: EnumRoleContent;
};

export const ApplicantInfo: React.FC<ApplicantInfoProps> = ({ cloudProvider, teamData, role }) => {
    const classes = useStyles();

    const enumAccountId: { [provider in Provider]: string } = {
        AWS: teamData.accountId,
        Azure: `${teamData.name}@ipponfrcloudchallenge.onmicrosoft.com`,
    };

    const handleCredentials = () => {
        downloadCredentials(
            cloudProvider,
            enumAccountId[cloudProvider],
            teamData.name,
            teamData.password,
            teamData.accessKey.AccessKey
        );
    };

    const enumContent: EnumProviderContent = {
        AWS: {
            player: {
                title: <b>Tes identifiants pour te connecter à AWS</b>,
                content: (
                    <div style={{ textAlign: "left" }}>
                        <Divider className={classes.typography} />
                        <ChipInformation
                            label={"Identifiant du compte AWS"}
                            value={enumAccountId[cloudProvider]}
                        />
                        <ChipInformation
                            label={"Nom d'utilisateur AWS Console"}
                            value={teamData.name}
                        />
                        <ChipInformation
                            label={"Mot de passe AWS Console"}
                            value={teamData.password}
                        />
                        <ChipInformation
                            label={"Access Key ID"}
                            value={teamData.accessKey.AccessKey.AccessKeyId}
                        />
                        <ChipInformation
                            label={"Secret Access Key"}
                            value={teamData.accessKey.AccessKey.SecretAccessKey}
                        />
                    </div>
                ),
            },
            admin: {
                title: <b>Identifiants à fournir au candidat lors sa session</b>,
                content: (
                    <div style={{ textAlign: "left" }}>
                        <Divider className={classes.typography} />
                        <ChipInformation
                            label={"Identifiants de connexion"}
                            value={teamData.name}
                        />
                        <ChipInformation
                            label={"Mot de passe pour la connexion"}
                            value={teamData.password}
                        />
                        <ChipInformation
                            label={"Identifiant du compte AWS"}
                            value={enumAccountId[cloudProvider]}
                        />
                        <ChipInformation
                            label={"Access Key ID"}
                            value={teamData.accessKey.AccessKey.AccessKeyId}
                        />
                        <ChipInformation
                            label={"Secret Access Key"}
                            value={teamData.accessKey.AccessKey.SecretAccessKey}
                        />
                        <div className={classes.downloadCredentials}>
                            <GetAppOutlinedIcon
                                style={{ marginRight: 8 }}
                                fontSize="small"
                                color="primary"
                            />
                            <Typography>
                                <Link
                                    color="primary"
                                    className={classes.link}
                                    onClick={handleCredentials}
                                >
                                    Télécharger les credentials
                                </Link>
                            </Typography>
                        </div>
                    </div>
                ),
            },
        },
        Azure: {
            player: {
                title: <b>Tes identifiants pour te connecter à Azure</b>,
                content: (
                    <div style={{ textAlign: "left" }}>
                        <Divider className={classes.typography} />
                        <ChipInformation
                            label={"Adresse mail Azure"}
                            value={enumAccountId[cloudProvider]}
                        />
                        <ChipInformation label={"Nom d'utilisateur Azure"} value={teamData.name} />
                        <ChipInformation label={"Mot de passe Azure"} value={teamData.password} />
                        <ChipInformation
                            label={"Client ID"}
                            value={teamData.accessKey.AccessKey.AccessKeyId}
                        />
                        <ChipInformation
                            label={"Client Secret"}
                            value={teamData.accessKey.AccessKey.SecretAccessKey}
                        />
                    </div>
                ),
            },
            admin: {
                title: <b>Identifiants à fournir au candidat lors sa session</b>,
                content: (
                    <div style={{ textAlign: "left" }}>
                        <Divider className={classes.typography} />
                        <ChipInformation
                            label={"Adresse mail du compte Azure"}
                            value={enumAccountId[cloudProvider]}
                        />
                        <ChipInformation
                            label={"Identifiants de connexion"}
                            value={teamData.name}
                        />
                        <ChipInformation
                            label={"Mot de passe pour la connexion"}
                            value={teamData.password}
                        />
                        <ChipInformation
                            label={"Client ID"}
                            value={teamData.accessKey.AccessKey.AccessKeyId}
                        />
                        <ChipInformation
                            label={"Client Secret"}
                            value={teamData.accessKey.AccessKey.SecretAccessKey}
                        />
                        <div className={classes.downloadCredentials}>
                            <GetAppOutlinedIcon
                                style={{ marginRight: 8 }}
                                fontSize="small"
                                color="primary"
                            />
                            <Typography>
                                <Link
                                    color="primary"
                                    className={classes.link}
                                    onClick={handleCredentials}
                                >
                                    Télécharger les credentials
                                </Link>
                            </Typography>
                        </div>
                    </div>
                ),
            },
        },
    };

    return (
        <Accordion defaultExpanded>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.icon} fontSize="large" />}
            >
                <Typography className={classes.title} color="primary">
                    {enumContent[cloudProvider][role].title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                {enumContent[cloudProvider][role].content}
            </AccordionDetails>
        </Accordion>
    );
};
