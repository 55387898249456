import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Alert } from "@material-ui/lab";
import ChangeEntrypoint from "../player/staffing/staff/ChangeEntrypoint";
import { Button, Link } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import axios from "axios";
import { BACKEND_API } from "../constant";
import { Provider, Role } from "../interfaces/Types";
import { Team } from "../interfaces/Team";
import { CloudServiceStatus } from "./CloudServiceStatus";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
    },
    title: {
        fontSize: 22,
        fontWeight: "bold",
    },
    details: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(3),
        "&> .MuiButton-root": {
            width: "100%",
        },
    },
    link: {
        cursor: "pointer",
        transition: theme.transitions.create(["all"]),
    },
    alert: {
        padding: theme.spacing(1.5, 2),
    },
    serviceInfo: {
        display: "flex",
        flexDirection: "column",
    },
}));

interface CloudServiceInfoProps {
    cloudProvider: Provider;
    teamData: Team;
    role: Role;
}

/**
 * Cloud service info
 *
 * @param teamData Data of the team account of the candidate
 * @param role Either player or admin.
 * @return {JSX} Cloud Service account information card
 */
export const CloudServiceInfo: React.FC<CloudServiceInfoProps> = ({
    cloudProvider,
    teamData,
    role,
}) => {
    const classes = useStyles();
    const [openUpdateEntrypoint, setOpenUpdateEntrypoint] = useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<boolean>(true);

    const enumProviderLink = {
        AWS: {
            label: "Console AWS",
            href: `https://${teamData.accountId}.signin.aws.amazon.com/console`,
        },
        Azure: {
            label: "Portal Azure",
            href: "https://portal.azure.com/signin/index/",
        },
    };

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    const handleCloseUpdateEntrypoint = () => {
        setOpenUpdateEntrypoint(false);
    };

    const handleChangeEntrypoint = () => {
        setOpenUpdateEntrypoint(true);
    };

    const generateAdminLinkAndNavigate = async () => {
        let response;
        let link;
        try {
            response = await axios.get(`${BACKEND_API}/accounts/link/${teamData.accountId}`);
            link = response.data;
            window.open(`${link}`, "_blank", "noopener,noreferrer");
        } catch (error) {
            console.log(error);
        }
    };

    const handleConsoleClick = () => {
        if (role === "admin" && cloudProvider === "AWS") {
            generateAdminLinkAndNavigate();
        } else {
            window.open(enumProviderLink[cloudProvider].href, "_blank", "noopener,noreferrer");
        }
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleAccordionChange}
            className={expanded ? classes.root : ""}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}>
                <Typography className={classes.title} color="primary">
                    Informations {cloudProvider}
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <Divider />
                <div>
                    <Typography variant="button" component="p" gutterBottom>
                        Statut du compte :
                    </Typography>
                    <CloudServiceStatus status={teamData.info} />
                </div>
                <Alert
                    severity={teamData.url === "" ? "warning" : "info"}
                    className={classes.alert}
                >
                    {teamData.url === "" ? (
                        <strong>No service entrypoint provided</strong>
                    ) : (
                        <>
                            Entrypoint : <strong>{teamData.url}</strong>
                        </>
                    )}
                </Alert>
                {role === "player" && (
                    <Button color="primary" variant="outlined" onClick={handleChangeEntrypoint}>
                        Changer l'entrypoint
                    </Button>
                )}
                <div style={{ display: "flex" }}>
                    <ArrowForwardIcon
                        style={{ marginRight: "10px" }}
                        fontSize="small"
                        color="primary"
                    />
                    <Typography gutterBottom>
                        <Link color="primary" className={classes.link} onClick={handleConsoleClick}>
                            <b>{enumProviderLink[cloudProvider].label}</b>
                        </Link>
                    </Typography>
                </div>
            </AccordionDetails>
            <ChangeEntrypoint
                openUpdateTeam={openUpdateEntrypoint}
                handleCloseUpdateTeam={handleCloseUpdateEntrypoint}
                password={teamData.password}
            />
        </Accordion>
    );
};
